import { useEffect } from "react";
import { Drawer, Form, Input, Button } from "antd";
import { useSelector } from "react-redux";
import EN from "../../../../config/en";

const SocialAuthDrawer = ({
  open,
  onClose,
  save,
  title,
  id,
  secret,
  loading,
  client_id,
  client_secret,
}) => {
  const [form] = Form.useForm();
  const translate = useSelector((state) => state.globalState.translate);

  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        [id]: client_id,
        [secret]: client_secret,
      });
    }
  }, [open, client_id, client_secret, id, secret, form]);

  const handleFinish = (values) => {
    save(values);
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={title}
      width={400}
      footer={
        <div style={{ textAlign: "right" }}>
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            {translate["Cancel"] || EN["Cancel"]}
          </Button>
          <Button onClick={form.submit} type="primary" loading={loading}>
            {translate["Save"] || EN["Save"]}
          </Button>
        </div>
      }
    >
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item label="Client ID" name={id}>
          <Input />
        </Form.Item>
        <Form.Item label="Client Key" name={secret}>
          <Input />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default SocialAuthDrawer;
