import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Drawer, Form, Input } from "antd";

import REQUESTS from "../../../../server/requests";

import EN from "../../../../config/en";
import ICONS from "../../../../config/icons";

import ErrorMessage from "../../../elements/ErrorMessage";
import SaveButtonComponent from "../../../elements/SaveButtonComponent";

export default function CategoryDrawer({ visible, onClose, category, getCategoriesList }) {

    const translate = useSelector((state) => state.globalState.translate);
    
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [errorText, setErrorText] = useState(null);

    useEffect(() => {
        if (visible) {
            if (category) {
                form.setFields([{ name: "name", value: category.name }]);
            }
        } else {
            setErrorText(null);
        }
    }, [visible]);

    useEffect(() => {
        if (!visible && category) {
            form.resetFields();
        } 
    }, [visible]);

    const onFinish = (values) => {
        setLoading(true);
        setErrorText(null);

        let body = {
            name: values.name,
        };

        function callback() {
            onClose();
            getCategoriesList();

            setLoading(false);
            form.resetFields();
        }

        function errorCallback(err) {
            setErrorText(err);
            setLoading(false);
        }

        if (category) {
            body.id = category.id;
            REQUESTS.PROMOTIONS.CATEGORIES.EDIT(body, callback, errorCallback);
        } else {
            REQUESTS.PROMOTIONS.CATEGORIES.ADD(body, callback, errorCallback);
        }
    };

    const onFinishFailed = () => {
        setErrorText(null);
    };

    return (
        <Drawer
            title={category ? translate['Edit Category'] || EN['Edit Category'] : translate['Add New Category'] || EN['Add New Category']}
            placement="right"
            width={400}
            onClose={onClose}
            visible={visible}
           
        >
            <Form
                form={form}
                layout="vertical"
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item 
                    label={translate['Name'] || EN['Name']}
                    tooltip={{
                        title: "Category name",
                        icon: ICONS.INFO,
                    }}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: `${translate['Please input'] || EN['Please input']} ${translate['Name'] || EN['Name']}`,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item>
                    <SaveButtonComponent
                        loading={loading}
                        onClick={() => form.submit()}
                    /> 
                </Form.Item>
            </Form>

            <ErrorMessage>{errorText}</ErrorMessage>
        </Drawer>
    );
}
