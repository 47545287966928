import { useEffect } from "react";

const Player = ({ url }) => {
    const video = <video id="video" autoPlay controls width="100%" height="auto" />;

    useEffect(() => {
        let hls = new window.Hls();

        setTimeout(() => {
            if (url.endsWith(".m3u") || url.endsWith(".m3u8")) {
                if (window.Hls.isSupported()) {
                    hls.loadSource(url);
                    hls.attachMedia(document.getElementById("video"));
                }
            } else {
                document.getElementById("video").src = url;
            }
        }, 500);

        return () => {
            hls.destroy();
            document.getElementById("video").src = "";
        };
    }, [url]);

    return video;
};

export default Player;
