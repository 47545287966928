import { useEffect, useState } from "react";
import { Drawer, Form, Input, Select, Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import FileChooser from "../../../../elements/FileChooser";
import Translation from "../../../../elements/Translation";
import { useSelector } from "react-redux";
import EN from "../../../../../config/en";
import ICONS from "../../../../../config/icons";

const RadioDrawer = ({ visible, onClose, genres, addMusic, editable, loading }) => {
  const [form] = Form.useForm();
  const [imageSrc, setImageSrc] = useState(null);
  const [descriptionTranslations, setDescriptionTranslations] = useState([]);
  const [nameTranslation, setNameTranslation] = useState([]);
  const [categoriesId, setCategoriesId] = useState(null);

  const translate = useSelector((state) => state.globalState.translate);

  const handleFinish = (values) => {
    console.log(categoriesId);

    const body = {
      name: JSON.stringify(nameTranslation || []),
      description: JSON.stringify(descriptionTranslations || []),
      icon: imageSrc,
      url: values.url,
      // category_id: values.category_id?.join(","),
    };

    if (categoriesId) {
      body.category_id = categoriesId.join(",");
    }

    addMusic(body);
  };

  const repliceImage = () => {
    setImageSrc(null);
    form.setFields([{ name: "image", value: null }]);
  };

  const selectFile = (file) => {
    setImageSrc(file?.file_url);
    form.setFields([{ name: "image", value: file }]);
  };

  const onChangeNameTranslation = (value, id) => {
    const name = {
      text: value,
      language_id: id,
    };
    const newTranslations = [...nameTranslation];

    const i = newTranslations.findIndex((i) => i.language_id === id);

    if (i >= 0) {
      newTranslations[i] = name;
    } else {
      newTranslations.push(name);
    }

    setNameTranslation(newTranslations);
  };

  const onChangeTranslation = (value, id) => {
    const description = {
      text: value,
      language_id: id,
    };
    const newTranslations = [...descriptionTranslations];

    const i = newTranslations.findIndex((i) => i.language_id === id);

    if (i >= 0) {
      newTranslations[i] = description;
    } else {
      newTranslations.push(description);
    }

    setDescriptionTranslations(newTranslations);
  };
  const setCategories = (editable, genres) => {
    if (editable) {
      const newGenres = editable.map((item) => {
        const genre = genres.find((genre) => genre.value === item.category_id);

        return genre;
      });
      return newGenres;
    }
  };

  useEffect(() => {
    if (editable) {
      form.setFieldsValue({
        name: editable.name,
        description: editable.description,
        url: editable.url,
        category_id: setCategories(editable?.radios_categories, genres),
      });

      setImageSrc(editable.icon);
    }
  }, [visible, editable]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setImageSrc(null);
      setDescriptionTranslations([]);
      setNameTranslation([]);
      setCategories(null);
    }
  }, [visible]);

  return (
    <Drawer
      width={550}
      open={visible}
      title={
        editable
          ? translate["Edit Radio"] || EN["Edit Radio"]
          : translate["Add Radio"] || EN["Add Radio"]
      }
      onClose={() => {
        onClose();
        form.resetFields();
        selectFile(null);
      }}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button
            onClick={() => {
              form.resetFields();
              onClose();
            }}
            style={{ marginRight: 8 }}
          >
            {translate["Cancel"] || EN["Cancel"]}
          </Button>
          <Button
            loading={loading}
            onClick={() => {
              form.submit();
            }}
            type="primary"
          >
            {editable ? translate["Edit"] || EN["Edit"] : translate["Add"] || EN["Add"]}
          </Button>
        </div>
      }
    >
      <Form form={form} onFinish={handleFinish} layout="vertical">
        <Form.Item label="Icon" name="icon">
          <FileChooser
            src={imageSrc}
            onChange={selectFile}
            replace={repliceImage}
            types={["image"]}
          />
        </Form.Item>
        <Translation
          render={(item, index) => {
            const name = nameTranslation.find(
              (translation) => translation.language_id === item.id
            )?.text;
            return (
              <Form.Item
                label={translate["Name"] || EN["Name"]}
                name="name"
                tooltip={{
                  title: "Info Title",
                  icon: ICONS.INFO,
                }}
              >
                <Input
                  value={name}
                  onChange={(e) => {
                    onChangeNameTranslation(e.target.value, item.id);
                    // setNameTranslation(e.target.value, item.id);
                  }}
                />
              </Form.Item>
            );
          }}
        />
        {/* <Form.Item label="Name" name="name">
          <Input />
        </Form.Item> */}
        <Form.Item label="URL" name="url">
          <Input />
        </Form.Item>
        <Form.Item label="Category" name="category_id">
          <Select
            options={genres}
            mode="multiple"
            placeholder="Select a category"
            showSearch
            allowClear
            filterOption={(input, option) => {
              return option?.label?.toLowerCase()?.includes(input?.toLowerCase());
            }}
            onChange={(value) => {
              if (value || value?.length) {
                setCategoriesId(value);
              }
            }}
          />
        </Form.Item>
        <Translation
          render={(item, index) => {
            const description = descriptionTranslations.find(
              (translation) => translation.language_id === item.id
            )?.text;
            return (
              <Form.Item
                label={translate["Description"] || EN["Description"]}
                name="description"
                tooltip={{
                  title: "Info Title",
                  icon: ICONS.INFO,
                }}
              >
                <TextArea
                  value={description}
                  onChange={(e) => {
                    onChangeTranslation(e.target.value, item.id);
                  }}
                />
              </Form.Item>
            );
          }}
        />

        {/* <Form.Item label="Description" name="description">
            <TextArea />
          </Form.Item> */}
        {/* </Translation> */}
      </Form>
    </Drawer>
  );
};

export default RadioDrawer;
