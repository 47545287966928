import { useSelector } from "react-redux";

import { Drawer } from "antd";
import InitAirports from "./InitAirports";
import ConfigAirports from "./ConfigAirports";

export default function AirportSetingsDrawer({ visible, onClose }) {
  const { profile } = useSelector((state) => state.globalState);

  return (
    <Drawer
      title="Airport settings"
      placement="right"
      width={600}
      onClose={onClose}
      visible={visible}
    >
      <div className="title-in-drawer">
        <h2>Airport settings</h2>
      </div>

      {visible ? (
        profile && profile?.is_super_admin ? (
          <InitAirports onClose={onClose} />
        ) : (
          <ConfigAirports onClose={onClose} />
        )
      ) : null}
    </Drawer>
  );
}
