import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";

import Page from "../../elements/Page";

import Chat from "./Chat";
import AllMessages from "./AllMessages";
import QuickMessages from "./QuickMessages";

import "./_messages.scss";

export default function MessagesPage() {
    const new_message = useSelector((state) => state.globalState.new_message);

    const [activeChat, setActiveChat] = useState(null);

    const selectActiveChat = useCallback((chat) => {
        setActiveChat(chat);
    }, []);

    return (
        <Page>
            <div className="page-body">
                <div className="messages-section">
                    <AllMessages
                        activeChat={activeChat}
                        selectActiveChat={selectActiveChat}
                        new_message={new_message}
                    />

                    <Chat activeChat={activeChat} selectActiveChat={selectActiveChat} />

                    {activeChat && <QuickMessages activeChat={activeChat} />}
                </div>
            </div>
        </Page>
    );
}
