import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";

import { Drawer, Form, Input } from "antd";

import { actions } from "../../../store";

import REQUESTS from "../../../server/requests";

import ICONS from "../../../config/icons";
import EN from "../../../config/en";

import ErrorMessage from "../../elements/ErrorMessage";
import SaveButtonComponent from "../../elements/SaveButtonComponent";

import ChangePasswordDrawer from "./ChangePasswordDrawer";
import FileChooser from "../../elements/FileChooser";

export default function UserAccountsDrawer({ visible, onClose }) {
  const dispatch = useDispatch();

  const info = useSelector((state) => state.globalState.profile);
  const translate = useSelector((state) => state.globalState.translate);

  const { setProfileInRedux } = bindActionCreators(actions, dispatch);

  const [loading, setLoading] = useState(false);

  const [errorText, setErrorText] = useState(null);

  const [imageSrc, setImageSrc] = useState(null);

  const [form] = Form.useForm();

  const [visibleTheChangePasswordDrawer, setVisibleTheChangePasswordDrawer] =
    useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (info && info.image) {
        setImageSrc(info.image);
        form.setFields([{ name: "image", value: info.image }]);
      }
    }, 1000);
  }, [info]);

  useEffect(() => {
    if (visible) {
      form.resetFields();
    } else {
      setImageSrc(null);
      setErrorText(null);
    }
  }, [visible]);

  const onFinish = (values) => {
    setLoading(true);

    setErrorText(null);

    const body = {
      id: info.id,
      name: values.name,
      surname: values.surname,
      email: values.email,
      image: values.image,
    };

    function callback() {
      REQUESTS.EMPLOYERS.PROFILE.GET((data) => {
        setProfileInRedux(data);
      });
      setLoading(false);
      onClose();
    }

    function errorCallback(err) {
      setLoading(false);
      setErrorText(err);
    }

    REQUESTS.EMPLOYERS.EDIT(body, callback, errorCallback);
  };

  const onFinishFailed = () => {
    setErrorText(null);
  };

  const selectFile = (item) => {
    setImageSrc(item.file_url);
    form.setFields([{ name: "image", value: item.file_url }]);
  };

  const repliceImage = () => {
    setImageSrc(null);
    form.setFields([{ name: "image", value: null }]);
  };

  return (
    <Drawer
      title={translate["User account"] || EN["User account"]}
      placement="right"
      width={600}
      onClose={onClose}
      visible={visible}
     
    >
      <div className="global_header-title-drawer">
        <div className="title-in-drawer">
          <h2>{translate["User account"] || EN["User account"]}</h2>
        </div>
      </div>

      <Form
        form={form}
        layout="vertical"
        name="userAccounts-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          name: info.name,
          surname: info.surname,
          email: info.email,
        }}
      >
        <Form.Item
          label={translate["Image"] || EN["Image"]}
          tooltip={{
            title: "Choose an image",
            icon: ICONS.INFO,
          }}
          name="image"
          rules={[
            {
              required: info ? false : true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Image"] || EN["Image"]
              }`,
            },
          ]}
        >
          <FileChooser
            src={imageSrc}
            onChange={selectFile}
            replace={repliceImage}
            types={["image"]}
          />
        </Form.Item>

        <Form.Item
          label={translate["Name"] || EN["Name"]}
          tooltip={{
            title: "User name",
            icon: ICONS.INFO,
          }}
          name="name"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Name"] || EN["Name"]
              }`,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translate["Surname"] || EN["Surname"]}
          tooltip={{
            title: "User surname",
            icon: ICONS.INFO,
          }}
          name="surname"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Surname"] || EN["Surname"]
              }`,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translate["Email address"] || EN["Email address"]}
          tooltip={{
            title: "Email Address",
            icon: ICONS.INFO,
          }}
          name="email"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Email"] || EN["Email"]
              }`,
            },
          ]}
        >
          <Input type="email" />
        </Form.Item>

        <div
          className="change-password-button"
          onClick={() => setVisibleTheChangePasswordDrawer(true)}
        >
          {translate["Change password"] || EN["Change password"]}

          {ICONS.ARROW_RIGHT_OUTLINED}
        </div>
        <Form.Item>
          <SaveButtonComponent
            loading={loading}
            onClick={() => form.submit()}
            disabled={info?.id ? false : true}
          />
        </Form.Item>
      </Form>

      <ErrorMessage>{errorText}</ErrorMessage>

      <ChangePasswordDrawer
        visible={visibleTheChangePasswordDrawer}
        onClose={() => setVisibleTheChangePasswordDrawer(false)}
      />
    </Drawer>
  );
}
