import React, { useEffect, useState } from "react";

import { Drawer, Form, Input, Radio, Space, message } from "antd";
import SaveButtonComponent from "../../elements/SaveButtonComponent";
import REQUESTS from "../../../server/requests";
import ErrorMessage from "../../elements/ErrorMessage";

export default function PreferenceDrawer({ visible, onClose }) {
  const [loading, setLoading] = useState(false);

  const [errorText, setErrorText] = useState(null);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setErrorText(null);

    setLoading(true);

    const body = {
      exit_type: values.exit_type
    };

    if (values.exit_type === "with_pin") {
      body.pin = values.pin;
    }

    function callback() {
      setLoading(false);

      onClose();

      message.success("Saved successfully");
    }

    function errorCallback(err) {
      setLoading(false);

      setErrorText(err);
    }

    REQUESTS.EXIT_APP.EDIT(body, callback, errorCallback);
  };

  const getExitConfing = () => {
    function callback(data) {
      if (data) {
        form.setFields([
          {
            name: "exit_type",
            value: data.exit_type
          },
          {
            name: "pin",
            value: data.pin
          }
        ]);
      }
    }

    REQUESTS.EXIT_APP.GET(callback);
  };

  const validatePin = (rule, value, callback) => {
    if (value === undefined || value === null || value === "") {
      callback("Please input a 4-digit pin");
    } else if (!/^\d{4}$/.test(value)) {
      callback("Pin must be exactly 4 digits");
    } else {
      callback();
    }
  };

  useEffect(() => {
    if (visible) {
      form.resetFields();
      setErrorText(null);
      getExitConfing();
    }
  }, [visible]);

  return (
    <Drawer
      title="Preferences"
      placement="right"
      onClose={onClose}
      visible={visible}
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        name="preference-form"
        initialValues={{
          exit_type: "dont_exit"
        }}
      >
        <Form.Item label="Exit app" name="exit_type">
          <Radio.Group buttonStyle="solid">
            <Space direction="vertical">
              <Radio value="dont_exit">Disable exit of app</Radio>

              <Radio value="exit">Enable exit of app with Y/N</Radio>

              <Radio value="with_pin">Enable exit of app with password</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>

        <Form.Item shouldUpdate noStyle>
          {() => {
            const { exit_type } = form.getFieldsValue();

            if (exit_type === "with_pin") {
              return (
                <Form.Item
                  label="Pin"
                  name="pin"
                  rules={[
                    {
                      required: true,
                      validator: validatePin
                    }
                  ]}
                >
                  <Input.Password style={{ width: 320 }} />
                </Form.Item>
              );
            }
          }}
        </Form.Item>

        <Form.Item>
          <SaveButtonComponent
            loading={loading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>

      <ErrorMessage>{errorText}</ErrorMessage>
    </Drawer>
  );
}
