import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Drawer, Form, Input, notification } from "antd";

import REQUESTS from "../../../../../../server/requests";

import EN from "../../../../../../config/en";
import ICONS from "../../../../../../config/icons";

import ErrorMessage from "../../../../../elements/ErrorMessage";
import SaveButtonComponent from "../../../../../elements/SaveButtonComponent";
import FileChooser from "../../../../../elements/FileChooser";
import { useCallback } from "react";

export default function EpisodeDrawer({
    visible,
    onClose,
    getEpisodes,
    getSeasons,
    episode,
    season_id,
}) {
    const translate = useSelector((state) => state.globalState.translate);

    const [loading, setLoading] = useState(false);
    const [errorText, setErrorText] = useState(null);
    const [videoSrc, setVideoSrc] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);

    const [form] = Form.useForm();

    const onFinish = (values) => {
        setLoading(true);
        setErrorText(null);

        const body = {
            seasons_id: season_id,
            name: values.name,
            url: values.url,
            still_path: values.still_path,
        };

        function callback() {
            onClose();
            getEpisodes();
            getSeasons();
            setLoading(false);
        }

        function errorCallback(err) {
            setErrorText(err);
            setLoading(false);
        }

        if (episode) {
            body.id = episode.id;

            REQUESTS.VOD.MOVIES.TV_SHOWS.EPISODES.EDIT(body, callback, errorCallback);
        } else {
            REQUESTS.VOD.MOVIES.TV_SHOWS.EPISODES.ADD(body, callback, errorCallback);
        }
    };

    const onFinishFailed = () => {
        setLoading(false);
        setErrorText(null);
    };

    const selectFile = (item) => {
        setImageSrc(item.file_url);
        form.setFields([{ name: "still_path", value: item.file_url }]);
    };

    const repliceImage = () => {
        setImageSrc(null);
        form.setFields([{ name: "imastill_pathge", value: null }]);
    };

    const uploadMovie = (item) => {
        setVideoSrc(item.file_url);
        form.setFields([{ name: "url", value: item.file_url }]);
    };

    const repliceVideo = () => {
        setVideoSrc(null);
        form.setFields([{ name: "url", value: null }]);
    };

    const finishVideoUploading = (data) => {
        if (episode) {
            function callback() {
                notification.success({
                    description: `${data.name} file attached to ${episode.name}`,
                });
            }

            function errorCallback(err) {
                notification.error({
                    description: err,
                });
            }

            if (data) {
                const body = {
                    id: episode.id,
                    url: data.file_url,
                    seasons_id: season_id,
                };

                REQUESTS.VOD.MOVIES.TV_SHOWS.EPISODES.EDIT(body, callback, errorCallback);
            }
        }
    };

    useEffect(() => {
        if (visible) {
            if (episode) {
                form.setFields([
                    { name: "name", value: episode.name },
                    { name: "url", value: episode.url },
                ]);
                if (episode.still_path) {
                    setImageSrc(episode.still_path);
                }
                setVideoSrc(episode.url);
            }
        } else {
            form.resetFields();
            setVideoSrc(null);
            setErrorText(null);
            setImageSrc(null);
        }
    }, [visible]);

    return (
        <Drawer
            title={episode ? translate['Edit Episode']  || EN['Edit Episode'] : translate['Add New Episode'] || EN['Add New Episode']}
            placement="right"
            width={600}
            onClose={onClose}
            visible={visible}
        >
            <Form
                form={form}
                layout="vertical"
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label={translate['Image'] || EN['Image']}
                    tooltip={{
                        title: "Choose an image",
                        icon: ICONS.INFO,
                    }}
                    name="still_path"
                    rules={[
                        {
                            required: episode ? false : true,
                            message: `${translate['Please choose'] || EN['Please choose']} ${translate['Image'] || EN['Image']}`,
                        },
                    ]}
                >
                    <FileChooser
                        src={imageSrc}
                        onChange={selectFile}
                        replace={repliceImage}
                        types={["image"]}
                    />
                </Form.Item>

                <Form.Item
                    label={translate['Name'] || EN['Name']}
                    tooltip={{
                        title: "Episode name",
                        icon: ICONS.INFO,
                    }}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: `${translate['Please input'] || EN['Please input']} ${translate['Name'] || EN['Name']}`,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={translate['Url'] || EN['Url']}
                    tooltip={{
                        title: "Episode url",
                        icon: ICONS.INFO,
                    }}
                    name="url"
                >
                    <Input />
                </Form.Item>

                <FileChooser
                    src={videoSrc}
                    onChange={uploadMovie}
                    replace={repliceVideo}
                    types={["video"]}
                    callback={finishVideoUploading}
                    dontAllowChoose={!episode ? true : false}
                />
                {/* 
                {episode && (
                    <FileChooser
                        src={videoSrc}
                        onChange={uploadMovie}
                        replace={repliceVideo}
                        types={["video"]}
                        callback={finishVideoUploading}
                        
                    />
                )} */}

                <Form.Item>
                    <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
                </Form.Item>
            </Form>

            <ErrorMessage>{errorText}</ErrorMessage>
        </Drawer>
    );
}
