import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Drawer, Form, Input } from "antd";

import REQUESTS from "../../../../server/requests";

import EN from "../../../../config/en";
import ICONS from "../../../../config/icons";

import ErrorMessage from "../../../elements/ErrorMessage";
import SaveButtonComponent from "../../../elements/SaveButtonComponent";

export default function HostGroupsDrawer({
  visible,
  onClose,
  groups,
  getGroupsList,
}) {
  
  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);

  const [errorText, setErrorText] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {

      if (groups) {
        form.setFields([{ name: "name", value: groups.name }]);
      }
    } else {
      setErrorText(null);
    }
  }, [visible]);

  useEffect(() => {
    if (!visible && groups) {
      form.resetFields();
    }
  }, [visible]);

  const onFinish = (values) => {
    setLoading(true);

    setErrorText(null);

    const body = {
      name: values.name,
    };

    function callback() {
      setLoading(false);
      onClose();
      getGroupsList();
      form.resetFields();
    }

    function errorCallback(err) {
      setErrorText(err);
      setLoading(false);
    }

    if (groups) {
      body.id = groups.id;
      REQUESTS.ROOMS.GUESTS_GROUPS.EDIT(body, callback, errorCallback);
    } else {
      REQUESTS.ROOMS.GUESTS_GROUPS.ADD(body, callback, errorCallback);
    }
  };

  const onFinishFailed = () => {
    setLoading(false);
    setErrorText(null);
  };

  return (
    <Drawer
      title={groups ? translate['Edit Group'] || EN['Edit Group'] : translate['Add Guest Group'] || EN['Add Guest Group']}
      placement="right"
      width={400}
      onClose={onClose}
      visible={visible}
    >
      <Form
        form={form}
        layout="vertical"
        name="groups-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={translate['Name'] || EN['Name']}
          tooltip={{
            title: "Host Group name",
            icon: ICONS.INFO,
          }}
          name="name"
          rules={[
            {
              required: true,
              message: `${translate['Please input'] || EN['Please input']} ${translate['Name'] || EN['Name']}`,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <SaveButtonComponent
            loading={loading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>

      <ErrorMessage>{errorText}</ErrorMessage>
    </Drawer>
  );
}
