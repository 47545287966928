import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";

import { Button, message } from "antd";
import ICONS from "../../config/icons";
import EN from "../../config/en";

export default function CsvLinkComponent({ filename, data, onClick }) {
  const translate = useSelector((state) => state.globalState.translate);

  const [csvData, setCsvData] = useState([]);

  const csvRef = useRef(null);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onClick({}, (done) => {
      if (done?.length > 0) {
        setCsvData(done);
        csvRef?.current?.link?.click();
        message.success(
          translate["The file is downloading"] || EN["The file is downloading"]
        );
      } else {
        message.error(
          translate["The file is downloading"] || EN["The file is downloading"]
        );
      }
    });
  };

  return (
    <Button type="primary" className="exportBtn">
      <div className="exportBtnHid" onClick={handleClick} />
      <CSVLink
        filename={filename}
        data={csvData}
        asyncOnClick={true}
        className="btn btn-primary"
        // onClick={(event, done) => {
        //   // onClick(event, done);
        //   // if (data?.length > 0) {
        //   if (csvLength > 0) {
        //     done(true);
        //     message.success(
        //       translate["The file is downloading"] || EN["The file is downloading"]
        //     );
        //   } else {
        //     done(false);
        //   }
        // }}
        ref={csvRef}
      >
        {ICONS.DOWNLOAD}
      </CSVLink>
    </Button>
  );
}
