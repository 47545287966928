import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Drawer, Form, Input } from "antd";

import REQUESTS from "../../../server/requests";

import ICONS from "../../../config/icons";
import EN from "../../../config/en";

import ErrorMessage from "../../elements/ErrorMessage";
import SaveButtonComponent from "../../elements/SaveButtonComponent";

export default function ChangePasswordDrawer({ visible, onClose }) {

    const translate = useSelector((state) => state.globalState.translate);

    const [loading, setLoading] = useState(false);

    const [errorText, setErrorText] = useState(null);

    const [form] = Form.useForm();

    const onFinish = (values) => {
        setLoading(true);

        setErrorText(null);

        const body = {
            password: values.password,
            new_password: values.new_password,
        };

        function callback() {
            setLoading(false);
            onClose();
        }

        function errorCallback(err) {
            setLoading(false);

            setErrorText(err);
        }

        REQUESTS.EMPLOYERS.PASWWORD.ADD(body, callback, errorCallback);
    };

    const onFinishFailed = () => {
        setErrorText(null);
    };

    return (
        <div>
            <Drawer
                title={translate['Change password'] || EN['Change password']}
                placement="right"
                width={400}
                onClose={onClose}
                visible={visible}
               
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="userPassword-pform"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item 
                        label={translate['Old password'] || EN['Old password']}
                        tooltip={{
                            title: "Old Password",
                            icon: ICONS.INFO,
                        }}
                        name="password"
                        autoComplete="new-password"
                        rules={[
                            {
                                required: true,
                                message: `${translate['Please input'] || EN['Please input']} ${translate['Old password'] || EN['Old password']}`,
                            },
                        ]}
                    >
                        <Input.Password autoComplete="new-password" />
                    </Form.Item>

                    <Form.Item 
                        label={translate['New password'] || EN['New password']}
                        tooltip={{
                            title: "New Password",
                            icon: ICONS.INFO,
                        }}
                        name="new_password"
                        autoComplete="new-password"
                        rules={[
                            {
                                required: true,
                                message: `${translate['Please input'] || EN['Please input']} ${translate['New password'] || EN['New password']}`,
                            },
                        ]}
                    >
                        <Input.Password autoComplete="new-password" />
                    </Form.Item>
                    
                    <Form.Item>
                        <SaveButtonComponent
                            loading={loading}
                            onClick={() => form.submit()}
                        /> 
                    </Form.Item>
                </Form>

                <ErrorMessage>{errorText}</ErrorMessage>
            </Drawer>
        </div>
    );
}
