import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import ReactAudioPlayer from "react-audio-player";
import { Button, Image, Modal, notification, Progress, Spin } from "antd";

import axios from "axios";

import { actions } from "../../store";

import EN from "../../config/en";

import URLS from "../../server/urls";
import REQUESTS from "../../server/requests";
import ChooseFromMediaLibrary from "./ChooseFromMediaLibrary";

export default function FileChooser({
  src,
  onChange,
  types,
  replace,
  callback,
  dontAllowChoose,
}) {
  const dispatch = useDispatch();
  const { setUploadingListInRedux } = bindActionCreators(actions, dispatch);

  const translate = useSelector((state) => state.globalState.translate);

  const [visible, setVisible] = useState(false);
  const [visibleLibrary, setVisibleLibrary] = useState(false);

  const [fileType, setFileType] = useState(null);

  const [accept, setAccept] = useState("");
  const [progress, setProgress] = useState(0);

  const formats = {
    image: ".png, .jpg, .jpeg",
    audio: ".mp3, .wav",
    video: "video/*",
  };

  const onClickChooseFile = () => {
    let file_chooser_id = document.getElementById("file_chooser_id");

    if (file_chooser_id) {
      file_chooser_id.click();
    }
  };

  const onClickChooseMedia = () => {
    setVisibleLibrary(true);
    setVisible(false);
  };

  const showModal = (event) => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const closeLibraryModal = () => {
    setVisible(true);
    setVisibleLibrary(false);
  };

  const chooseFile = (event) => {
    if (event.currentTarget.files) {
      getUploadStatus(event.currentTarget.files[0]);
    } else {
      getUploadStatus(event.target.files[0]);
    }

    setVisible(false);
  };

  const selectFile = (item) => {
    onChange(item);
    setVisibleLibrary(false);
  };

  const getUploadStatus = (file) => {
    // if (file.lastModifiedDate) {
    const fileType = file.type.split("/")[0];
    const fileId = `${file.lastModified}_${file.name}`;

    const body = {
      fileId,
      name: file.name,
      file_type: fileType,
    };

    function callback(data) {
      upload(file, fileType, fileId, data.totalChunkUploaded);
    }

    function errorCallback(err) {
      notification.error({ description: err });
    }

    REQUESTS.VOD.MOVIES.UPLOAD.STATUS(body, callback, errorCallback);
    // }
  };

  const upload = (file, fileType, fileId, totalChunkUploaded) => {
    let chunk = file.slice(totalChunkUploaded);

    const myAxios = axios.create();

    setFileType(null);

    myAxios.defaults.headers.common["Authorization"] = localStorage.getItem("TOKEN");

    const formData = new FormData();

    formData.append("chunk", chunk, file.name);
    formData.append("fileId", file.lastModified);

    myAxios
      .post(`${URLS.UPLOAD}`, formData, {
        headers: {
          "starting-byte": totalChunkUploaded,
          "x-file-id": `${file.lastModified}_${file.name}`,
          "chunk-size": chunk.size,
          "file-size": file.size,
        },

        onUploadProgress: (progressEvent) => {
          const total = parseFloat(progressEvent.total);
          const loaded = parseFloat(progressEvent.loaded);

          const progress = Math.floor((loaded / total) * 100);

          setUploadingListInRedux({
            id: fileId,
            type: fileType,
            name: file.name,
            poster: file.file_url,
            progress,
          });

          setProgress(progress);
        },
      })
      .then((response) => {
        if (response.data.error) {
        } else {
          const data = response.data.message.data;

          if (data) {
            onChange(data);
          }

          if (response.data.message.message !== "This file already exists") {
            if (callback) {
              callback(data);
            }
          }

          if (response.data.message.message === "Finish") {
            notification.success({
              message: (
                <div className="uploading-video">
                  <div>
                    <p>{file.name}</p>
                    <Progress percent={100} size="small" width={"100%"} />
                  </div>
                </div>
              ),
              description: ` `,
              style: {
                width: "max-content",
              },
            });
          }
        }
      });
  };

  useEffect(() => {
    if (src) {
      let splittedSrc = src.split(".");
      splittedSrc = splittedSrc[splittedSrc.length - 1];

      switch (splittedSrc) {
        case "png":
        case "jpg":
        case "jpeg":
          setFileType("image");
          break;

        case "mp4":
          setFileType("video");
          break;

        case "wav":
          setFileType("audio");
          break;

        default:
          setFileType(null);
      }
    }
  }, [src]);

  useEffect(() => {
    let acceptList = "";
    if (types) {
      for (let i = 0; i < types.length; i++) {
        acceptList += `${formats[types[i]]}, `;
      }
    }

    setAccept(acceptList);
  }, [types]);

  const renderComponent = () => {
    switch (fileType) {
      case "image":
        return (
          <Image
            className="choosed-image"
            src={src}
            // onError={(e) => setFileUrl(null)}
          />
        );

      case "video":
        return <video src={src} className="choosed-video" controls />;

      case "audio":
        return <ReactAudioPlayer src={src} controls />;

      default:
        break;
    }
  };

  const onClickReplace = () => {
    replace();
    setFileType(null);
  };

  return (
    <div>
      <div className="chooser-container">
        {src ? (
          <div>
            {renderComponent()}
            <div className="replice" onClick={onClickReplace}>
              Replace {fileType}
            </div>
          </div>
        ) : (
          <div className="file-chooser" onClick={showModal}>
            Choose {fileType} file
            {visible && (
              <input
                id="file_chooser_id"
                type="file"
                className="input-chooser"
                onChange={chooseFile}
                accept={accept}
              />
            )}
          </div>
        )}
      </div>
      <Modal
        title={translate["Choose file"] || EN["Choose file"]}
        visible={visible}
        footer={null}
        onCancel={handleCancel}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            type="primary"
            style={{ width: 200 }}
            onClick={onClickChooseFile}
            disabled={dontAllowChoose}
          >
            Choose file
          </Button>
          <Button
            type="primary"
            style={{ marginTop: 10, width: 200 }}
            onClick={onClickChooseMedia}
          >
            Choose from media library
          </Button>
        </div>
      </Modal>

      <ChooseFromMediaLibrary
        types={types}
        visible={visibleLibrary}
        selectFile={selectFile}
        onClose={closeLibraryModal}
      />

      {progress && progress != 100 ? (
        <Progress percent={progress} size="small" width={"100%"} />
      ) : (
        ""
      )}
    </div>
  );
}
