import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { Form, Input } from "antd";

import EN from "../../../../config/en";

import ICONS from "../../../../config/icons";

import REQUESTS from "../../../../server/requests";

import ErrorMessage from "../../../elements/ErrorMessage";

import SaveButtonComponent from "../../../elements/SaveButtonComponent";

export default function InitAirports({ onClose }) {
  const { translate } = useSelector((state) => state.globalState);

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const [errorText, setErrorText] = useState(null);

  const getKey = () => {
    REQUESTS.AIRPORTS.KEY.GET((data) => {
      form.setFieldsValue({
        key: data.key
      });
    });
  };

  const onFinish = (values) => {
    setLoading(true);

    setErrorText(null);

    function callback(data) {
      setLoading(false);
      onClose();
    }

    function errorCallback(err) {
      setLoading(false);
      setErrorText(err);
    }

    const body = {
      key: values.key
    };

    REQUESTS.AIRPORTS.KEY.EDIT(body, callback, errorCallback);
  };

  const onFinishFailed = () => {
    setErrorText(null);
  };

  useEffect(() => {
    getKey();
  }, []);

  return (
    <Form
      form={form}
      layout="vertical"
      name="gui-form"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label={translate["Key"] || EN["Key"]}
        tooltip={{
          title: "Key",
          icon: ICONS.INFO
        }}
        name="key"
        rules={[
          {
            required: true,
            message: `${translate["Please input"] || EN["Please input"]} ${
              translate["Key"] || EN["Key"]
            }`
          }
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
      </Form.Item>

      <ErrorMessage>{errorText}</ErrorMessage>
    </Form>
  );
}
