import React, { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Drawer, Select } from "antd";

import Context from "./Context";

import { usePackagesOptions } from "../../../../hooks/options";

import EN from "../../../../config/en";
import ICONS from "../../../../config/icons";
import BUTTON_CLASSES from "../../../../config/button-classes";

import ButtonComponent from "../../../elements/ButtonComponent";

export default function FilterDrawer() {
    const { visibleFilterDrawer, setVisibleFilterDrawer, setSelectedPackagesId } =
        useContext(Context);

    const translate = useSelector((state) => state.globalState.translate);

    const [selectedPackages, setSelectedPackages] = useState("");

    const packagesOptions = usePackagesOptions();

    const onClickFilter = () => {
        setSelectedPackagesId(selectedPackages);
        setVisibleFilterDrawer(false);
    };

    return (
        <Drawer
            title={translate['Filter'] || EN['Filter']}
            placement="right"
            onClose={() => setVisibleFilterDrawer(false)}
            visible={visibleFilterDrawer}
        >
            <div style={{ display: "flex", flexDirection: "column" }}>
                <h1>{translate['Select filter parameters'] || EN['Select filter parameters']}</h1>

                <p>{translate['Package'] || EN['Package']}:</p>
                <Select
                    showArrow
                    allowClear={true}
                    value={selectedPackages}
                    onChange={setSelectedPackages}
                    options={packagesOptions}
                    style={{ width: "100%", marginBottom: 20 }}
                />

                <ButtonComponent
                    text={translate['Filter'] || EN['Filter']}
                    icon={ICONS.ARROW_RIGHT_OUTLINED}
                    buttonClass={BUTTON_CLASSES.APP_BUTTON}
                    onClick={onClickFilter}
                />
            </div>
        </Drawer>
    );
}
