import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Drawer, Form, Checkbox } from "antd";

import REQUESTS from "../../../server/requests";

import ErrorMessage from "../../elements/ErrorMessage";
import SaveButtonComponent from "../../elements/SaveButtonComponent";

import EN from "../../../config/en";
import ICONS from "../../../config/icons";
import FileChooser from "../../elements/FileChooser";

export default function LogoBackgroundDrawer({ visible, onClose, tvApplicationConfig, getConfig }) {

    const translate = useSelector((state) => state.globalState.translate);

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [imageBgSrc, setImageBgSrc] = useState(null);
    const [imageLogoSrc, setImageLogoSrc] = useState(null);
    const [errorText, setErrorText] = useState(null);

    useEffect(() => {
        if (visible) {
            form.resetFields();

            if (tvApplicationConfig.items) {
                setImageBgSrc(tvApplicationConfig.items.background_image);
                setImageLogoSrc(tvApplicationConfig.items.logo);
                form.setFields([{ name: "show_logo", value: tvApplicationConfig.items.show_logo }]);
            }
        } else {
            setErrorText(null);

            setImageBgSrc(null);
            setImageLogoSrc(null);
        }
    }, [visible]);

    const onFinish = (values) => {
        setLoading(true);

        setErrorText(null);

        const body = {
            logo: values.logo,
            background_image: values.background_image,
            show_logo: values.show_logo,
        };

        function callback() {
            setLoading(false);
            onClose();
            getConfig();
        }

        function errorCallback(err) {
            setErrorText(err);
            setLoading(false);
        }
        if (tvApplicationConfig.items) {
            body.id = tvApplicationConfig.items.id;
            REQUESTS.TV_APPLICATION.CONFIG.EDIT(body, callback, errorCallback);
        }
    };

    const onFinishFailed = () => {
        setLoading(false);
        setErrorText(null);
    };

    const chooseImageBg = (item) => {
        setImageBgSrc(item.file_url);
        form.setFields([{ name: "background_image", value: item.file_url }]);
    };

    const replaceBackground = () => {
        setImageBgSrc(null);
        form.setFields([{ name: "background_image", value: null }]);
    };

    const chooseImageLogo = (item) => {
        setImageLogoSrc(item.file_url);
        form.setFields([{ name: "logo", value: item.file_url }]);
    };

    const replaceImageLogo = () => {
        setImageLogoSrc(null);
        form.setFields([{ name: "logo", value: null }]);
    };

    return (
        <Drawer
            title={translate['Edit'] || EN['Edit']}
            placement="right"
            width={500}
            onClose={onClose}
            visible={visible}
           
        >
            <Form
                form={form}
                layout="vertical"
                name="edit-text-form"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={{
                    show_logo: true,
                }}
            >
                <Form.Item
                    label={translate['Background'] || EN['Background']}
                    tooltip={{
                        title: "Choose an image",
                        icon: ICONS.INFO,
                    }}
                    name="background_image"
                    rules={[
                        {
                            required: false,
                            message: `${translate['Please choose'] || EN['Please choose']} ${translate['Image'] || EN['Image']}`,
                        },
                    ]}
                >
                    <FileChooser
                        src={imageBgSrc}
                        onChange={chooseImageBg}
                        replace={replaceBackground}
                        types={["image"]}
                    />
                </Form.Item>

                <Form.Item name="show_logo" valuePropName="checked">
                    <Checkbox>{translate['Show Logo'] || EN['Show Logo']}</Checkbox>
                </Form.Item>

                <Form.Item
                    label={translate['Logo'] || EN['Logo']}
                    tooltip={{
                        title: "Choose an image",
                        icon: ICONS.INFO,
                    }}
                    name="logo"
                    rules={[
                        {
                            required: false,
                            message: `${translate['Please choose'] || EN['Please choose']} ${translate['Image'] || EN['Image']}`,
                        },
                    ]}
                >
                    <FileChooser
                        src={imageLogoSrc}
                        onChange={chooseImageLogo}
                        replace={replaceImageLogo}
                        types={["image"]}
                    />
                </Form.Item>

                <Form.Item>
                    <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
                </Form.Item>

                <ErrorMessage>{errorText}</ErrorMessage>
            </Form>
        </Drawer>
    );
}
