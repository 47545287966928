import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Drawer, Form, Input } from "antd";

import REQUESTS from "../../../../server/requests";
import ICONS from "../../../../config/icons";
import EN from "../../../../config/en";

import ErrorMessage from "../../../elements/ErrorMessage";
import SaveButtonComponent from "../../../elements/SaveButtonComponent";

import FileChooser from "../../../elements/FileChooser";

export default function NewGalleryDrawer({ visible, onClose, id, getList, getInfoList }) {
  const translate = useSelector((state) => state.globalState.translate);

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [galleryType, setGalleryType] = useState(null);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setErrorText(null);
      setImageSrc(null);
      setGalleryType(null);
    }
  }, [visible]);

  const onFinish = (values) => {
    setLoading(true);
    setErrorText(null);

    let body = {
      information_id: id,
      description: values.description,
      file: values.file,
      file_type: galleryType,
    };

    function callback() {
      setLoading(false);
      onClose();
      getList();
      getInfoList();
    }

    function errorCallback(err) {
      setErrorText(err);
      setLoading(false);
    }

    REQUESTS.INFO_CENTER.GALLERY.ADD(body, callback, errorCallback);
  };

  const onFinishFailed = () => {
    setErrorText(null);
  };

  const selectFile = (item) => {
    setImageSrc(item.file_url);
    setGalleryType(item.file_type);
    form.setFields([{ name: "file", value: item.file_url }]);
  };

  const repliceImage = () => {
    setImageSrc(null);
    form.setFields([{ name: "file", value: null }]);
  };

  return (
    <Drawer
      title={translate["Galleries"] || EN["Galleries"]}
      placement="right"
      width={600}
      onClose={onClose}
      visible={visible}
    >
      <Form
        form={form}
        layout="vertical"
        name="product-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={translate["Gallery"] || EN["Gallery"]}
          tooltip={{
            title: "Choose an gallery",
            icon: ICONS.INFO,
          }}
          name="file"
          rules={[
            {
              required: true,
              message: `${translate["Please choose"] || EN["Please choose"]} ${
                translate["Gallery"] || EN["Gallery"]
              }`,
            },
          ]}
        >
          <FileChooser
            src={imageSrc}
            onChange={selectFile}
            replace={repliceImage}
            types={["image", "video"]}
          />
        </Form.Item>

        <Form.Item
          label={translate["Description"] || EN["Description"]}
          tooltip={{
            title: "Gallery description",
            icon: ICONS.INFO,
          }}
          name="description"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Description"] || EN["Description"]
              }`,
            },
          ]}
        >
          <Input.TextArea rows={4} onResize={false} />
        </Form.Item>

        <Form.Item>
          <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
        </Form.Item>
      </Form>

      <ErrorMessage>{errorText}</ErrorMessage>
    </Drawer>
  );
}
