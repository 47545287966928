import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Drawer, Form, Checkbox, Select, Input } from "antd";

import REQUESTS from "../../../server/requests";

import ErrorMessage from "../../elements/ErrorMessage";
import SaveButtonComponent from "../../elements/SaveButtonComponent";

import EN from "../../../config/en";
import ICONS from "../../../config/icons";
import FileChooser from "../../elements/FileChooser";

export default function GeneralPreferencesDrawer({
  visible,
  onClose,
  tvApplicationConfig,
  getConfig
}) {
  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);

  const [errorText, setErrorText] = useState(null);

  const [imageSrc, setImageSrc] = useState(null);
  const [audioSrc, setAudioSrc] = useState(null);

  const [form] = Form.useForm();

  const dateNewsExpireTime = [
    {
      label: "Minute",
      value: "minute"
    },
    {
      label: "Hour",
      value: "hour"
    },
    {
      label: "Day",
      value: "day"
    }
  ];

  useEffect(() => {
    if (visible) {
      form.resetFields();
      if (tvApplicationConfig.items) {
        form.setFields([
          {
            name: "notification_sound",
            value: tvApplicationConfig.items.notification_sound
          },

          {
            name: "show_welcome_message",
            value: tvApplicationConfig.items.show_welcome_message
          },

          {
            name: "welcome_message_once",
            value: tvApplicationConfig.items.welcome_message_once
          },

          {
            name: "news_promotions_notification",
            value: tvApplicationConfig.items.news_promotions_notification
          },

          {
            name: "notification_icon",
            value: tvApplicationConfig.items.notification_icon
          },

          {
            name: "notification_sound",
            value: tvApplicationConfig.items.notification_sound
          },

          {
            name: "scheduling_type",
            value: tvApplicationConfig.items.scheduling_type
          },

          {
            name: "scheduling_time",
            value: tvApplicationConfig.items.scheduling_time
          }
        ]);

        setImageSrc(tvApplicationConfig.items.notification_icon);
        setAudioSrc(tvApplicationConfig.items.notification_sound);
      }
    } else {
      setErrorText(null);
    }
  }, [visible]);

  const onFinish = (values) => {
    setLoading(true);

    setErrorText(null);

    const body = {
      show_welcome_message: values.show_welcome_message,
      welcome_message_once: values.welcome_message_once,
      news_promotions_notification: values.news_promotions_notification,
      notification_icon: values.notification_icon,
      notification_sound: values.notification_sound,
      scheduling_type: values.scheduling_type,
      scheduling_time: values.scheduling_time
    };

    function callback() {
      setLoading(false);
      onClose();
      getConfig();
    }

    function errorCallback(err) {
      setErrorText(err);
      setLoading(false);
    }

    if (tvApplicationConfig.items) {
      body.id = tvApplicationConfig.items.id;
      REQUESTS.TV_APPLICATION.CONFIG.EDIT(body, callback, errorCallback);
    }
  };

  const onFinishFailed = () => {
    setLoading(false);
    setErrorText(null);
  };

  const chooseAudio = (item) => {
    setAudioSrc(item.file_url);
    form.setFields([{ name: "notification_sound", value: item.file_url }]);
  };

  const repliceAudio = () => {
    setAudioSrc(null);
    form.setFields([{ name: "notification_sound", value: null }]);
  };

  const selectFile = (item) => {
    setImageSrc(item.file_url);
    form.setFields([{ name: "notification_icon", value: item.file_url }]);
  };

  const repliceImage = () => {
    setImageSrc(null);
    form.setFields([{ name: "notification_icon", value: null }]);
  };

  return (
    <Drawer
      title={translate["General preferences"] || EN["General preferences"]}
      width={500}
      placement="right"
      onClose={onClose}
      visible={visible}
    >
      <Form
        form={form}
        layout="vertical"
        name="edit-text-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={translate["Notification icon"] || EN["Notification icon"]}
          tooltip={{
            title: "Choose an notification icon",
            icon: ICONS.INFO
          }}
          name="notification_icon"
          rules={[
            {
              required: false,
              message: `${translate["Please choose"] || EN["Please choose"]} ${
                translate["Image"] || EN["Image"]
              }`
            }
          ]}
        >
          <FileChooser
            src={imageSrc}
            onChange={selectFile}
            replace={repliceImage}
            types={["image"]}
          />
        </Form.Item>

        <Form.Item
          label={translate["Notification sound"] || EN["Notification sound"]}
          tooltip={{
            title: "Show notification sound",
            icon: ICONS.INFO
          }}
          name="notification_sound"
        >
          <Form.Item noStyle>
            <FileChooser
              src={audioSrc}
              onChange={chooseAudio}
              replace={repliceAudio}
              types={["audio"]}
            />
          </Form.Item>
        </Form.Item>

        <div>
          <Form.Item
            label="Scheduling type"
            tooltip={{
              title: "Scheduling type",
              icon: ICONS.INFO
            }}
            name="scheduling_type"
          >
            <Select
              style={{ width: "50%" }}
              showArrow
              allowClear={true}
              options={dateNewsExpireTime}
            />
          </Form.Item>

          <Form.Item
            label="Scheduling time"
            tooltip={{
              title: "Scheduling time",
              icon: ICONS.INFO
            }}
            name="scheduling_time"
          >
            <Input style={{ width: "50%" }} />
          </Form.Item>
        </div>

        <Form.Item name="show_welcome_message" valuePropName="checked" noStyle>
          <Checkbox style={{ width: "100%" }}>
            {" "}
            {translate["Show welcome message"] || EN["Show welcome message"]}
          </Checkbox>
        </Form.Item>
        <Form.Item name="welcome_message_once" valuePropName="checked" noStyle>
          <Checkbox style={{ width: "100%", margin: 0 }}>
            {translate["Show welcome message once"] ||
              EN["Show welcome message once"]}
          </Checkbox>
        </Form.Item>

        <Form.Item
          name="news_promotions_notification"
          valuePropName="checked"
          noStyle
        >
          <Checkbox style={{ width: "100%", margin: 0, marginBottom: 20 }}>
            {translate["Show News Promotions"] || EN["Show News Promotions"]}
          </Checkbox>
        </Form.Item>

        <Form.Item>
          <SaveButtonComponent
            loading={loading}
            onClick={() => form.submit()}
          />
        </Form.Item>

        <ErrorMessage>{errorText}</ErrorMessage>
      </Form>
    </Drawer>
  );
}
