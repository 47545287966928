import { useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { DatePicker, Form, Input, notification, Select } from "antd";

import moment from "moment";

import { useMovieTypesOptions, useVodCategoriesOptions } from "../../../../../../hooks/options";

import REQUESTS from "../../../../../../server/requests";

import EN from "../../../../../../config/en";
import ICONS from "../../../../../../config/icons";

import ErrorMessage from "../../../../../elements/ErrorMessage";
import SaveButtonComponent from "../../../../../elements/SaveButtonComponent";

import Context from "../Context";
import Seasons from "./Seasons";
import FileChooser from "../../../../../elements/FileChooser";

export default function FromHandTabPane({ activeKey }) {
    const {
        getMovesList,
        visibleAddMovieDrawer,
        visibleEditMovieDrawer,
        setVisibleAddMovieDrawer,
        currentMoves,
        setVisibleEditMovieDrawer,
        setCurrentMoves,
    } = useContext(Context);

    const translate = useSelector((state) => state.globalState.translate);

    const moviesCategories = useVodCategoriesOptions();

    const movieTypes = useMovieTypesOptions();

    const [loading, setLoading] = useState(false);
    const [errorText, setErrorText] = useState(null);

    const [posterSrc, setPosterSrc] = useState(null);
    const [backdropSrc, setBackdropSrc] = useState(null);
    const [videoSrc, setVideoSrc] = useState(null);

    const [movieType, setMovieType] = useState(movieTypes[0].value);

    useEffect(() => {
        if(currentMoves){
            form.resetFields();
            setPosterSrc(null);
            setBackdropSrc(null);
            setVideoSrc(null);
        }
    }, [activeKey, visibleEditMovieDrawer]);

    useEffect(() => {
        if (currentMoves) {
            const genersIds = currentMoves.vod_geners.map((item) => item.genre_id);
            form.setFields([
                { name: "name", value: currentMoves.name },
                { name: "url", value: currentMoves.url },
                { name: "price", value: currentMoves.price },
                { name: "overview", value: currentMoves.overview },
                { name: "runtime", value: currentMoves.runtime },
                { name: "type", value: currentMoves.type },
                { name: "year", value: moment(currentMoves.year) },
                { name: "geners_id", value: genersIds },
            ]);

            setPosterSrc(currentMoves.poster);
            setBackdropSrc(currentMoves.backdrop);
            setVideoSrc(currentMoves.url);

            setMovieType(currentMoves.type);
        }
    }, [currentMoves]);

    const [form] = Form.useForm();

    const onFinish = (values) => {
        setLoading(true);
        setErrorText(null);

        let body = {
            poster: values.poster,
            backdrop: values.backdrop,
            name: values.name,
            url: values.url,
            geners_id: JSON.stringify(values.geners_id),
            type: values.type,
            price: values.price,
            overview: values.overview,
            runtime: values.runtime,
            year: values.year,
        };

        function callback() {
            setLoading(false);

            if (visibleEditMovieDrawer) {
                setVisibleEditMovieDrawer(false);
            }

            if (visibleAddMovieDrawer) {
                setVisibleAddMovieDrawer(false);
            }

            setCurrentMoves(null);
            getMovesList();

            form.resetFields();
            setPosterSrc(null);
            setBackdropSrc(null);
            setVideoSrc(null);
        }

        function errorCallback(err) {
            setErrorText(err);
            setLoading(false);
        }

        if (currentMoves) {
            body.id = currentMoves.id;
            REQUESTS.VOD.MOVIES.EDIT(body, callback, errorCallback);
        } else {
            REQUESTS.VOD.MOVIES.ADD(body, callback, errorCallback);
        }
    };

    const onFinishFailed = () => {
        setErrorText(null);
    };

    const onValuesChange = (a, b) => {
        if (Object.keys(a)[0] === "type") {
            let val = Object.values(a);
            val = val[0];

            setMovieType(val);
        }
    };

    const choosePoster = (item) => {
        setPosterSrc(item.file_url);
        form.setFields([{ name: "poster", value: item.file_url }]);
    };

    const replicePoster = () => {
        setPosterSrc(null);
        form.setFields([{ name: "poster", value: null }]);
    };

    const chooseBackdrop = (item) => {
        setBackdropSrc(item.file_url);
        form.setFields([{ name: "backdrop", value: item.file_url }]);
    };

    const repliceBackdrop = () => {
        setBackdropSrc(null);
        form.setFields([{ name: "backdrop", value: null }]);
    };

    const chooseVideo = (item) => {
        setVideoSrc(item.file_url);
        form.setFields([{ name: "url", value: item.file_url }]);
    };

    const repliceVideo = () => {
        setVideoSrc(null);
        form.setFields([{ name: "url", value: null }]);
    };

    const finishVideoUploading = (data) => {
        if (currentMoves) {
            function callback() {
                notification.success({
                    description: `${data.name} file attached to ${currentMoves.name}`,
                });
            }

            function errorCallback(err) {
                notification.error({
                    description: err,
                });
            }

            if (data) {
                const body = {
                    id: currentMoves.id,
                    url: data.file_url,
                };
                REQUESTS.VOD.MOVIES.EDIT(body, callback, errorCallback);
            }
        }
    };

    return (
        <div>
            <Form
                form={form}
                layout="vertical"
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                onValuesChange={onValuesChange}
                initialValues={{
                    price: 0,
                }}
            >
                <div className="movie-form-section">
                    <section>
                        <Form.Item
                            label={translate['Poster'] || EN['Poster']}
                            tooltip={{
                                title: "Choose an poster",
                                icon: ICONS.INFO,
                            }}
                            name="poster"
                            rules={[
                                {
                                    required: currentMoves ? false : true,
                                    message: `${translate['Please choose'] || EN['Please choose']} ${translate['Poster'] || EN['Poster']}`,
                                },
                            ]}
                        >
                            <FileChooser
                                src={posterSrc}
                                onChange={choosePoster}
                                replace={replicePoster}
                                types={["image"]}
                            />
                        </Form.Item>

                        <Form.Item
                            label={translate['Name'] || EN['Name']}
                            tooltip={{
                                title: "Movie name",
                                icon: ICONS.INFO,
                            }}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: `${translate['Please input'] || EN['Please input']} ${translate['Name'] || EN['Name']}`,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label={translate['Categories'] || EN['Categories']}
                            tooltip={{
                                title: "Categories",
                                icon: ICONS.INFO,
                            }}
                            name="geners_id"
                            rules={[
                                {
                                    required: true,
                                    message: `${translate['Please select'] || EN['Please select']} ${translate['Categories'] || EN['Categories']}`,
                                },
                            ]}
                        >
                            <Select options={moviesCategories} mode="multiple" />
                        </Form.Item>

                        <Form.Item
                            label={translate['Description'] || EN['Description']}
                            tooltip={{
                                title: "Movie description",
                                icon: ICONS.INFO,
                            }}
                        >
                            <Form.Item name="overview" noStyle>
                                <Input.TextArea style={{ height: 120 }} />
                            </Form.Item>
                        </Form.Item>
                    </section>

                    <section>
                        <Form.Item
                            label={translate['Backdrop'] || EN['Backdrop']}
                            tooltip={{
                                title: "Choose an backdrop",
                                icon: ICONS.INFO,
                            }}
                            name="backdrop"
                            rules={[
                                {
                                    required: currentMoves ? false : true,
                                    message: `${translate['Please choose'] || EN['Please choose']} ${translate['Backdrop'] || EN['Backdrop']}`,
                                },
                            ]}
                        >
                            <FileChooser
                                src={backdropSrc}
                                onChange={chooseBackdrop}
                                replace={repliceBackdrop}
                                types={["image"]}
                            />
                        </Form.Item>

                        <div className="type-price">
                            <Form.Item
                                label={translate['Type'] || EN['Type']}
                                tooltip={{
                                    title: "Type",
                                    icon: ICONS.INFO,
                                }}
                                name="type"
                                rules={[
                                    {
                                        required: true,
                                        message: `${translate['Please select'] || EN['Please select']} ${translate['Type'] || EN['Type']}`,
                                    },
                                ]}
                            >
                                <Select options={movieTypes} />
                            </Form.Item>

                            <Form.Item
                                label={translate['Price'] || EN['Price']}
                                tooltip={{
                                    title: "Price",
                                    icon: ICONS.INFO,
                                }}
                                name="price"
                                rules={[
                                    {
                                        required: true,
                                        message: `${translate['Price'] || EN['Price']}`,
                                    },
                                ]}
                            >
                                <Input type="number" />
                            </Form.Item>
                        </div>

                        <div className="type-price">
                            <Form.Item
                                label={translate['Year'] || EN['Year']}
                                tooltip={{
                                    title: "Choose a year",
                                    icon: ICONS.INFO,
                                }}
                                name="year"
                                rules={[
                                    {
                                        required: true,
                                        message: `${translate['Please select'] || EN['Please select']} ${translate['Year'] || EN['Year']}`,
                                    },
                                ]}
                            >
                                <DatePicker
                                    format="DD-MM-YYYY"
                                    style={{ width: "100%" }}
                                    onChange={(e) => {
                                        // console.log(e);
                                    }}
                                />
                            </Form.Item>

                            <Form.Item
                                label={translate['Runtime'] || EN['Runtime']}
                                tooltip={{
                                    title: "Runtime",
                                    icon: ICONS.INFO,
                                }}
                                name="runtime"
                                rules={[
                                    {
                                        required: true,
                                        message: `${translate['Runtime'] || EN['Runtime']}`,
                                    },
                                ]}
                            >
                                <Input type="number" />
                            </Form.Item>
                        </div>

                        <Form.Item
                            label={translate['Url'] || EN['Url']}
                            tooltip={{
                                title: "Video",
                                icon: ICONS.INFO,
                            }}
                            name="url"
                        >
                            <Input type="url" />
                        </Form.Item>
                        {movieType === "movie" && (
                            <FileChooser
                                src={videoSrc}
                                onChange={chooseVideo}
                                replace={repliceVideo}
                                types={["video"]}
                                callback={finishVideoUploading}
                                dontAllowChoose={!currentMoves ? true : false}
                            />
                        )}

                        {/* {movieType === "movie" && currentMoves && (
                            <FileChooser
                                src={videoSrc}
                                onChange={chooseVideo}
                                replace={repliceVideo}
                                types={["video"]}
                                callback={finishVideoUploading}
                            />
                        )} */}
                    </section>
                </div>
                {movieType === "tv" && currentMoves && (
                    <Seasons id={currentMoves && currentMoves.id} movie={currentMoves} />
                )}

                <Form.Item>
                    <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
                </Form.Item>
            </Form>

            <ErrorMessage>{errorText}</ErrorMessage>
        </div>
    );
}
