import { useState, useEffect } from "react";
import { Button, notification, Table, Typography, Tag } from "antd";
import TableButtons from "../../../../elements/TableButtons";
import TableHead from "./TableHead";
import Page from "../../../../elements/Page";
import ICONS from "../../../../../config/icons";
import { useSelector } from "react-redux";
import EN from "../../../../../config/en";
import HeaderItem from "../../../../elements/HeaderItem";
import REQUESTS from "../../../../../server/requests";
import AddButtonComponent from "../../../../elements/AddButtonComponent";
import FacilitiesPageDrawer from "./drawer/FacilitiesPageDrawer";
import confirm from "antd/lib/modal/confirm";

const FacilitiesPage = () => {
  const translation = useSelector((state) => state.globalState.translate);

  const [dataSource, setDataSource] = useState([]);
  const [childrenOption, setChildrenOption] = useState([]);
  const [editable, setEditable] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const columns = [
    {
      title: "#",
      key: "id",
      dataIndex: "id",
      align: "center",
      width: 50,
      fixed: "left",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      align: "center",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "center",
    },
    {
      title: "Property",
      dataIndex: "property",
      key: "property",
      align: "center",
      render: (text, record) => {
        return (
          <div>
            {record?.property?.length
              ? record?.property?.map((item, index) => (
                  <Tag key={index} color="blue">
                    {item.title}
                  </Tag>
                ))
              : "N/A"}
          </div>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      fixed: "right",
      width: 100,
      render: (text, record) => {
        return (
          <TableButtons
            handleMenuClick={(e) => handleMenuClick(e.key, record)}
            buttons={[
              { key: "edit", text: "Edit", icon: ICONS.EDIT },
              {
                key: "delete",
                text: "Delete",
                icon: ICONS.DELETE,
              },
            ]}
          />
        );
      },
    },
  ];

  const getDataSource = () => {
    setLoading(true);
    const query = {
      limit,
      page: currentPage,
      total,
    };

    try {
      const successCallBack = (data) => {
        setLoading(false);

        const newDataSource = data?.rows?.map((item) => {
          return {
            id: item?.id,
            title: item.title,
            price: item.price,
            property: item?.children,
          };
        });

        const newChildrenOption = data?.rows?.map((item) => {
          return {
            label: item.title,
            value: item.id,
          };
        });

        const filterOptions = childrenOption.filter(
          (item) => item.value !== editable?.id
        );

        setDataSource(newDataSource);
        setChildrenOption(filterOptions);
        setTotal(data.total);
      };

      const errorCallBack = (error) => {
        setLoading(false);
        console.log(error);
      };

      REQUESTS.BOOKING.FACILITY.GET(query, successCallBack, errorCallBack);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = (values) => {
    if (editable) {
      handleUpdate(values);
    } else {
      try {
        const successCallBack = (data) => {
          setLoading(false);
          notification.success({
            message:
              translation["Facilities added successfully"] ||
              EN["Facilities added successfully"],
          });
          setShowDrawer(false);
          getDataSource();
        };

        const errorCallBack = (error) => {
          setLoading(false);
          console.log(error);
          notification.error({
            message: translation["An error occurred"] || EN["An error occurred"],
          });
        };

        REQUESTS.BOOKING.FACILITY.ADD(values, successCallBack, errorCallBack);
      } catch (error) {
        console.log(error);
      }
    }

    setLoading(true);
  };

  const handleUpdate = (values) => {
    setLoading(true);
    try {
      const successCallBack = (data) => {
        setLoading(false);
        notification.success({
          message:
            translation["Facilities info updated successfully"] ||
            EN["Facilities info updated successfully"],
        });
        setShowDrawer(false);
        getDataSource();
      };

      const errorCallBack = (error) => {
        setLoading(false);
        console.log(error);
        notification.error({
          message: translation["An error occurred"] || EN["An error occurred"],
        });
      };

      REQUESTS.BOOKING.FACILITY.EDIT(values, editable.id, successCallBack, errorCallBack);
    } catch (error) {
      console.log(error);
    }
  };

  const handleMenuClick = (key, record) => {
    switch (key) {
      case "edit":
        setShowDrawer(true);
        setEditable(record);
        break;
      case "delete":
        confirm({
          title: translation["Do you want to delete"] || EN["Do you want to delete"],
          onOk() {
            handleDelete(record.id);
          },
        });
        break;
      default:
        break;
    }
  };

  const handleDelete = (id) => {
    try {
      const successCallBack = (data) => {
        notification.success({
          message:
            translation["Facilities deleted successfully"] ||
            EN["Facilities deleted successfully"],
        });

        getDataSource();
      };

      const errorCallBack = (error) => {
        console.log(error);
        notification.error({
          message: translation["An error occurred"] || EN["An error occurred"],
        });
      };

      REQUESTS.BOOKING.FACILITY.DELETE(id, successCallBack, errorCallBack);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  useEffect(() => {
    getDataSource();
  }, [limit, currentPage, total]);

  return (
    <Page title="Facilities">
      <div className="page-body">
        <h1>{translation["Facilities Page"] || EN["Facilities Page"]}</h1>
        <HeaderItem
          total={dataSource?.length}
          title={translation["Facilities information"] || EN["Facilities information"]}
        />

        <div
          style={{
            marginBottom: 30,
          }}
        >
          <TableHead>
            <AddButtonComponent
              onClick={() => setShowDrawer(true)}
              text={translation["Add Facility"] || EN["Add Facility"]}
            />
          </TableHead>
        </div>

        <Table
          columns={columns}
          dataSource={dataSource}
          onChange={handleTableChange}
          loading={loading}
          pagination={{
            position: ["bottomCenter"],
            total,
            current: currentPage,
            showSizeChanger: true,
          }}
        />
      </div>
      <FacilitiesPageDrawer
        open={showDrawer}
        onClose={() => {
          setShowDrawer(false);
          setEditable(null);
        }}
        options={childrenOption}
        save={handleSave}
        editable={editable}
        loading={loading}
      />
    </Page>
  );
};

export default FacilitiesPage;
