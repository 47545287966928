import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Drawer } from "antd";
import EN from "../../../../../config/en";
import Player from "../../../../elements/Player";

export default function PlayChannelDrawer({ visible, onClose, channel }) {
    const [url, setUrl] = useState("");
    const translate = useSelector((state) => state.globalState.translate);

    useEffect(() => {
        if (channel && channel.url) {
            setUrl(channel.url);
        } else {
            setUrl("");
        }
    }, [channel]);

    return (
        <Drawer
            title={translate['Play Tv Channel'] || EN['Play Tv Channel']}
            placement="top"
            width={500}
            height={1000}
            onClose={onClose}
            visible={visible}
        >
            <Player url={url} />
        </Drawer>
    );
}
