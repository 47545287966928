import { useState } from "react";
import { Modal, Form, Input, Button } from "antd";
import EN from "../../config/en";
import { useSelector } from "react-redux";
import REQUESTS from "../../server/requests";

const AddDeviceModal = ({ open, onClose, getData, skip }) => {
  const [form] = Form.useForm();

  const { translate } = useSelector((state) => state.globalState);

  const [errorMessage, setErrorMessage] = useState("");

  const getAsyncCode = (data) => {
    REQUESTS.ROOMS.DEVICES.SEND_CODE(
      data,
      (res) => {
        getData(res);
        form.resetFields();
        setErrorMessage("");
      },
      (err) => {
        setErrorMessage(translate["Device not found"] || EN["Device not found"]);
        console.log(err);
      }
    );
  };

  return (
    <>
      <Modal
        open={open}
        onCancel={() => {
          onClose();
          form.resetFields();
          setErrorMessage("");
        }}
        footer={[
          <Button
            type="default"
            onClick={() => {
              skip();
              form.resetFields();
              setErrorMessage("");
            }}
          >
            {translate["Skip"] || EN["Skip"]}
          </Button>,
          <Button
            type="primary"
            onClick={() => {
              getAsyncCode(form.getFieldsValue());
            }}
          >
            {translate["Continue"] || EN["Continue"]}
          </Button>,
        ]}
        title={translate["Enter Code"] || EN["Enter Code"]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="code"
            label="Enter code"
            rules={[{ required: false }]}
            validateStatus={errorMessage && "error"}
            help={errorMessage}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddDeviceModal;
