import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Drawer, Form, Select, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import REQUESTS from "../../../../server/requests";

import ErrorMessage from "../../../elements/ErrorMessage";
import SaveButtonComponent from "../../../elements/SaveButtonComponent";

import EN from "../../../../config/en";

import {
  useRoomsGroupsOptions,
  usePackagesOptions
} from "../../../../hooks/options";

export default function ImportFromCsvDrawer({ visible, onClose, getData }) {
  const translate = useSelector((state) => state.globalState.translate);

  const groupsOptions = useRoomsGroupsOptions();
  const packagesOptions = usePackagesOptions();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState(null);

  useEffect(() => {
    if (visible) {
      form.resetFields();
    } else {
      setErrorText(null);
    }
  }, [visible]);

  const onFinish = (values) => {
    console.log(values);
    setLoading(true);
    setErrorText(null);

    const formData = new FormData();

    if (values.file) {
      formData.append("file", values.file?.file?.originFileObj);
    }

    if (values.room_group_id) {
      formData.append("room_group_id", values.room_group_id);
    }

    if (values.package_id) {
      formData.append("package_id", values.package_id);
    }

    const callback = (res) => {
      message.success("CSV file imported successfully");
      setLoading(false);
      getData();
      onClose();
    };

    const errorCallback = (err) => {
      setErrorText(err);
      setLoading(false);
    };

    REQUESTS.IMPORT_CVS(formData, callback, errorCallback);
  };

  const onFinishFailed = () => {
    setErrorText(null);
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      //   onChange(info);
      console.log(info);

      // Get this url from response in real world.
    }
  };

  return (
    <Drawer
      title={translate["Import from CSV"] || EN["Import from CSV"]}
      placement="right"
      width={400}
      visible={visible}
      onClose={onClose}
    >
      <Form
        form={form}
        layout="vertical"
        name="import-from-csv-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="file"
          label={translate["File"] || EN["File"]}
          rules={[
            {
              required: true,
              message: `${translate["Please choose"] || EN["Please choose"]} ${
                translate["File"] || EN["File"]
              }`
            }
          ]}
        >
          <Upload
            multiple={false}
            maxCount={1}
            accept=".csv, application/csv, text/csv"
            onChange={handleChange}
            customRequest={({ file, onSuccess }) => {
              setTimeout(() => {
                form.setFieldsValue({ file: file });
                onSuccess("ok");
              }, 0);
            }}
          >
            <Button icon={<UploadOutlined />} style={{ width: 350 }}>
              Click to Upload
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item
          label={translate["Group"] || EN["Group"]}
          name="room_group_id"
        >
          <Select showArrow allowClear={true} options={groupsOptions} />
        </Form.Item>

        <Form.Item
          label={translate["Package"] || EN["Package"]}
          name="package_id"
        >
          <Select showArrow allowClear={true} options={packagesOptions} />
        </Form.Item>

        <Form.Item>
          <SaveButtonComponent
            loading={loading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
      <ErrorMessage>{errorText}</ErrorMessage>
    </Drawer>
  );
}
