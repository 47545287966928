import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { Form, Select } from "antd";

import EN from "../../../../config/en";

import ICONS from "../../../../config/icons";

import REQUESTS from "../../../../server/requests";

import ErrorMessage from "../../../elements/ErrorMessage";

import SaveButtonComponent from "../../../elements/SaveButtonComponent";

export default function ConfigAirports({ onClose }) {
  const { translate } = useSelector((state) => state.globalState);

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const [errorText, setErrorText] = useState(null);

  const [airportsOptions, setAirportsOptions] = useState([]);

  const getAirports = () => {
    REQUESTS.AIRPORTS.GET((data) => {
      const options = data.map((item) => {
        return {
          label: `${item.name} (${item.iata})`,
          value: item.id,
          is_enabled: item.is_enabled
        };
      });

      const enableds = options
        .filter((item) => item.is_enabled)
        .map((item) => {
          return item.value;
        });

      form.setFieldsValue({
        id: enableds
      });

      setAirportsOptions(options);
    });
  };

  const onFinish = (values) => {
    setLoading(true);

    setErrorText(null);

    function callback(data) {
      setLoading(false);
      onClose();
    }

    function errorCallback(err) {
      setLoading(false);
      setErrorText(err);
    }

    const body = {
      id: JSON.stringify(values.id)
    };

    REQUESTS.AIRPORTS.EDIT(body, callback, errorCallback);
  };

  const onFinishFailed = () => {
    setErrorText(null);
  };

  useEffect(() => {
    getAirports();
  }, []);

  return (
    <Form
      form={form}
      layout="vertical"
      name="gui-form"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label={translate["Airports"] || EN["Airports"]}
        tooltip={{
          title: "Airports",
          icon: ICONS.INFO
        }}
        name="id"
        rules={[
          {
            required: true,
            message: `${translate["Please select"] || EN["Please select"]} ${
              translate["Airports"] || EN["Airports"]
            }`
          }
        ]}
      >
        <Select
          mode="multiple"
          showArrow
          allowClear={true}
          options={airportsOptions}
          filterOption={(input, option) =>
            option.label.toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>

      <Form.Item>
        <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
      </Form.Item>

      <ErrorMessage>{errorText}</ErrorMessage>
    </Form>
  );
}
