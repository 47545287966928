import React, { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Drawer, Select } from "antd";

import ServiceContext from "./ServiceContext";

import {
  useEcommerceCategoriesOptions,
  useEmployersOptions,
  usePublishedStatusOptions,
} from "../../../../hooks/options";

import EN from "../../../../config/en";
import ICONS from "../../../../config/icons";
import BUTTON_CLASSES from "../../../../config/button-classes";

import ButtonComponent from "../../../elements/ButtonComponent";

export default function FilterDrawer() {
  const {
    visibleFilterDrawer,
    setVisibleFilterDrawer,
    setSelectedEmployerId,
    setSelectedCategoryId,
    setSelectedPublishedStatus,
  } = useContext(ServiceContext);

  const translate = useSelector((state) => state.globalState.translate);

  const employersOptions = useEmployersOptions();
  const publishedStatusOptions = usePublishedStatusOptions();
  const categoriesOptions = useEcommerceCategoriesOptions();

  const [selectedCategory, setSelectedCategory] = useState("");

  const [selectedEmployer, setSelectedEmployer] = useState("");

  const [selectedStatus, setSelectedStatus] = useState(null);

  const onClickFilter = () => {
    setSelectedCategoryId(selectedCategory);
    setSelectedEmployerId(selectedEmployer);
    setSelectedPublishedStatus(selectedStatus);

    setVisibleFilterDrawer(false);
  };

  return (
    <Drawer
      title={translate['Filter'] || EN['Filter']}
      placement="right"
      visible={visibleFilterDrawer}
      onClose={() => setVisibleFilterDrawer(false)}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h1>{translate['Select filter parameters'] || EN['Select filter parameters']}</h1>

        <p>{translate['Categories'] || EN['Categories']}:</p>
        <Select
          showArrow
          allowClear={true}
          value={selectedCategory}
          onChange={setSelectedCategory}
          options={categoriesOptions}
          style={{ width: "100%", marginBottom: 20 }}
        />

        <p>{translate['User'] || EN['User']}:</p>
        <Select
          showArrow
          allowClear={true}
          value={selectedEmployer}
          onChange={setSelectedEmployer}
          options={employersOptions}
          style={{ width: "100%", marginBottom: 20 }}
        />

        <p>{translate['Attach Slider'] || EN['Attach Slider']}:</p>
        <Select
          showArrow
          allowClear={true}
          value={selectedStatus}
          onChange={setSelectedStatus}
          options={publishedStatusOptions}
          style={{ width: "100%", marginBottom: 20 }}
        />

        <ButtonComponent
          text={translate['Filter'] || EN['Filter']}
          icon={ICONS.ARROW_RIGHT_OUTLINED}
          buttonClass={BUTTON_CLASSES.APP_BUTTON}
          onClick={onClickFilter}
        />
      </div>
    </Drawer>
  );
}
