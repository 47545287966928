import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table, message, notification, Input } from "antd";
import { useNavigate } from "react-router-dom";
import NAVIGATORS from "../../../../config/navigators";

import REQUESTS from "../../../../server/requests";

import EN from "../../../../config/en";
import ICONS from "../../../../config/icons";
import confirmModal from "../../../../config/confirmModal";
import { dateFormat } from "../../../../config/formats";

import ButtonComponent from "../../../elements/ButtonComponent";
import TableButtons from "../../../elements/TableButtons";
import Page from "../../../elements/Page";

import SubTable from "./SubTable";
import RoomDrawer from "./RoomDrawer";
import RoomsHeader from "./RoomsHeader";
import FilterDrawer from "./FilterDrawer";
import NewRoomDrawer from "./NewRoomDrawer";
import FiltersSection from "./FIltersSection";
import ViewSettingsDrawer from "./ViewSettingsDrawer";
import AddOrdersDrawer from "./AddOrdersDrawer";

import { useRoomsGroupsOptions } from "../../../../hooks/options";
import "./../_rooms.scss";
import ImportFromCsvDrawer from "./ImortFromCsvDrawer";

export default function RoomsPage() {
  const translate = useSelector((state) => state.globalState.translate);

  const navigate = useNavigate();

  // * * * DRAWERS * * *
  const [visibleFilterDrawer, setVisibleFilterDrawer] = useState(false);
  const [isVisibleAddNewRoomModal, setIsVisibleAddNewRoomModal] = useState(false);
  const [isVisibleViewSettingsDrawer, setIsVisibleViewSettingsDrawer] = useState(false);
  const [visibleOrdersDrawer, setVisibleOrdersDrawer] = useState(false);
  const [isVisibleImportFromCsvDrawer, setIsVisibleImportFromCsvDrawer] = useState(false);

  const [currentRoom, setCurrentRoom] = useState(null);

  const [currentRoomId, setCurrentRoomId] = useState(null);

  const [currentNewRoom, setCurrentNewRoom] = useState(null);

  const [packagesOption, setPackagesOption] = useState([]);

  const [selectedGroupId, setSelectedGroupId] = useState(null);

  // * * * TABLE DATA * * *
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [sort, setSort] = useState(["id", "DESC"]);
  const [search, setSearch] = useState({
    location_no: "",
    location_name: "",
    guestName: "",
    guests_phone: "",
    guests_email: "",
  });

  const [guestSearch, setGuestSearch] = useState("");
  const [groupSearch, setGroupSearch] = useState("");

  const [emailSearch, setEmailSearch] = useState("");
  const [phoneSearch, setPhoneSearch] = useState("");

  const [loading, setLoading] = useState(false);
  const [roomsList, setRoomsList] = useState([]);

  const [columns, setColumns] = useState([]);

  const [viewSettingsValues, setViewSettingsValues] = useState({
    roomNumber: true,
    locationName: true,
    guestName: true,
    language: true,
    country: true,
    email: true,
    phone: true,
    reservation_number: true,
    bill: true,
    group: true,
    comment: true,
    arival_date: true,
    planned_departure_date: true,
    feedbacks: true,
  });
  const [formApplication, setFormApplication] = useState([]);

  const groupOptions = useRoomsGroupsOptions();

  useEffect(() => {
    if (!isVisibleAddNewRoomModal) {
      setCurrentNewRoom(null);
    }
  }, [isVisibleAddNewRoomModal]);

  useEffect(() => {
    getRoomsList();
  }, [
    currentPage,
    limit,
    selectedGroupId,
    sort,
    guestSearch,
    groupSearch,
    emailSearch,
    phoneSearch,
  ]);

  useEffect(() => {
    setTimeout(() => {
      configureTableColumns();
    }, 200);
  }, [viewSettingsValues, groupOptions, currentPage, selectedGroupId]);

  useEffect(() => {
    let VIEW_SETTINGS_VALUES = localStorage.getItem("VIEW_SETTINGS_VALUES");

    if (VIEW_SETTINGS_VALUES) {
      VIEW_SETTINGS_VALUES = JSON.parse(VIEW_SETTINGS_VALUES);

      setViewSettingsValues(VIEW_SETTINGS_VALUES);
    }

    getFormApplicationAsync();

    getPackagesOptions();
  }, []);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      getRoomsList();
    }, 1000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [search]);

  const getFormApplicationAsync = () => {
    REQUESTS.HOST_GROUPS.GET_APPLICATION_FORM(
      {},
      (data) => {
        setFormApplication(data);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const configureTableColumns = () => {
    let newArr = [];

    newArr.push({
      title: "#",
      dataIndex: "id",
      fixed: "left",
      width: 60,
      key: "id",
      align: "center",
      render: (text, record, index) => (currentPage - 1) * limit + 1 + index,
    });

    if (viewSettingsValues.roomNumber) {
      newArr.push({
        title: `${translate["Location No"] || EN["Location No"]}`,
        dataIndex: "location_no",
        key: "location_no",
        sorter: true,
        width: 150,
        ellipsis: true,
        render: (text, record, index) => <p className="txt-tb-r">{record.location_no}</p>,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <>{ICONS.SEARCHOUTLINED}</>;
        },
        onFilter: (value, record) => {
          return record.location_no.toString().includes(value);
        },
      });
    }

    if (viewSettingsValues.locationName) {
      newArr.push({
        title: `${translate["Location name"] || EN["Location name"]}`,
        dataIndex: "location_name",
        key: "location_name",
        width: 150,
        ellipsis: true,
        render: (text, record, index) => (
          <p>{record.location_name ? record.location_name : "-"}</p>
        ),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <>{ICONS.SEARCHOUTLINED}</>;
        },
        onFilter: (value, record) => {
          return record.location_name.toLowerCase().includes(value.toLowerCase());
        },
      });
    }

    if (viewSettingsValues.group) {
      newArr.push({
        width: 150,
        ellipsis: true,
        title: translate["Category"] || EN["Category"],
        dataIndex: "room_group",
        key: "room_group",
        render: (text, record, index) => (
          <p className="text-ellipsis">
            {record.room_group ? record.room_group.name : "-"}
          </p>
        ),
        filterDropdown: ({ confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={groupSearch[0]}
              onChange={(e) => {
                setGroupSearch(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <>{ICONS.SEARCHOUTLINED}</>;
        },
        onFilter: (value, record) => {
          return record.room_group?.name.toLowerCase().includes(value.toLowerCase());
        },

        // render: (text, record, index) => {
        // if (record.group_id) {
        //   const group = groupOptions.map((item) => {
        //     if (item.value === record.group_id) {
        //       return item.label;
        //     }
        //   });
        //   return <p>{group}</p>;
        // } else {
        //   return "-";
        // }
        // },
      });
    }

    if (viewSettingsValues.guestName) {
      newArr.push({
        width: 150,
        ellipsis: true,
        title: translate["Guest Name"] || EN["Guest Name"],
        dataIndex: "guest",
        key: "guest",
        render: (text, record, index) => (
          <p className="text-ellipsis">
            {record.guest ? record.guest.name : translate["No guest"] || EN["No guest"]}
          </p>
        ),
        filterDropdown: ({ confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={guestSearch[0]}
              onChange={(e) => {
                setGuestSearch(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <>{ICONS.SEARCHOUTLINED}</>;
        },
        onFilter: (value, record) => {
          return record?.guest?.name.toLowerCase().includes(value?.toLowerCase());
        },
      });
    }
    if (viewSettingsValues.language) {
      newArr.push({
        title: translate[["Language"]] || EN["Language"],
        dataIndex: "language",
        key: "language",
        sorter: true,
        width: 150,
        ellipsis: true,
        render: (text, record, index) => (
          <p>{record.guest?.language ? record.guest?.language?.name : "-"}</p>
        ),
      });
    }
    if (viewSettingsValues.country) {
      newArr.push({
        title: translate["Country"] || EN["Country"],
        dataIndex: "country",
        key: "country",
        width: 150,
        ellipsis: true,
        render: (text, record, index) => {
          return <p>{record.guest?.country ? record.guest?.country?.name : "-"}</p>;
        },
      });
    }

    if (viewSettingsValues.email) {
      newArr.push({
        width: 150,
        ellipsis: true,
        title: translate["Email"] || EN["Email"],
        dataIndex: "email",
        key: "email",
        render: (text, record, index) => (
          <p className="text-ellipsis">{record.guest ? record.guest.email : "-"}</p>
        ),
        filterDropdown: ({ confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={emailSearch[0]}
              onChange={(e) => {
                console.log(e);
                setEmailSearch(e.target?.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <>{ICONS.SEARCHOUTLINED}</>;
        },
        onFilter: (value, record) => {
          return record.guest?.email.toLowerCase().includes(value.toLowerCase());
        },
      });
    }

    if (viewSettingsValues.phone) {
      newArr.push({
        width: 150,
        ellipsis: true,
        title: translate["Phone"] || EN["Phone"],
        dataIndex: "phone",
        key: "phone",
        render: (text, record, index) => (
          <p className="text-ellipsis txt-tb-r">
            {record.guest ? record.guest.phone : "-"}
          </p>
        ),
        filterDropdown: ({ confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={phoneSearch[0]}
              onChange={(e) => {
                console.log(e);
                setPhoneSearch(e.target?.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <>{ICONS.SEARCHOUTLINED}</>;
        },
        onFilter: (value, record) => {
          return record.guest?.phone.toLowerCase().includes(value.toLowerCase());
        },
      });
    }

    if (viewSettingsValues.bill) {
      newArr.push({
        title: translate["Due"] || EN["Due"],
        dataIndex: "balance",
        key: "balance",
        sorter: true,
        width: 150,
        ellipsis: true,
        render: (text, record, index) => <p className="txt-tb-r">{text}</p>,
      });
    }
    if (viewSettingsValues.reservation_number) {
      newArr.push({
        title: translate["Reservation Number"] || EN["Reservation Number"],
        dataIndex: "reservation_number",
        key: "reservation_number",
        sorter: true,
        width: 150,
        ellipsis: true,
        render: (text, record, index) => (
          <p className="txt-tb-r">
            {record.guest ? record.guest.reservation_number : "-"}
          </p>
        ),
      });
    }

    if (viewSettingsValues.comment) {
      newArr.push({
        title: translate["Comment"] || EN["Comment"],
        dataIndex: "comment",
        key: "comment",
        sorter: true,
        width: 150,
        ellipsis: true,
        render: (text, record, index) => (record.guest ? record.guest.comment : "-"),
      });
    }

    if (viewSettingsValues.feedbacks) {
      newArr.push({
        title: translate["Feedback"] || EN["Feedback"],
        dataIndex: "feedbacks",
        key: "feedbacks",
        sorter: true,
        width: 150,
        ellipsis: true,
        render: (text, record, index) => (record.guest ? record.guest.feedbacks : "-"),
      });
    }
    if (viewSettingsValues.arival_date) {
      newArr.push({
        title: translate["Arrival Date"] || EN["Arrival Date"],
        dataIndex: "arival_date",
        key: "arival_date",
        sorter: true,
        width: 150,
        ellipsis: true,
        render: (text, record, index) =>
          record.arival_date ? dateFormat(record.arival_date) : "-",
      });
    }

    if (viewSettingsValues.planned_departure_date) {
      newArr.push({
        title: translate["Planned Departure Date"] || EN["Planned Departure Date"],
        dataIndex: "planned_departure_date",
        key: "planned_departure_date",
        sorter: true,
        width: 150,
        ellipsis: true,

        render: (text, record, index) =>
          record.planned_departure_date ? dateFormat(record.planned_departure_date) : "-",
      });
    }

    newArr.push({
      width: 200,
      title: translate["Check In / Check out"] || EN["Check In / Check out"],
      fixed: "right",
      dataIndex: "action",
      ellipsis: true,
      align: "center",
      render: (text, record, index) =>
        record.guest ? (
          <div onClick={(e) => e.stopPropagation()}>
            <ButtonComponent
              style={{ width: "70%" }}
              text={translate["Deactivate"] || EN["Deactivate"]}
              icon={ICONS.USER_MINUS}
              onClick={() => onClickCheckOut(record)}
            />
          </div>
        ) : (
          <div onClick={(e) => e.stopPropagation()}>
            <ButtonComponent
              // style={{ width: "100%" }}
              text={translate["Activate"] || EN["Activate"]}
              icon={ICONS.USER_PLUS}
              onClick={() => setCurrentRoom(record)}
            />
          </div>
        ),
    });

    newArr.push({
      width: 80,
      title: "",
      fixed: "right",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      ellipsis: true,

      render: (text, record, index) => (
        <div onClick={(e) => e.stopPropagation()}>
          <TableButtons
            handleMenuClick={(e) => handleMenuClick(e, record)}
            style={{ minWidth: 130 }}
            buttons={[
              {
                key: "orders",
                text: translate["Orders"] || EN["Orders"],
                icon: ICONS.ORDERS,
              },
              {
                key: "edit",
                text: translate["Edit"] || EN["Edit"],
                icon: ICONS.EDIT,
              },
              {
                key: "delete",
                text: translate["Delete"] || EN["Delete"],
                icon: ICONS.DELETE,
              },
            ]}
          />
        </div>
      ),
    });

    setColumns(newArr);

    localStorage.setItem("VIEW_SETTINGS_VALUES", JSON.stringify(viewSettingsValues));
  };

  const onChangeSettingsValues = (event) => {
    viewSettingsValues[event.target.name] = event.target.checked;
    setViewSettingsValues({ ...viewSettingsValues });
  };

  const onClickCheckOut = (room) => {
    confirmModal({
      title: `${translate["Do you want to delete"] || EN["Do you want to delete"]} ${
        translate["Guest"] || EN["Guest"]
      }`,

      action: () =>
        REQUESTS.GUEST.DELETE(
          room.current_guest_id,
          (data) => {
            getRoomsList();
          },
          (err) => {
            notification.error({ description: err });
          }
        ),
    });
  };

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "orders":
        setCurrentRoomId(item);
        setVisibleOrdersDrawer(true);
        break;

      case "edit":
        setCurrentNewRoom(item);
        setIsVisibleAddNewRoomModal(true);
        break;

      case "delete":
        confirmModal({
          title: `${translate["Do you want to delete"] || EN["Do you want to delete"]} ${
            item.location_name
          }`,
          action: () => deleteRoom(item),
        });
        break;

      default:
        break;
    }
  };

  const deleteRoom = (item) => {
    REQUESTS.ROOMS.DELETE(item.id, getRoomsList, (err) => {
      message.error(err);
    });
  };
  const getRoomsList = () => {
    setLoading(true);
    const query = {
      sort,
      limit,
      pagination: 1,
      page: currentPage,
    };

    if (
      search.location_no ||
      search.location_name ||
      search.guests_email ||
      search.guests_phone
    ) {
      query.search = JSON.stringify({
        location_no: search.location_no && search.location_no[0],
        location_name: search.location_name && search.location_name[0],

        guests_name: search.location_name && search.location_name[0],
        email: search.guests_email && search.email[0],
        phone: search.guests_phone && search.guests_phone[0],
      });
    }

    if (selectedGroupId) {
      query.filter = JSON.stringify({
        group_id: selectedGroupId,
      });
    }
    if (guestSearch) {
      query.guests_name = guestSearch;
    }
    if (groupSearch) {
      query.group_name = groupSearch;
    }

    if (emailSearch) {
      query.guests_email = emailSearch;
    }

    if (phoneSearch) {
      query.guests_phone = phoneSearch;
    }

    function callback(data) {
      setRoomsList(data.rows);
      setCurrentPage(data.currentPage);
      setTotal(data.count);
      setLimit(data.limit);

      setLoading(false);

      if (data.rows.length === 0 && currentPage !== 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }
    }

    function errorCallback() {
      setLoading(false);
    }

    REQUESTS.ROOMS.GET(query, callback, errorCallback);
  };

  const getPackagesOptions = () => {
    REQUESTS.PACKAGES.GET({}, (data) => {
      const packages = data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setPackagesOption(packages);
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    setSearch(filters);
    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  const openSingleRoomPage = (record) => {
    const roomId = record.id;
    navigate(NAVIGATORS.LOCATION, { state: { roomId: roomId } });
  };

  const rowColor = (record) => {
    let arrival = record.arival_date;
    let departure = record.planned_departure_date;

    if (arrival && departure) {
      let today = new Date().getTime();

      arrival = new Date(arrival).getTime();

      departure = new Date(departure).getTime();

      if (today > departure) {
        return "expired-row";
      } else if (departure - today < 86400000) {
        return "last-day-row";
      }
    }
  };

  return (
    <Page>
      <div className="page-style">
        <RoomsHeader
          total={total}
          roomsList={roomsList}
          setIsVisibleAddNewRoomModal={setIsVisibleAddNewRoomModal}
          openImportFromCsvDrawer={() => setIsVisibleImportFromCsvDrawer(true)}
        />

        <div className="page-body">
          <FiltersSection
            selectedGroupId={selectedGroupId}
            onClickFilter={() => setVisibleFilterDrawer(true)}
            onClickViewSettings={() => setIsVisibleViewSettingsDrawer(true)}
          />

          <Table
            rowKey="id"
            className="activeRow"
            loading={loading}
            rowClassName={rowColor}
            columns={columns}
            dataSource={roomsList}
            onChange={handleTableChange}
            scroll={{ x: 2000 }}
            pagination={{
              position: ["topCenter", "bottomCenter"],
              current: currentPage,
              total: total,
              showSizeChanger: true,
            }}
            expandable={{
              expandedRowRender: (record) => (
                <SubTable roomId={record.id} packagesOption={packagesOption} />
              ),
              rowExpandable: (record) => record.name !== "Not Expandable",
            }}
            sticky={{
              offsetHeader: 50,
              offsetScroll: 0,
            }}
            onRow={(record, rowIndex) => {
              return {
                className: "activeItem",
                onClick: (event) => {
                  openSingleRoomPage(record);
                },
              };
            }}
          />
        </div>

        <NewRoomDrawer
          newroom={currentNewRoom}
          getRoomsList={getRoomsList}
          visible={isVisibleAddNewRoomModal}
          onClose={() => setIsVisibleAddNewRoomModal(false)}
        />

        <RoomDrawer
          visible={currentRoom ? true : false}
          onClose={() => setCurrentRoom(null)}
          room={currentRoom}
          getRoomsList={getRoomsList}
          formApplication={formApplication}
        />

        <FilterDrawer
          visible={visibleFilterDrawer}
          onClose={() => setVisibleFilterDrawer(false)}
          setSelectedGroupId={setSelectedGroupId}
        />

        <ViewSettingsDrawer
          visible={isVisibleViewSettingsDrawer}
          viewSettingsValues={viewSettingsValues}
          onChangeSettingsValues={onChangeSettingsValues}
          onClose={() => setIsVisibleViewSettingsDrawer(false)}
        />
        <AddOrdersDrawer
          current={currentRoomId}
          visible={visibleOrdersDrawer}
          onClose={() => setVisibleOrdersDrawer(false)}
        />

        <ImportFromCsvDrawer
          getData={getRoomsList}
          visible={isVisibleImportFromCsvDrawer}
          onClose={() => setIsVisibleImportFromCsvDrawer(false)}
        />
      </div>
    </Page>
  );
}
