import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Drawer, Form, Input, Select } from "antd";

import REQUESTS from "../../../server/requests";

import ICONS from "../../../config/icons";
import EN from "../../../config/en";

import ErrorMessage from "../../elements/ErrorMessage";
import SaveButtonComponent from "../../elements/SaveButtonComponent";
import FileChooser from "../../elements/FileChooser";

import { useLanguagesOptions } from "../../../hooks/options";
//
export default function AddScreenDrawer({ visible, onClose, current, getAppList }) {
    const translate = useSelector((state) => state.globalState.translate);

    const [loading, setLoading] = useState(false);

    const [errorText, setErrorText] = useState(null);

    const [imageSrc, setImageSrc] = useState(null);

    const [form] = Form.useForm();

    const languagesOption = useLanguagesOptions();

    useEffect(() => {
        if (visible) {
            if (current) {
                form.setFields([
                    { name: "language_id", value: current.language_id },
                    { name: "screen", value: current.screen },
                ]);

                if (current.screen) {
                    setImageSrc(current.screen);
                }
            }
        } else {
            setErrorText(null);
        }
    }, [visible]);

    useEffect(() => {
        if (!visible && current) {
            form.resetFields();
            setImageSrc(null);
        }
    }, [visible]);

    const onFinish = (values) => {
        setLoading(true);

        setErrorText(null);

        const body = {
            language_id: values.language_id,
            screen: values.screen,
        };

        function callback() {
            setLoading(false);
            onClose();
            getAppList();
            form.resetFields();
            setImageSrc(null);
        }

        function errorCallback(err) {
            setErrorText(err);
            setLoading(false);
        }

        if (current) {
            body.id = current.id;
            REQUESTS.SCREEN_MIRRORING.EDIT(body, callback, errorCallback);
        } else {
            REQUESTS.SCREEN_MIRRORING.ADD(body, callback, errorCallback);
        }
    };

    const onFinishFailed = () => {
        setLoading(false);
        setErrorText(null);
    };

    const selectFile = (item) => {
        setImageSrc(item.file_url);
        form.setFields([{ name: "screen", value: item.file_url }]);
    };

    const repliceImage = () => {
        setImageSrc(null);
        form.setFields([{ name: "screen", value: null }]);
    };
    return (
        <Drawer
            title={
                current
                    ? translate["Edit Screen Mirroring"] || EN["Edit Screen Mirroring"]
                    : translate["Add New Screen Mirroring"] || EN["Add New Screen Mirroring"]
            }
            placement="right"
            width={600}
            visible={visible}
            onClose={onClose}
           
        >
            <Form
                form={form}
                layout="vertical"
                name="gui-form"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={{
                    is_enabled: false,
                }}
            >
                <Form.Item
                    label={translate["Screen"] || EN["Screen"]}
                    tooltip={{
                        title: "Choose an image",
                        icon: ICONS.INFO,
                    }}
                    name="screen"
                    rules={[
                        {
                            required: true,
                            message: `${translate["Please choose"] || EN["Please choose"]} ${
                                translate["Screen"] || EN["Screen"]
                            }`,
                        },
                    ]}
                >
                    <FileChooser
                        src={imageSrc}
                        onChange={selectFile}
                        replace={repliceImage}
                        types={["image"]}
                    />
                </Form.Item>

                <Form.Item
                    name="language_id"
                    label={translate[["Language"]] || EN[["Language"]]}
                    tooltip={{
                        title: "Choose a language",
                        icon: ICONS.INFO,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `${translate["Please select"] || EN["Please select"]} ${
                                translate["Language"] || EN["Language"]
                            }`,
                        },
                    ]}
                >
                    <Select
                        showSearch
                        options={languagesOption}
                        optionFilterProp="label"
                        filterOption={(input, option) =>
                            option.label.toLowerCase().includes(input.toLowerCase())
                        }
                    />
                </Form.Item>

                <Form.Item>
                    <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
                </Form.Item>
            </Form>
            <ErrorMessage>{errorText}</ErrorMessage>
        </Drawer>
    );
}
