import React, { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Drawer } from "antd";
import REQUESTS from "../../../../server/requests";

import Context from "./Context";

import ICONS from "../../../../config/icons";
import EN from "../../../../config/en";

import GalleryComponent from "../../../elements/GalleryComponent";

import NewGalleryDrawer from "./NewGalleryDrawer";

export default function GalleriesDrawer() {
  const {
    visibleGalleriesDrawer,
    setVisibleGalleriesDrawer,
    currentGallery,
    getInfoList,
  } = useContext(Context);

  const translate = useSelector((state) => state.globalState.translate);

  const [list, setList] = useState([]);

  const [visibleNewGalleryDrawer, setVisibleNewGalleryDrawer] = useState(false);

  useEffect(() => {
    if (visibleGalleriesDrawer) {
      getList();
    } else {
      setList([]);
    }
  }, [visibleGalleriesDrawer]);

  const getList = () => {
    REQUESTS.INFO_CENTER.GALLERY.GET({ id: currentGallery.id }, (data) => {
      setList(data);
    });
  };

  const deleteGallery = (item) => {
    REQUESTS.INFO_CENTER.GALLERY.DELETE(item.id, () => {
      getList();
    });
  };

  return (
    <Drawer
      title={translate["Galleries"] || EN["Galleries"]}
      placement="right"
      width={710}
      onClose={() => setVisibleGalleriesDrawer(false)}
      visible={visibleGalleriesDrawer}
    >
      <div className="list-section">
        <div className="add-component" onClick={() => setVisibleNewGalleryDrawer(true)}>
          {ICONS.PLUS}
        </div>

        {list.map((item) => {
          return (
            <GalleryComponent key={item.id} item={item} deleteGallery={deleteGallery} />
          );
        })}
      </div>

      <NewGalleryDrawer
        id={currentGallery ? currentGallery.id : null}
        visible={visibleNewGalleryDrawer}
        onClose={() => setVisibleNewGalleryDrawer(false)}
        getList={getList}
        getInfoList={getInfoList}
      />
    </Drawer>
  );
}
