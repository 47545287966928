import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table, Input } from "antd";

import REQUESTS from "../../../../server/requests";

import EN from "../../../../config/en";
import ICONS from "../../../../config/icons";
import confirmModal from "../../../../config/confirmModal";
import { dateFormat } from "../../../../config/formats";

import TableButtons from "../../../elements/TableButtons";
import Page from "../../../elements/Page";

import TableHead from "./TableHead";
import Head from "./Head";
import CompanyDrawer from "./CompanyDrawer";
import LOCAL_STORAGE_VALUES from "../../../../config/localStorageValues";
import ImageComponent from "../../../elements/ImageComponent";

export default function OrganizationCompaniesPage() {
  const { translate, profile } = useSelector((state) => state.globalState);

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [sort, setSort] = useState(["id", "DESC"]);
  const [nameSearch, setNameSearch] = useState(null);

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [columns, setColumns] = useState([]);

  const [currentCompany, setCurrentCompany] = useState(null);

  const [visibleCompanyDrawer, setVisibleCompanyDrawer] = useState(false);

  const [values, setValues] = useState({
    name: true,
    email: true,
    phone: true,
    region: true,
    city: true,
    website: true,
    date: true,
  });

  const configureTableColumns = () => {
    let newArr = [];

    newArr.push({
      width: 60,
      fixed: "left",
      align: "center",
      title: "#",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => (currentPage - 1) * limit + 1 + index,
    });

    if (values.name) {
      newArr.push({
        title: translate["Name"] || EN["Name"],
        dataIndex: "name",
        key: "name",
        sorter: true,
        render: (text, record, index) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <ImageComponent src={record.icon} className="tableImage" />

            <p className="text-ellipsis">{text}</p>
          </div>
        ),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <>{ICONS.SEARCHOUTLINED}</>;
        },

        onFilter: (value, record) => {
          return record.name.toLowerCase().includes(value.toLowerCase());
        },
      });
    }

    if (values.email) {
      newArr.push({
        title: translate["Email"] || EN["Email"],
        dataIndex: "email_address",
        key: "email_address",
      });
    }

    if (values.phone) {
      newArr.push({
        title: translate["Phone"] || EN["Phone"],
        dataIndex: "phone_number",
        key: "phone_number",
      });
    }

    if (values.region) {
      newArr.push({
        title: translate["Region"] || EN["Region"],
        dataIndex: "region",
        key: "region",
      });
    }

    if (values.city) {
      newArr.push({
        title: translate["City"] || EN["City"],
        dataIndex: "city",
        key: "city",
      });
    }

    if (values.website) {
      newArr.push({
        title: translate["Website"] || EN["Website"],
        dataIndex: "website",
        key: "website",
      });
    }

    if (values.date) {
      newArr.push({
        title: translate["Date"] || EN["Date"],
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: true,
        render: (text, record, index) => dateFormat(text),
      });
    }

    newArr.push({
      width: 60,
      fixed: "right",
      title: "",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          style={{ minWidth: 130 }}
          buttons={[
            { key: "edit", text: translate["Edit"] || EN["Edit"], icon: ICONS.EDIT },
            {
              key: "delete",
              text: translate["Delete"] || EN["Delete"],
              icon: ICONS.DELETE,
            },
          ]}
        />
      ),
    });

    setColumns(newArr);
  };

  useEffect(() => {
    setTimeout(() => {
      configureTableColumns();
    }, 200);
  }, [values]);

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "edit":
        setCurrentCompany(item);
        setVisibleCompanyDrawer(true);
        break;

      case "delete":
        confirmModal({
          title: `${translate["Do you want to delete"] || EN["Do you want to delete"]} ${
            item.name
          }`,
          action: () => onDelete(item),
        });
        break;

      default:
        break;
    }
  };

  const onDelete = (item) => {
    REQUESTS.ORGANIZATIONS.DELETE(item.id, getOrganizations);
  };

  const getOrganizations = () => {
    if (profile.is_super_admin) {
      setLoading(true);

      const query = {
        sort,
        limit,
        pagination: 1,
        page: currentPage,
      };

      if (nameSearch) {
        query.search = JSON.stringify({ name: nameSearch });
      }

      function callback(data) {
        setTableData(data.rows);
        setLimit(data.limit);
        setTotal(data.count);
        setLoading(false);

        if (data.rows.length === 0 && currentPage !== 1) {
          setCurrentPage((current) => current - 1);
        } else {
          setCurrentPage(data.currentPage);
        }
      }

      function errorCallback() {
        setLoading(false);
      }

      REQUESTS.ORGANIZATIONS.GET(query, callback, errorCallback);
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }

    if (Object.values(filters)[0]) {
      setNameSearch(Object.values(filters)[0].join(""));
    } else {
      setNameSearch(null);
    }

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  useEffect(() => {
    getOrganizations();
  }, [currentPage, limit, sort]);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      getOrganizations();
    }, 1000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [nameSearch]);

  useEffect(() => {
    let VIEW_SETTINGS_VALUES = localStorage.getItem(
      LOCAL_STORAGE_VALUES.ORGANIZATIONS_COMPANIES_COLUMNS
    );

    if (VIEW_SETTINGS_VALUES) {
      VIEW_SETTINGS_VALUES = JSON.parse(VIEW_SETTINGS_VALUES);

      setValues(VIEW_SETTINGS_VALUES);
    }

    getOrganizations();
  }, []);

  return (
    <Page>
      <Head openDrawer={() => setVisibleCompanyDrawer(true)} />

      <div className="page-body">
        <TableHead values={values} setValues={setValues} />

        <Table
          loading={loading}
          rowKey="id"
          columns={columns}
          dataSource={tableData}
          onChange={handleTableChange}
          pagination={{
            position: ["topCenter", "bottomCenter"],
            current: currentPage,
            total: total,
            showSizeChanger: true,
          }}
          scroll={{ x: 1500 }}
          sticky={{
            offsetHeader: 50,
            offsetScroll: 0,
          }}
        />

        <CompanyDrawer
          visible={visibleCompanyDrawer}
          current={currentCompany}
          getData={getOrganizations}
          onClose={() => {
            setCurrentCompany(null);
            setVisibleCompanyDrawer(false);
          }}
        />
      </div>
    </Page>
  );
}
