import React, { useState, useEffect } from "react";
import { Drawer, Form, Checkbox, Input } from "antd";
import { useSelector } from "react-redux";
import REQUESTS from "../../../server/requests";

import ErrorMessage from "../../elements/ErrorMessage";
import SaveButtonComponent from "../../elements/SaveButtonComponent";

import EN from "../../../config/en";
import ICONS from "../../../config/icons";
import Translation from "../../elements/Translation";

export default function WelcomeMessageDrawer({ visible, onClose, config, getConfig }) {
  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);

  const [errorText, setErrorText] = useState(null);

  const [form] = Form.useForm();

  const [descriptionTranslations, setDescriptionTranslations] = useState([]);

  useEffect(() => {
    if (visible) {
      if (config.items) {
        form.setFields([
          {
            name: "show_welcome_message",
            value: config.items.show_welcome_message,
          },

          {
            name: "welcome_message_once",
            value: config.items.welcome_message_once,
          },
        ]);
      }

      console.log(config.items.welcome_message_translations);
      setDescriptionTranslations(config.items.welcome_message_translations || []);
    } else {
      form.resetFields();
      setErrorText(null);
    }
  }, [visible]);

  const onFinish = (values) => {
    setLoading(true);

    setErrorText(null);

    const body = {
      welcome_message: values.welcome_message,
      show_welcome_message: values.show_welcome_message,
      welcome_message_once: values.welcome_message_once,
      welcome_message: JSON.stringify(descriptionTranslations || []),
    };

    function callback() {
      setLoading(false);
      onClose();
      getConfig();
    }

    function errorCallback(err) {
      setErrorText(err);
      setLoading(false);
    }

    if (config.items) {
      REQUESTS.TV_APPLICATION.CONFIG.EDIT(body, callback, errorCallback);
    }
  };

  const onFinishFailed = () => {
    setLoading(false);
    setErrorText(null);
  };

  const onChangeDesctiption = (text, id) => {
    const description = {
      text,
      language_id: id,
    };

    const newTranslations = [...descriptionTranslations];

    const i = newTranslations.findIndex((i) => i.language_id === id);

    if (i >= 0) {
      newTranslations[i] = description;
    } else {
      newTranslations.push(description);
    }

    setDescriptionTranslations(newTranslations);
  };

  return (
    <Drawer
      title={translate["Edit"] || EN["Edit"]}
      placement="right"
      onClose={onClose}
      visible={visible}
    >
      <Form
        form={form}
        layout="vertical"
        name="edit-text-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item name="show_welcome_message" valuePropName="checked" noStyle>
          <Checkbox>
            {translate["Show welcome message"] || EN["Show welcome message"]}
          </Checkbox>
        </Form.Item>

        <Form.Item name="welcome_message_once" valuePropName="checked">
          <Checkbox>
            {translate["Show welcome message once"] || EN["Show welcome message once"]}
          </Checkbox>
        </Form.Item>

        {visible && (
          <Translation
            render={(item, index) => {
              const description = descriptionTranslations?.find(
                (translation) => translation.language_id === item.id
              )?.text;
              return (
                <div>
                  <Form.Item
                    label={translate["Description"] || EN["Description"]}
                    tooltip={{
                      title: "Use {{guest_name}} variable to display guest name on tv",
                      icon: ICONS.INFO,
                    }}
                    rules={[
                      {
                        required: false,
                        message: `${translate["Please input"] || EN["Please input"]} ${
                          translate["Description"] || EN["Description"]
                        }`,
                      },
                    ]}
                    noStyle
                  >
                    <Input.TextArea
                      rows={5}
                      onChange={(e) => onChangeDesctiption(e.target.value, item.id)}
                      value={description}
                    />
                  </Form.Item>
                </div>
              );
            }}
          />
        )}

        <Form.Item style={{ marginTop: 20 }}>
          <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
        </Form.Item>

        <ErrorMessage>{errorText}</ErrorMessage>
      </Form>
    </Drawer>
  );
}
