import { useContext } from "react";
import { useSelector } from "react-redux";
import { Drawer } from "antd";

import Context from "./Context";
import Checkbox from "antd/lib/checkbox/Checkbox";

import EN from "../../../../config/en";
import LOCAL_STORAGE_VALUES from "../../../../config/localStorageValues";

export default function ViewSettingsDrawer() {

    const {
        visibleViewSettingsDrawer,
        setVisibleViewSettingsDrawer,
        viewSettingsValues,
        setViewSettingsValues,
    } = useContext(Context);


    const translate = useSelector((state) => state.globalState.translate);

    const onChangeSettingsValues = (event) => {
        viewSettingsValues[event.target.name] = event.target.checked;
        setViewSettingsValues({ ...viewSettingsValues });

        localStorage.setItem(
            LOCAL_STORAGE_VALUES.ORGANIZATIONS_CATEGORIES_COLUMNS,
            JSON.stringify(viewSettingsValues)
        );
    };

    return (
        <Drawer
            title={translate['View settings'] || EN['View settings']}
            placement="right"
            onClose={() => setVisibleViewSettingsDrawer(false)}
            visible={visibleViewSettingsDrawer}
        >
            <h2>{translate['Show columns'] || EN['Show columns']}</h2>

            <div className="view-settings-checkboxes">
                <Checkbox
                    name="name"
                    checked={viewSettingsValues.name}
                    onChange={onChangeSettingsValues}
                >
                    {translate['Name'] || EN['Name']}
                </Checkbox>

                <Checkbox
                    name="room_count"
                    checked={viewSettingsValues.room_count}
                    onChange={onChangeSettingsValues}
                >
                    {translate['Location Counter'] || EN['Location Counter']}
                </Checkbox>

                <Checkbox
                    name="devices_count"
                    checked={viewSettingsValues.devices_count}
                    onChange={onChangeSettingsValues}
                >
                    {translate['Devices Counter'] || EN['Devices Counter']}
                </Checkbox>

                <Checkbox
                    name="package"
                    checked={viewSettingsValues.package}
                    onChange={onChangeSettingsValues}
                >
                    {translate['Package'] || EN['Package']}
                </Checkbox>

                <Checkbox
                    name="createdAt"
                    checked={viewSettingsValues.createdAt}
                    onChange={onChangeSettingsValues}
                >
                    {translate['Date'] || EN['Date']} 
                </Checkbox>
            </div>
        </Drawer>
    );
}
