import React from "react";
import ICONS from "../../../config/icons";
import { messageDate } from "../../../config/formats";
import OrderMessage from "./OrderMessage";

export default React.memo(function Message({ item, profile }) {
    return (
        <div className="message-style">
            <div>
                {item.sender === "admin" ? (
                    <img src={profile.image} className="admin-image" />
                ) : (
                    <div className="admin-image-round">{item.sender_name.substring(0, 1)} </div>
                )}
            </div>

            <div className="info-section">
                <p style={{ color: "#2f6bff" }}>
                    {item.sender === "admin"
                        ? `${profile.name} ${profile.surname}`
                        : item.sender_name}
                    <span className="date-style">{messageDate(item.createdAt)}</span>
                </p>
                {item.message_type === "messages" ? (
                    <p style={{ color: "#a1a7b8" }}>{item.message}</p>
                ) : (
                    <OrderMessage item={item} />
                )}
                {item.sender === "admin" && (
                    <div className="seen">{item.reciver_seen ? ICONS.DONE_ALL : ICONS.DONE}</div>
                )}
            </div>
        </div>
    );
});
