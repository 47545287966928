import { useEffect, useState } from "react";
import { notification, Table, Tag } from "antd";
import Page from "../../../../elements/Page";
import HeaderItem from "../../../../elements/HeaderItem";
import TableHead from "./TableHead";
import AddButtonComponent from "../../../../elements/AddButtonComponent";
import REQUESTS from "../../../../../server/requests";
import RoomPageDrawer from "./drawer/RoomPageDrawer";
import TableButtons from "../../../../elements/TableButtons";
import ICONS from "../../../../../config/icons";

const RoomPage = () => {
  const [dataSource, setDataSource] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);

  const [showDrawer, setShowDrawer] = useState(false);
  const [editable, setEditable] = useState(null);
  const [facilitiesOptions, setFacilitiesOptions] = useState([]);
  const [propertiesOptions, setPropertiesOptions] = useState([]);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      align: "center",
      width: 50,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center",
    },
    {
      title: "Adults",
      dataIndex: "adults",
      key: "adults",
      align: "center",
    },
    {
      title: "Children",
      dataIndex: "chld",
      key: "chld",
      align: "center",
    },
    {
      title: "Twin Beds",
      dataIndex: "twin_beds",
      key: "twin_beds",
      align: "center",
    },
    {
      title: "Sofa Bed",
      dataIndex: "sofa_bed",
      key: "sofa_bed",
      align: "center",
    },
    {
      title: "Queen Bed",
      dataIndex: "queen_bed",
      key: "queen_bed",
      align: "center",
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      align: "center",
    },
    {
      title: "Properties",
      dataIndex: "properties",
      key: "properties",
      align: "center",

      render: (properties) => {
        return (
          <>
            {properties?.map((item) => {
              return <Tag>{item.title ? item?.title : "N/A"}</Tag>;
            })}
          </>
        );
      },
    },
    {
      title: "Facilities",
      dataIndex: "facilities",
      key: "facilities",
      align: "center",

      render: (facilities) => {
        return (
          <>
            {facilities?.map((item) => {
              return <Tag>{item.title ? item?.title : "N/A"}</Tag>;
            })}
          </>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      fixed: "right",
      width: 100,
      render: (text, record) => {
        return (
          <TableButtons
            handleMenuClick={(e) => handleMenuClick(e.key, record)}
            buttons={[
              { key: "edit", text: "Edit", icon: ICONS.EDIT },
              {
                key: "delete",
                text: "Delete",
                icon: ICONS.DELETE,
              },
            ]}
          />
        );
      },
    },
  ];

  const handleMenuClick = (key, record) => {
    if (key === "edit") {
      setEditable(record);
      setShowDrawer(true);
    } else if (key === "delete") {
      console.log("delete", record);
    }
  };

  const handleClickDrawer = () => {
    setShowDrawer(true);
  };

  const handleTableChange = (data) => {
    setCurrentPage(data.current);
    setLimit(data.pageSize);
  };

  const handleSave = (values) => {
    try {
      if (editable) {
        const successCallBack = (data) => {
          getDataSource();
          notification.success({
            message: "Room updated successfully",
          });
        };

        const errorCallBack = (error) => {
          notification.error({
            message: "An error occurred",
          });
          console.log(error);
        };

        REQUESTS.BOOKING.ROOM.EDIT(values, editable.id, successCallBack, errorCallBack);
      } else {
        const successCallBack = (data) => {
          getDataSource();
          notification.success({
            message: "Room added successfully",
          });
        };

        const errorCallBack = (error) => {
          notification.error({
            message: "An error occurred",
          });
          console.log(error);
        };

        REQUESTS.BOOKING.ROOM.ADD(values, successCallBack, errorCallBack);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDataSource = () => {
    try {
      const query = {
        limit,
        page: currentPage,
        total,
      };
      const successCallBack = (response) => {
        const data = response?.rows.map((item) => {
          return {
            name: item.name,
            description: item.description,
            id: item.id,
            adults: item.adults,
            facilities: item.facilities,
            queen_bed: item.queen_bed,
            properties: item.properties,
            size: item.size,
            sofa_bed: item.sofa_bed,
            twin_beds: item.twin_beds,
            properties: item.properties,
            chld: item.children,
          };
        });
        setDataSource(data);
        setTotal(response?.count);
        setCurrentPage(response?.page);
        setLimit(response?.limit);
      };

      const errorCallBack = (error) => {
        console.error("Error occurred while fetching data:", error);
        notification.error({
          message: "An error occurred while fetching rooms",
        });
      };

      REQUESTS.BOOKING.ROOM.GET(query, successCallBack, errorCallBack);
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  };

  useEffect(() => {
    REQUESTS.BOOKING.FACILITY.GET(
      {},
      (data) => {
        const facilities = data?.rows?.map((item) => {
          return {
            value: item.id,
            label: item.title,
          };
        });
        setFacilitiesOptions(facilities);
      },
      (error) => {
        console.log(error);
      }
    );

    REQUESTS.BOOKING.PROPERTY.GET(
      {},
      (data) => {
        const properties = data?.rows?.map((item) => {
          return {
            value: item.id,
            label: item.title,
          };
        });
        setPropertiesOptions(properties);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    getDataSource();
  }, [limit, currentPage]);

  return (
    <>
      <Page>
        <div className="page-body">
          <h1>Rooms</h1>
          <HeaderItem title={"Roms"} total={dataSource?.length} />
          <div
            style={{
              marginBottom: 30,
            }}
          >
            <TableHead>
              <AddButtonComponent text={"Add room"} onClick={handleClickDrawer} />
            </TableHead>
          </div>
          <Table
            dataSource={dataSource || []}
            columns={columns}
            onChange={handleTableChange}
            scroll={{ x: 1300 }}
            pagination={{
              position: ["bottomCenter"],
              current: currentPage,
              pageSize: limit,
              total,
              showSizeChanger: true,
            }}
          />
        </div>
        <RoomPageDrawer
          open={showDrawer}
          close={() => setShowDrawer(false)}
          handleSave={handleSave}
          editable={editable}
          facilitiesOptions={facilitiesOptions}
          propertiesOptions={propertiesOptions}
        />
      </Page>
    </>
  );
};

export default RoomPage;
