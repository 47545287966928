import { useEffect } from "react";
import { Form, Input, Select, Drawer, Button } from "antd";

const RoomPageDrawer = ({
  open,
  close,
  handleSave,
  editable,
  facilitiesOptions,
  propertiesOptions,
}) => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    handleSave(values);
  };

  useEffect(() => {
    form.setFieldsValue({
      name: editable?.name,
      adults: editable?.adults,
      children: editable?.chld,
      twin_beds: editable?.twin_beds,
      sofa_bed: editable?.sofa_bed,
      queen_bed: editable?.queen_bed,
      size: editable?.size,
      properties: editable?.properties,
      facilities: editable?.facilities,
      description: editable?.description,
    });
  }, [editable]);

  return (
    <Drawer
      title="Room Details"
      placement="right"
      open={open}
      onClose={() => {
        close();
      }}
      width={600}
      footer={
        <div style={{ textAlign: "right" }}>
          <Button onClick={close} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button type="primary" onClick={() => form?.submit()}>
            Submit
          </Button>
        </div>
      }
    >
      <Form onFinish={onFinish} layout="vertical" form={form}>
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Adults" name="adults" rules={[{ required: true }]}>
          <Input type="number" />
        </Form.Item>

        <Form.Item label="Children" name="children" rules={[{ required: true }]}>
          <Input type="number" />
        </Form.Item>

        <Form.Item label="Twin Beds" name="twin_beds" rules={[{ required: true }]}>
          <Input type="number" />
        </Form.Item>

        <Form.Item label="Sofa Bed" name="sofa_bed" rules={[{ required: true }]}>
          <Input type="number" />
        </Form.Item>

        <Form.Item label="Queen Bed" name="queen_bed" rules={[{ required: true }]}>
          <Input type="number" />
        </Form.Item>

        <Form.Item label="Size" name="size" rules={[{ required: true }]}>
          <Input type="number" />
        </Form.Item>

        <Form.Item label="Properties" name="properties" rules={[{ required: true }]}>
          <Select mode="multiple" options={propertiesOptions} />
        </Form.Item>

        <Form.Item label="Facilities" name="facilities" rules={[{ required: true }]}>
          <Select mode="multiple" options={facilitiesOptions} />
        </Form.Item>
        <Form.Item label="Description" name="description" rules={[{ required: true }]}>
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default RoomPageDrawer;
