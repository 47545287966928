import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Drawer, Form, Input, Select } from "antd";

import { bindActionCreators } from "redux";

import { actions } from "../../../store";

import REQUESTS from "../../../server/requests";

import ICONS from "../../../config/icons";
import EN from "../../../config/en";

import ErrorMessage from "../../elements/ErrorMessage";
import SaveButtonComponent from "../../elements/SaveButtonComponent";
import FileChooser from "../../elements/FileChooser";

const OrganizationInfoDrawer = ({ visible, onClose }) => {
    const dispatch = useDispatch();
    const { setOrganizationInfoInRedux } = bindActionCreators(actions, dispatch);
    const translate = useSelector((state) => state.globalState.translate);

    const [loading, setLoading] = useState(false);

    const [errorText, setErrorText] = useState(null);

    const [info, setInfo] = useState(null);

    const [imageSrc, setImageSrc] = useState(null);

    const [currenciesOptions, setCurrenciesOptions] = useState([]);

    useEffect(() => {
        if (info && info.icon) {
            setImageSrc(info.icon);
        }
    }, [info]);

    useEffect(() => {
        if (visible) {
            getOrganizationInfo();
            getCurrencies();
        } else {
            setErrorText(null);
        }
    }, [visible]);

    const [form] = Form.useForm();

    const onFinish = (values) => {
        setLoading(true);

        setErrorText(null);
        const body = {
            id: info.id,
            name: values.name,
            email_address: values.email_address,
            phone_number: values.phone_number,
            website: values.website,
            region: values.region,
            city: values.city,
            currency_id: values.currency_id,
            icon: values.icon,
        };

        function callback(data) {
            REQUESTS.ORGANIZATIONS.INFO((data) => {
                setOrganizationInfoInRedux(data[0]);
            });

            setLoading(false);
            onClose();
            getOrganizationInfo();
        }

        function errorCallback(err) {
            setLoading(false);
            setErrorText(err);
        }

        REQUESTS.ORGANIZATIONS.EDIT(body, callback, errorCallback);
    };

    const onFinishFailed = () => {
        setErrorText(null);
    };

    const getOrganizationInfo = () => {
        REQUESTS.ORGANIZATIONS.INFO((data) => {
            if (data[0]) {
                setInfo(data[0]);

                const {
                    name,
                    phone_number,
                    email_address,
                    website,
                    region,
                    city,
                    currency_id,
                    icon,
                } = data[0];

                form.setFields([
                    { name: "name", value: name },
                    { name: "email_address", value: email_address },
                    { name: "phone_number", value: phone_number },
                    { name: "website", value: website },
                    { name: "region", value: region },
                    { name: "city", value: city },
                    { name: "currency_id", value: currency_id },
                    { name: "icon", value: icon },
                ]);
            }
        });
    };

    const getCurrencies = () => {
        REQUESTS.CURRENCY.GET((data) => {
            const currencies = data.map((item) => ({
                label: `${item.name} ${
                    item.symbolNative && item.symbolNative !== "" && `( ${item.symbolNative} )`
                }`,
                value: item.id,
            }));

            setCurrenciesOptions(currencies);
        });
    };

    const selectFile = (item) => {
        setImageSrc(item.file_url);
        form.setFields([{ name: "icon", value: item.file_url }]);
    };

    const repliceImage = () => {
        setImageSrc(null);
        form.setFields([{ name: "icon", value: null }]);
    };

    return (
        <Drawer
            title={
                translate["Organization Information Settings"] ||
                EN["Organization Information Settings"]
            }
            placement="right"
            width={600}
            onClose={onClose}
            visible={visible}
           
        >
            <h2>{translate["Organization Information"] || EN["Organization Information"]}</h2>

            <Form
                form={form}
                layout="vertical"
                name="service-form"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label={translate["Image"] || EN["Image"]}
                    tooltip={{
                        title: "Choose an image",
                        icon: ICONS.INFO,
                    }}
                    name="icon"
                >
                    <FileChooser
                        src={imageSrc}
                        onChange={selectFile}
                        replace={repliceImage}
                        types={["image"]}
                    />
                </Form.Item>

                <Form.Item
                    label={translate["Name"] || EN["Name"]}
                    tooltip={{
                        title: "Organization Info name",
                        icon: ICONS.INFO,
                    }}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: `${translate["Please input"] || EN["Please input"]} ${
                                translate["Name"] || EN["Name"]
                            }`,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={translate["Email address"] || EN["Email address"]}
                    tooltip={{
                        title: "Email Address",
                        icon: ICONS.INFO,
                    }}
                    name="email_address"
                    rules={[
                        {
                            required: true,
                            message: `${translate["Please input"] || EN["Please input"]} ${
                                translate["Email"] || EN["Email"]
                            }`,
                        },
                    ]}
                >
                    <Input type="email" />
                </Form.Item>

                <Form.Item
                    label={translate["Phone number"] || EN["Phone number"]}
                    tooltip={{
                        title: "Phone Number",
                        icon: ICONS.INFO,
                    }}
                    name="phone_number"
                    rules={[
                        {
                            required: true,
                            message: `${translate["Please input"] || EN["Please input"]} ${
                                translate["Phone number"] || EN["Phone number"]
                            }`,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={translate["Website"] || EN["Website"]}
                    tooltip={{
                        title: "Website",
                        icon: ICONS.INFO,
                    }}
                    name="website"
                    rules={[
                        {
                            required: true,
                            message: `${translate["Please input"] || EN["Please input"]} ${
                                translate["Website"] || EN["Website"]
                            }`,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={translate["Region"] || EN["Region"]}
                    tooltip={{
                        title: "Region",
                        icon: ICONS.INFO,
                    }}
                    name="region"
                    rules={[
                        {
                            required: true,
                            message: `${translate["Please input"] || EN["Please input"]} ${
                                translate["Region"] || EN["Region"]
                            }`,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={translate["City"] || EN["City"]}
                    tooltip={{
                        title: "City",
                        icon: ICONS.INFO,
                    }}
                    name="city"
                    rules={[
                        {
                            required: true,
                            message: `${translate["Please input"] || EN["Please input"]} ${
                                translate["City"] || EN["City"]
                            } `,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={translate["Currency"] || EN["Currency"]}
                    tooltip={{
                        title: "Currency",
                        icon: ICONS.INFO,
                    }}
                    name="currency_id"
                    rules={[
                        {
                            required: true,
                            message: `${translate["Please input"] || EN["Please input"]} ${
                                translate["Currency"] || EN["Currency"]
                            }`,
                        },
                    ]}
                >
                    <Select showArrow allowClear={true} options={currenciesOptions}>
                        {/* {currenciesOptions.map((item) => {
                            return (
                                <Select.Option key={item.id}>
                                    <div style={{ display: "flex" }}>
                                        <div
                                            style={{
                                                width: 50,
                                                color: "#2f6bff",
                                            }}
                                        >
                                            {item.symbolNative}
                                        </div>

                                        {item.name}
                                    </div>
                                </Select.Option>
                            );
                        })} */}
                    </Select>
                </Form.Item>

                <Form.Item>
                    <SaveButtonComponent
                        loading={loading}
                        onClick={() => form.submit()}
                        disabled={info?.id ? false : true}
                    />
                </Form.Item>
            </Form>

            <ErrorMessage>{errorText}</ErrorMessage>
        </Drawer>
    );
};

export default OrganizationInfoDrawer;
