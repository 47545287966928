import React, { useEffect, useState } from "react";
import { Table, Button, notification, Tag } from "antd";
import Page from "../../../elements/Page";
import TableHead from "./TableHead";
import HeaderItem from "../../../elements/HeaderItem";
import REQUESTS from "../../../../server/requests";
import RadioGenresDrawer from "./drawer/RadioGenreDrawer";
import TableButtons from "../../../elements/TableButtons";
import ImageComponent from "../../../elements/ImageComponent";
import ICONS from "../../../../config/icons";
import confirm from "antd/lib/modal/confirm";
import { useSelector } from "react-redux";
import EN from "../../../../config/en";
import AddButtonComponent from "../../../elements/AddButtonComponent";
import CsvLinkComponent from "../../../elements/CsvLinkComponent";

const RadioGenres = () => {
  const translation = useSelector((state) => state.globalState.translate);

  const [dataSource, setDataSource] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [editable, setEditable] = useState(null);
  const [loading, setLoading] = useState(false);

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  let debounceAddGenre = null;

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      align: "center",
      fixed: "left",
      width: 50,
    },
    {
      title: translation["Image"] || EN["Image"],
      dataIndex: "image",
      key: "image",
      align: "center",
      render: (text, record) => {
        return <ImageComponent src={text} className="tableImage" />;
      },
    },
    {
      title: translation["Tags"] || EN["Tags"],
      dataIndex: "tags",
      key: "tags",
      align: "center",
      render: (text, record) => {
        return text?.map((tag) => (
          <Tag key={tag} color="blue">
            {tag ? tag : "N/A"}
          </Tag>
        ));
      },
    },
    {
      title: translation["Name"] || EN["Name"],
      dataIndex: "name",
      key: "name",
      align: "center",
    },

    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      fixed: "right",
      width: 50,
      render: (text, record) => {
        return (
          <TableButtons
            handleMenuClick={(key) => handleMenuClick(key, record)}
            buttons={[
              {
                key: "edit",
                text: translation["Edit"] || EN["Edit"],
                icon: ICONS.EDIT,
              },
              {
                key: "delete",
                text: translation["Edit"] || EN["Edit"],
                icon: ICONS.DELETE,
              },
            ]}
          />
        );
      },
    },
  ];

  const handleMenuClick = ({ key }, record) => {
    switch (key) {
      case "edit":
        setEditable(record);
        setShowDrawer(true);
        break;

      case "delete":
        confirm({
          title: `${
            translation["Do you want to delete"] || EN["Do you want to delete"]
          } ${record.name}?`,
          onOk() {
            handleDelete(record.id);
          },
        });
        break;

      default:
        break;
    }
  };

  const getGenres = () => {
    setLoading(true);

    const query = {
      limit,
      page: currentPage,
      total,
    };

    function successCallBack(data) {
      setLoading(false);
      setDataSource(data);
    }

    function errorCallBack(err) {
      setLoading(false);
      console.log(err);
      notification.error({
        message: translation["Error"] || EN["Error"],
      });
    }

    try {
      REQUESTS.RADIO_CATEGORIES.GET(query, successCallBack, errorCallBack);
    } catch (error) {
      console.log(error);
    }
  };

  const handleValues = (values) => {
    setLoading(true);
    clearTimeout(debounceAddGenre);

    debounceAddGenre = setTimeout(() => {
      if (editable) {
        try {
          REQUESTS.RADIO_CATEGORIES.EDIT(
            { ...values, id: editable.id },
            (res) => {
              setLoading(false);
              setShowDrawer(false);
              setEditable(null);
              getGenres();
              notification.success({
                message: translation["Success"] || EN["Success"],
              });
            },
            (err) => {
              setLoading(false);
              notification.error({
                message: translation["An error occurred"] || EN["An error occurred"],
              });
              console.log(err);
            }
          );
        } catch (error) {
          setLoading(false);

          throw new Error(error);
        }
      } else {
        try {
          REQUESTS.RADIO_CATEGORIES.ADD(
            values,
            (res) => {
              setLoading(false);

              setShowDrawer(false);
              setEditable(null);
              getGenres();
              notification.success({
                message: translation["Success"] || EN["Success"],
              });
            },
            (err) => {
              setLoading(false);
              notification.error({
                message: translation["An error occurred"] || EN["An error occurred"],
              });

              console.log(err);
            }
          );
        } catch (error) {
          setLoading(false);

          throw new Error(error);
        }
      }
    }, 1000);
  };

  const handleAddGenre = () => {
    setShowDrawer(true);
  };

  const handleDelete = (id) => {
    setLoading(true);
    try {
      REQUESTS.RADIO_CATEGORIES.DELETE(
        id,
        (data) => {
          setLoading(false);

          notification.success({
            message: translation["Success"] || EN["Success"],
          });
          getGenres();
        },
        (err) => {
          notification.error({
            message: translation["An error occurred"] || EN["An error occurred"],
          });
          console.log(err);
        }
      );
    } catch (err) {
      throw new Error(err);
    }
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
    setCurrentPage(pagination.current);
    setTotal(pagination.total);
  };

  const onClicCsv = (event, done) => {
    if (!loading) {
      try {
        done(dataSource);
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    getGenres();
  }, [currentPage, limit, total]);

  return (
    <Page>
      <div className="page-body">
        <h1>{translation["Radio Genre"] || EN["Radio Genre"]}</h1>
        <HeaderItem title={"Radio genres"} total={dataSource?.length} />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
        >
          <TableHead>
            <div
              style={{
                display: "flex",
                justifyContent: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <AddButtonComponent
                text={translation["Add Genre"] || EN["Add Genre"]}
                onClick={handleAddGenre}
              />
            </div>
            <CsvLinkComponent
              filename={"radio-genres"}
              data={dataSource}
              onClick={onClicCsv}
            />
          </TableHead>
        </div>

        <Table
          columns={columns}
          dataSource={dataSource}
          onChange={handleTableChange}
          loading={loading}
          pagination={{
            position: ["bottomCenter"],
            current: currentPage,
            pageSize: limit,
            total: total,
            limit,
            showSizeChanger: true,
          }}
        />
      </div>

      <RadioGenresDrawer
        visible={showDrawer}
        onClose={() => {
          setShowDrawer(false);
          setEditable(null);
        }}
        handleValues={handleValues}
        editable={editable}
        loading={loading}
      />
    </Page>
  );
};

export default RadioGenres;
