import {useContext} from "react";
import { useSelector } from "react-redux";
import { Drawer, Checkbox } from "antd";

import Context from "./Context";

import EN from "../../../../config/en";

export default function ViewSettingsDrawer () {
   const {
      visibleViewSettingsDrawer,
      setVisibleViewSettingsDrawer,
      viewSettingsValues,
      setViewSettingsValues,
   } = useContext(Context);

   const translate = useSelector((state) => state.globalState.translate);

   const onChangeSettingsValues = (event) => {
      viewSettingsValues[event.target.name] = event.target.checked;
      setViewSettingsValues({ ...viewSettingsValues });

      localStorage.setItem("INFO_COLUMNS", JSON.stringify(viewSettingsValues));
   } 

   return(
      <Drawer
         title={translate['View settings'] || EN['View settings']}
         placement="right"
         onClose={() => setVisibleViewSettingsDrawer(false)}
         visible={visibleViewSettingsDrawer}
      >
         <h2>{translate['Show columns'] || EN['Show columns']}</h2>

         <div className="view-settings-checkboxes">
            <Checkbox
               name="cover"
               checked={viewSettingsValues.cover}
               onChange={onChangeSettingsValues}
            >
               {translate['Image'] || EN['Image']}
            </Checkbox>

            <Checkbox
               name="title"
               checked={viewSettingsValues.title}
               onChange={onChangeSettingsValues}
            >
               {translate['Title'] || EN['Title']}
            </Checkbox>
         
            <Checkbox
               name="information_galeries"
               checked={viewSettingsValues.information_galeries}
               onChange={onChangeSettingsValues}
            >
               {translate['Galleries'] || EN['Galleries']}
            </Checkbox>
            
            <Checkbox
               name="informations_category"
               checked={viewSettingsValues.informations_category}
               onChange={onChangeSettingsValues}
            >
               {translate['Category'] || EN['Category']}
            </Checkbox>

            <Checkbox
               name="description"
               checked={viewSettingsValues.description}
               onChange={onChangeSettingsValues}
            >
               {translate['Description'] || EN['Description']}
            </Checkbox>

            <Checkbox
               name="createdAt"
               checked={viewSettingsValues.createdAt}
               onChange={onChangeSettingsValues}
            >
               {translate['Date'] || EN['Date']}
            </Checkbox>
         </div>
      </Drawer>
   );
}