import React from "react";
import { useSelector } from "react-redux";
import REQUESTS from "../../../../../server/requests";

import EN from "../../../../../config/en";
import ICONS from "../../../../../config/icons";
import confirmModal from "../../../../../config/confirmModal";

import TitleComponent from "../../../../elements/TitleComponent";
import ButtonComponent from "../../../../elements/ButtonComponent";

import { notification } from "antd";

export default function CurrentHost({ currentHost, setCheckInHost, getHostInLocations }) {
    const onClickCheckOut = (room) => {
        confirmModal({
            title: `${translate['Do you want to delete'] || EN['Do you want to delete']} ${translate['Guest'] || EN['Guest']}`,
            action: () =>
                REQUESTS.GUEST.DELETE(
                    room.guest.id,
                    (data) => {
                        getHostInLocations();
                    },
                    (err) => {
                        notification.error({ description: err });
                    }
                ),
        });
    };
    const translate = useSelector((state) => state.globalState.translate);

    return (
        <div className="bg_fff  content_box_host">
            {currentHost.map((room) => {
                return (
                    <div key={room.id}>
                        <div className="df_check_title">
                            <TitleComponent>{translate['Guest'] || EN['Guest']}</TitleComponent>
                            <div>
                                {room.guest !== null ? (
                                    <ButtonComponent
                                        text={translate['Deactivate'] || EN['Deactivate']}
                                        icon={ICONS.USER_MINUS}
                                        onClick={() => onClickCheckOut(room)}
                                    />
                                ) : (
                                    <ButtonComponent
                                        style={{ width: "100%" }}
                                        text={translate['Activate'] || EN['Activate']}
                                        icon={ICONS.USER_PLUS}
                                        onClick={() => setCheckInHost(room)}
                                    />
                                )}
                            </div>
                        </div>

                        <div className="height_list_orders">
                            <p className="childTitle mt_12">{translate['Abount host'] || EN['Abount host']}</p>
                            <div className="host_keys">
                                <div>
                                    <span>{translate['Name'] || EN['Name']}: </span>
                                    {room.guest !== null ? room.guest.name : translate['N/A'] || EN['N/A']}
                                </div>

                                <div>
                                    <span>{translate['Email'] || EN['Email']} : </span>
                                    {room.guest !== null ? room.guest.email : translate['N/A'] || EN['N/A']}
                                </div>

                                <div>
                                    <span>{translate['Phone'] || EN['Phone']}: </span>
                                    {room.guest !== null ? room.guest.phone : translate['N/A'] || EN['N/A']}
                                </div>

                                <div>
                                    <span>{translate['Price for month'] || EN['Price for month']}: </span>
                                    {room.room_price_for_month}
                                </div>

                                <div>
                                    <span>{translate['Price per day'] || EN['Price per day']}: </span>
                                    {room.room_price_per_day}
                                </div>

                                <div>
                                    <span>{translate['Reservation No'] || EN['Reservation No']}: </span>
                                    {room.guest !== null && room.guest.reservation_number
                                        ? room.guest.reservation_number
                                        : translate['N/A'] || EN['N/A']}
                                </div>

                                <div>
                                    <span>{translate['Comment'] || EN['Comment']}: </span>
                                    {room.guest !== null && room.guest.comment
                                        ? room.guest.comment
                                        : translate['N/A'] || EN['N/A']}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
