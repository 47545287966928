import React from "react";
import { useSelector } from "react-redux";
import { Drawer } from "antd";

import EN from "../../../../config/en";
import Checkbox from "antd/lib/checkbox/Checkbox";

export default function ViewSettingsDrawer({
    visible,
    onClose,
    viewSettingsValues,
    onChangeSettingsValues,
}) {
  const translate = useSelector((state) => state.globalState.translate);

    return (
        <Drawer 
            title={translate['View settings'] || EN['View settings']} 
            placement="right" 
            onClose={onClose} 
            visible={visible}
           
        >
            <h2>{translate['Show columns'] || EN['Show columns']}</h2>

            <div className="view-settings-checkboxes">
                <Checkbox
                    name="roomNumber"
                    checked={viewSettingsValues.roomNumber}
                    onChange={onChangeSettingsValues}
                >
                    {translate['Location'] || EN['Location']} No
                </Checkbox>

                <Checkbox
                    name="locationName"
                    checked={viewSettingsValues.locationName}
                    onChange={onChangeSettingsValues}
                >
                    {translate['Location name'] || EN['Location name']}
                </Checkbox>

                <Checkbox
                    name="group"
                    checked={viewSettingsValues.group}
                    onChange={onChangeSettingsValues}
                >
                    {translate['Category'] || EN['Category']}
                </Checkbox>

                <Checkbox
                    name="guestName"
                    checked={viewSettingsValues.guestName}
                    onChange={onChangeSettingsValues}
                >
                    {translate['Guest Name'] || EN['Guest Name']}
                </Checkbox>

                <Checkbox
                    name="language"
                    checked={viewSettingsValues.language}
                    onChange={onChangeSettingsValues}
                >
                    {translate['Language'] || EN['Language']}
                </Checkbox>
                
                <Checkbox
                    name="country"
                    checked={viewSettingsValues.country}
                    onChange={onChangeSettingsValues}
                >
                    {translate['Country'] || EN['Country']}
                </Checkbox>

                <Checkbox
                    name="email"
                    checked={viewSettingsValues.email}
                    onChange={onChangeSettingsValues}
                >
                    {translate['Email'] || EN['Email']}
                </Checkbox>
                
                <Checkbox
                    name="phone"
                    checked={viewSettingsValues.phone}
                    onChange={onChangeSettingsValues}
                >
                    {translate['Phone'] || EN['Phone']}
                </Checkbox>
                
                <Checkbox
                    name="reservation_number"
                    checked={viewSettingsValues.reservation_number}
                    onChange={onChangeSettingsValues}
                >
                    {translate['Reservation Number'] || EN['Reservation Number']}
                </Checkbox>

                <Checkbox
                    name="bill"
                    checked={viewSettingsValues.bill}
                    onChange={onChangeSettingsValues}
                >
                    {translate['Due'] || EN['Due']}
                </Checkbox>
                
                <Checkbox
                    name="arival_date"
                    checked={viewSettingsValues.arival_date}
                    onChange={onChangeSettingsValues}
                >
                    {translate['Arrival Date'] || EN['Arrival Date']}
                </Checkbox>

                <Checkbox
                    name="planned_departure_date"
                    checked={viewSettingsValues.planned_departure_date}
                    onChange={onChangeSettingsValues}
                >
                    {translate['Planned Departure Date'] || EN['Planned Departure Date']}
                </Checkbox>

                <Checkbox
                    name="comment"
                    checked={viewSettingsValues.comment}
                    onChange={onChangeSettingsValues}
                >
                    {translate['Comment'] || EN['Comment']} 
                </Checkbox>
                
                <Checkbox
                    name="feedbacks"
                    checked={viewSettingsValues.feedbacks}
                    onChange={onChangeSettingsValues}
                >
                    {translate['Feedback'] || EN['Feedback']}
                </Checkbox>
                
            </div>
        </Drawer>
    );
}
