import React, { useContext, useState, useEffect } from "react";

import Context from "./Context";
import REQUESTS from "../../../server/requests";

import ICONS from "../../../config/icons";

import IconButton from "../../elements/IconButton";
import TableHeadComponent from "../../elements/TableHeadComponent";
import CsvLinkComponent from "../../elements/CsvLinkComponent";

import ViewSettingsDrawer from "./ViewSettingsDrawer";
import FilterDrawer from "./FilterDrawer";

export default function TableHead() {
  const {
    setVisibleViewSettingsDrawer,
    setVisibleFilterDrawer,
    selectedPublishedStatus,
    selectedHostId,
    selectedLocationNameId,
    ordersTypeId,
  } = useContext(Context);

  const [clickedCsv, setClickedCsv] = useState(false);

  const [dataList, setDataList] = useState([]);

  const [isFilterActive, setIsFilterActive] = useState(false);

  useEffect(() => {
    if (
      ordersTypeId ||
      selectedLocationNameId ||
      selectedHostId ||
      selectedPublishedStatus != null
    ) {
      setIsFilterActive(true);

      return;
    }

    setIsFilterActive(false);
  }, [ordersTypeId, selectedHostId, selectedLocationNameId, selectedPublishedStatus]);

  const onClicCsv = (event, done) => {
    if (!clickedCsv) {
      setClickedCsv(true);

      function callback(data) {
        const newList = data.map((list) => {
          console.log(list);
          return {
            Name: list.name,
            Host_name: list.guest ? list.guest.name : "-",
            Location_name: list.room ? list.room.location_name : "-",
            Price: list.price,
            Completed: list.completed,
            Quantity: list.quantity,
          };
        });
        done(newList);
        setDataList(newList);

        setClickedCsv(false);
      }

      function errorCallback() {
        setClickedCsv(false);
      }

      REQUESTS.ORDERS.GET({}, callback, errorCallback);
    }
  };

  return (
    <>
      <TableHeadComponent>
        <CsvLinkComponent
          filename="Orders-Products-List.csv"
          data={dataList}
          onClick={onClicCsv}
        />

        <IconButton
          icon={ICONS.SETTINGS}
          onClick={() => setVisibleViewSettingsDrawer(true)}
        />

        <IconButton
          active={isFilterActive}
          icon={ICONS.FILTER}
          onClick={() => setVisibleFilterDrawer(true)}
        />
      </TableHeadComponent>

      <ViewSettingsDrawer />
      <FilterDrawer />
    </>
  );
}
