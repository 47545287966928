import { useEffect, useState } from "react";
import { Drawer, Form, Input, Button, Radio, message } from "antd";
import REQUESTS from "../../../server/requests";
import ICONS from "../../../config/icons";
import { useSelector } from "react-redux";
import EN from "../../../config/en";

const PmsDrawer = ({ visible, onClose }) => {
  const [form] = Form.useForm();

  const translate = useSelector((state) => state.globalState.translate);

  const [disabled, setDisabled] = useState(true);

  const handleChecked = (e) => {
    if (e.target.value === 1) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleCopy = () => {
    const reserving = form.getFieldValue("reserving");
    navigator.clipboard.writeText(reserving);
  };

  useEffect(() => {
    REQUESTS.PMS_OPERA.GET(
      {},
      (data) => {
        form.setFieldsValue({
          sending: data?.export_url || "",
        });
        form.setFieldsValue({
          reserving: data?.import_url,
        });
        setDisabled(data?.is_enabled);
      },
      (err) => {
        console.log(err);
      }
    );
  }, [visible]);

  const handleFinish = () => {
    const params = {
      is_enabled: disabled,
    };
    if (disabled) {
      params.export_url = form.getFieldValue("sending");
    }

    REQUESTS.PMS_OPERA.EDIT(
      params,
      (data) => {
        console.log(data);
        onClose();
      },
      (err) => {
        message.error(err.split("_").join(" "));
        console.log(err);
      }
    );
  };

  return (
    <Drawer visible={visible} onClose={onClose}>
      <h1>{"PMS"}</h1>

      <Radio.Group onChange={handleChecked} defaultValue={!disabled ? 1 : 2}>
        <Radio label="disabled" value={1}>
          {translate["Disable"] || EN["Disable"]}
        </Radio>
        <br />
        <Radio value={2}>{" Opera / fidelio"}</Radio>
      </Radio.Group>
      <Form
        layout="vertical"
        form={form}
        style={{ marginTop: 20 }}
        onFinnish={handleFinish}
      >
        <div style={{ display: "flex", alignItems: "flex-end", gap: 10 }}>
          <Form.Item label={translate["Reserving"] || EN["Reserving"]} name="reserving">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item>
            <Button
              onClick={handleCopy}
              disabled={disabled}
              style={{ border: ".5px solid #d9d9d9" }}
              icon={ICONS.COPY}
            />
          </Form.Item>
        </div>
        <Form.Item label={translate["Sending"] || EN["Sending"]} name="sending">
          <Input disabled={!disabled} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={handleFinish}>
            {translate["Save"] || EN["Save"]}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default PmsDrawer;
