import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table, Input, message, Checkbox } from "antd";

import TvChannelsContext from "./TvChannelsContext";

import REQUESTS from "../../../../../server/requests";

import ImageComponent from "../../../../elements/ImageComponent";
import TableButtons from "../../../../elements/TableButtons";
import Check from "../../../../elements/Check";
import LongText from "../../../../elements/LongText";
import Page from "../../../../elements/Page";

import { dateFormat } from "../../../../../config/formats";
import confirmModal from "../../../../../config/confirmModal";
import ICONS from "../../../../../config/icons";
import EN from "../../../../../config/en";

import AddNewChannelDrawer from "./AddNewChannelDrawer";
import ImportFileDrawer from "./ImportFileDrawer";
import PlayChannelDrawer from "./PlayChannelDrawer";
import TvChannelHeader from "./TvChannelHeader";
import TableHead from "./TableHead";
import LOCAL_STORAGE_VALUES from "../../../../../config/localStorageValues";

export default function TvCannalsPage() {
  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);
  const [channelList, setChannelList] = useState([]);

  const [sort, setSort] = useState(["id", "DESC"]);
  const [search, setSearch] = useState({
    url: "",
    name: "",
    epg_id: "",
    udp_url: "",
    groups_name: "",
  });

  const [countrySearch, setCountrySearch] = useState("");
  const [languageSearch, setLanguageSearch] = useState("");
  const [categorySearch, setCategorySearch] = useState("");

  const [selectedLanguageId, setSelectedLanguageId] = useState(null);
  const [selectedCountryId, setSelectedCountryId] = useState(null);
  const [selectedPlayerId, setSelectedPlayerId] = useState(null);

  const [selectedPublishedStatus, setSelectedPublishedStatus] = useState(null);

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [currentChannel, setCurrentChannel] = useState(null);

  const [visibleChannelDrawer, setVisibleChannelDrawer] = useState(false);
  const [visibleImportDrawer, setVisibleImportDrawer] = useState(false);
  const [visibleViewSettingsDrawer, setVisibleViewSettingsDrawer] = useState(false);
  const [visibleFilterDrawer, setVisibleFilterDrawer] = useState(false);

  const [activeChannelForPlayer, setActiveChannelForPlayer] = useState(null);

  const [columns, setColumns] = useState([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [viewSettingsValues, setViewSettingsValues] = useState({
    id: true,
    icon: true,
    name: true,
    description: true,
    epg_id: true,
    url: true,
    udp_url: true,
    country_id: true,
    languages_id: true,
    tv_categories: true,
    tizen_player: true,
    published_status: true,
    createdAt: true,
    action: true,
  });

  const configureTableColumns = () => {
    const newColumns = [];

    newColumns.push({
      title: translate["Select all"] || EN["Select all"],
      dataIndex: "select_all",
      key: "id",
      align: "center",
      fixed: "left",
    });

    newColumns.push({
      fixed: "left",
      width: 60,
      title: "#",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => (currentPage - 1) * limit + 1 + index,
    });

    newColumns.push({
      fixed: "left",
      title: "Number",
      dataIndex: "Channel_number",
      key: "Channel_number",
      align: "center",
      sorter: true,
    });

    if (viewSettingsValues.icon) {
      newColumns.push({
        width: 100,
        title: "",
        dataIndex: "icon",
        render: (text, record, index) => (
          <ImageComponent src={record.icon} className="tableImage" />
        ),
      });
    }

    if (viewSettingsValues.name) {
      newColumns.push({
        title: translate["Name"] || EN["Name"],
        dataIndex: "name",
        key: "name",
        sorter: true,

        render: (text, record, index) => {
          let title = "";

          const translations = record?.name_translations;

          for (let i = 0; i < translations.length; i++) {
            if (translations[i].is_default) {
              title = translations[i].text;
              break;
            }

            if (translations[i].text) title = translations[i].text;
          }

          return <LongText width={100}>{title || "N/A"}</LongText>;
        },

        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <>{ICONS.SEARCHOUTLINED}</>;
        },
        onFilter: (value, record) => {
          return record.name.toLowerCase().includes(value.toLowerCase());
        },
      });
    }

    if (viewSettingsValues.description) {
      newColumns.push({
        title: translate["Description"] || EN["Description"],
        dataIndex: "description",
        key: "description",
        width: 200,

        render: (text, record, index) => {
          let title = "";

          const translations = record?.description_translations;
          for (let i = 0; i < translations.length; i++) {
            if (translations[i].is_default) {
              title = translations[i].text;
              break;
            }

            if (translations[i].text) title = translations[i].text;
          }

          return <LongText>{title || "N/A"}</LongText>;
        },

        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <>{ICONS.SEARCHOUTLINED}</>;
        },
        onFilter: (value, record) => {
          return record.description.toLowerCase().includes(value.toLowerCase());
        },
      });
    }

    if (viewSettingsValues.epg_id) {
      newColumns.push({
        title: translate["Epg id"] || EN["Epg id"],
        dataIndex: "epg_id",
        key: "epg_id",
        sorter: true,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <>{ICONS.SEARCHOUTLINED}</>;
        },
        onFilter: (value, record) => {
          return record.epg_id.toLowerCase().includes(value.toLowerCase());
        },
      });
    }

    if (viewSettingsValues.url) {
      newColumns.push({
        title: translate["Url"] || EN["Url"],
        dataIndex: "url",
        key: "url",
        sorter: true,
        width: 250,
        render: (text, record, index) => <LongText>{text}</LongText>,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <>{ICONS.SEARCHOUTLINED}</>;
        },
        onFilter: (value, record) => {
          return record.url.toLowerCase().includes(value.toLowerCase());
        },
      });
    }

    if (viewSettingsValues.udp_url) {
      newColumns.push({
        title: translate["Udp url"] || EN["Udp url"],
        dataIndex: "udp_url",
        key: "udp_url",
        sorter: true,
        width: 250,
        render: (text, record, index) => <LongText>{text}</LongText>,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <>{ICONS.SEARCHOUTLINED}</>;
        },
        onFilter: (value, record) => {
          return record.udp_url.toLowerCase().includes(value.toLowerCase());
        },
      });
    }

    if (viewSettingsValues.country_id) {
      newColumns.push({
        title: translate["Country"] || EN["Country"],
        dataIndex: "country_id",
        key: "country_id",
        sorter: true,
        render: (text, record, index) => {
          return <p>{record.country ? record.country.name : "-"}</p>;
        },
        filterDropdown: ({ confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={countrySearch[0]}
              onChange={(e) => {
                setCountrySearch(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <>{ICONS.SEARCHOUTLINED}</>;
        },
        onFilter: (value, record) => {
          return record.country?.name.toLowerCase().includes(value.toLowerCase());
        },
      });
    }

    if (viewSettingsValues.languages_id) {
      newColumns.push({
        title: translate["Language"] || EN["Language"],
        dataIndex: "languages_id",
        key: "languages_id",
        sorter: true,
        render: (text, record, index) => <p>{record.language && record.language.name}</p>,
        filterDropdown: ({ confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={languageSearch[0]}
              onChange={(e) => {
                setLanguageSearch(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <>{ICONS.SEARCHOUTLINED}</>;
        },
        onFilter: (value, record) => {
          return record.language?.name.toLowerCase().includes(value.toLowerCase());
        },
      });
    }

    if (viewSettingsValues.tv_categories) {
      newColumns.push({
        title: translate["Categories"] || EN["Categories"],
        dataIndex: "tv_categories",
        key: "tv_categories",
        width: 200,
        render: (text, record, index) => {
          if (record.tv_categories && record.tv_categories.length > 0) {
            const categories = record.tv_categories.map((item) => item.tv_categori.name);
            return <p>{categories.join(", ")}</p>;
          } else {
            return "-";
          }
        },
        filterDropdown: ({ confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={categorySearch[0]}
              onChange={(e) => {
                setCategorySearch(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <>{ICONS.SEARCHOUTLINED}</>;
        },
        onFilter: (value, record) => {
          return record.tv_categories.toLowerCase().includes(value.toLowerCase());
        },
      });
    }
    if (viewSettingsValues.tizen_player) {
      newColumns.push({
        title: translate["Player"] || EN["Player"],
        dataIndex: "tizen_player",
        key: "tizen_player",
        render: (text, record, index) => record.tizen_player,
      });
    }

    if (viewSettingsValues.published_status) {
      newColumns.push({
        title: "Status",
        dataIndex: "published_status",
        key: "published_status",
        sorter: true,
        render: (text, record, index) => <Check check={record.published_status} />,
      });
    }

    if (viewSettingsValues.createdAt) {
      newColumns.push({
        title: translate["Date"] || EN["Date"],
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: true,
        render: (text, record, index) => dateFormat(text),
      });
    }
    newColumns.push({
      fixed: "right",
      width: 60,
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          style={{ minWidth: 130 }}
          buttons={[
            {
              key: "play",
              text: translate["Play"] || EN["Play"],
              icon: ICONS.PLAY,
            },
            {
              key: "edit",
              text: translate["Edit"] || EN["Edit"],
              icon: ICONS.EDIT,
            },
            {
              key: "delete",
              text: translate["Delete"] || EN["Delete"],
              icon: ICONS.DELETE,
            },
          ]}
        />
      ),
    });

    setColumns(newColumns);
  };

  useEffect(() => {
    if (!visibleChannelDrawer) {
      setCurrentChannel(null);
    }
  }, [visibleChannelDrawer]);

  useEffect(() => {
    getChannelList();
  }, [
    currentPage,
    limit,
    sort,
    selectedCountryId,
    selectedLanguageId,
    selectedPublishedStatus,
    selectedPlayerId,
  ]);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      getChannelList();
    }, 1000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [search]);

  useEffect(() => {
    let timeout = null;
    if (countrySearch[0] || languageSearch[0] || categorySearch[0]) {
      if (
        countrySearch[0]?.length > 2 ||
        languageSearch[0]?.length > 2 ||
        categorySearch[0]?.length > 2
      ) {
        timeout = setTimeout(() => {
          getChannelList();
        }, 1000);
      }
    } else {
      getChannelList();
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [countrySearch, languageSearch, categorySearch]);

  useEffect(() => {
    configureTableColumns();
  }, [viewSettingsValues, currentPage]);

  useEffect(() => {
    let LIVE_TV_CHANNELS_COLUMNS = localStorage.getItem(
      LOCAL_STORAGE_VALUES.LIVE_TV_CHANNELS_COLUMNS
    );

    if (LIVE_TV_CHANNELS_COLUMNS) {
      LIVE_TV_CHANNELS_COLUMNS = JSON.parse(LIVE_TV_CHANNELS_COLUMNS);
      setViewSettingsValues(LIVE_TV_CHANNELS_COLUMNS);
    }
  }, []);

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "play":
        setActiveChannelForPlayer(item);
        break;

      case "edit":
        setCurrentChannel(item);
        setVisibleChannelDrawer(true);
        break;

      case "delete":
        confirmModal({
          title: `${translate["Do you want to delete"] || EN["Do you want to delete"]} ${
            item.name
          }`,
          action: () => deleteChannel(item),
        });
        break;

      default:
        break;
    }
  };

  const deleteChannel = (item) => {
    REQUESTS.TV_CHANNELS.DELETE(item.id, getChannelList, (err) => {
      message.error(err);
    });
  };

  const getChannelList = () => {
    setLoading(true);

    const query = {
      sort,
      limit,
      pagination: 1,
      page: currentPage,
    };

    if (
      search.groups_name ||
      search.name ||
      search.epg_id ||
      search.url ||
      search.udp_url
    ) {
      query.search = JSON.stringify({
        name: search.name && search.name[0],
        groups_name: search.groups_name && search.groups_name[0],
        epg_id: search.epg_id && search.epg_id[0],
        url: search.url && search.url[0],
        udp_url: search.udp_url && search.udp_url[0],
      });
    }

    if (countrySearch[0]) {
      query.country_name = countrySearch[0];
      if (query.pagination) {
        delete query.pagination;
        delete query.limit;
      }
    }
    if (languageSearch[0]) {
      query.languages_name = languageSearch[0];
      if (query.pagination) {
        delete query.pagination;
        delete query.limit;
      }
    }
    if (categorySearch[0]) {
      query.category_name = categorySearch[0];
      if (query.pagination) {
        delete query.pagination;
        delete query.limit;
      }
    }

    let filter = {};

    if (selectedLanguageId) {
      filter.languages_id = selectedLanguageId;
    }

    if (selectedCountryId) {
      filter.country_id = selectedCountryId;
    }

    if (selectedPublishedStatus != null) {
      filter.published_status = selectedPublishedStatus;
    }
    if (selectedPlayerId) {
      filter.tizen_player = selectedPlayerId;
    }

    const size = Object.keys(filter).length;

    if (size) {
      query.filter = JSON.stringify(filter);
    }

    function callback(data) {
      if (data.rows) {
        setChannelList(data.rows);
        setLimit(data.limit);
        setTotal(data.count);
      } else {
        setChannelList(data);
      }
      setLoading(false);

      if (data.rows.length === 0 && currentPage !== 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }
    }

    function errorCallback() {
      setLoading(false);
    }

    REQUESTS.TV_CHANNELS.GET(query, callback, errorCallback);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }

    setSearch(filters);
    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  return (
    <TvChannelsContext.Provider
      value={{
        total,
        visibleChannelDrawer,
        setVisibleChannelDrawer,
        visibleImportDrawer,
        setVisibleImportDrawer,
        currentChannel,
        getChannelList,
        visibleViewSettingsDrawer,
        setVisibleViewSettingsDrawer,
        visibleFilterDrawer,
        setVisibleFilterDrawer,
        viewSettingsValues,
        setViewSettingsValues,
        selectedLanguageId,
        setSelectedLanguageId,
        selectedCountryId,
        setSelectedCountryId,
        selectedPublishedStatus,
        setSelectedPublishedStatus,
        selectedPlayerId,
        setSelectedPlayerId,
      }}
    >
      <Page>
        <TvChannelHeader
          selectedRows={selectedRowKeys}
          deleteChannel={() => {
            confirmModal({
              title: `${
                translate["Do you want to delete"] || EN["Do you want to delete"]
              } ${selectedRowKeys.length} ${translate["Tv Channel"] || EN["Tv Channel"]}`,
              action: () =>
                deleteChannel({
                  id: selectedRowKeys,
                }),
            });
          }}
        />

        <div className="page-body">
          <TableHead />

          <Table
            style={{ marginTop: 20 }}
            rowKey="id"
            loading={loading}
            columns={columns}
            dataSource={channelList}
            onChange={handleTableChange}
            rowSelection={{
              type: "checkbox",
              onChange: (selectedRowKeys, selectedRows) => {
                setSelectedRowKeys(selectedRowKeys);
              },
            }}
            pagination={{
              position: ["topCenter", "bottomCenter"],
              current: currentPage,
              total: total,
              showSizeChanger: true,
            }}
            scroll={{ x: 2000, y: null }}
            sticky={{
              offsetHeader: 60,
              offsetScroll: 0,
            }}
          />

          <AddNewChannelDrawer />
          <ImportFileDrawer />

          <PlayChannelDrawer
            channel={activeChannelForPlayer}
            visible={activeChannelForPlayer ? true : false}
            onClose={() => setActiveChannelForPlayer(null)}
          />
        </div>
      </Page>
    </TvChannelsContext.Provider>
  );
}
