import React, { useEffect, useState , useContext} from "react";
import { useSelector } from "react-redux";
import { Drawer, Form, Input } from "antd";

import REQUESTS from "../../../../server/requests";

import ICONS from "../../../../config/icons";
import EN from "../../../../config/en";

import ErrorMessage from "../../../elements/ErrorMessage";
import SaveButtonComponent from "../../../elements/SaveButtonComponent";

import Context from "./Context";
import TvChannelsSection from "./TvChannelsSection";
import MoviesVodSection from "./MoviesVodSection";
import EcommerceSection from "./EcommerceSection";
import WidgetsSection from "./WidgetsSection";
import GuiSection from "./GuiSection";
import SwitchesSection from "./SwitchesSection";

export default function PackageDrawer() {
    const { 
        visiblePackageDrawer, 
        setVisiblePackageDrawer, 
        currentPackege, 
        getPackegesList 
    } = useContext(Context);

    const translate = useSelector((state) => state.globalState.translate);

    const [enableTvChannels, setEnableTvChannels] = useState(true);
    const [accessToAllChannels, setAccessToAllChannels] = useState(false);

    const [enableMoviesVod, setEnableMoviesVod] = useState(true);
    const [accessToAllMovies, setAccessToAllMovies] = useState(false);

    const [enableEcommerce, setEnableEcommerce] = useState(true);
    const [accessToAllServicesAndProducts, setAccessToAllServicesAndProducts] = useState(false);

    const [enableWidgets, setEnableWidgets] = useState(true);
    const [accessToAllWidgets, setAccessToAllWidgets] = useState(false);

    const [selectedGuiId, setSelectedGuiId] = useState(null);

    const [loading, setLoading] = useState(false);

    const [errorText, setErrorText] = useState(null);

    useEffect(() => {
        if (accessToAllChannels) {
            form.setFields([
                { name: "tv_channels_rating", value: undefined },
                { name: "tv_channels_countries", value: undefined },
                { name: "tv_channels_languages", value: undefined },
                { name: "tv_channels_categories", value: undefined },
            ]);
        }
    }, [accessToAllChannels]);

    useEffect(() => {
        if (accessToAllMovies) {
            form.setFields([
                { name: "vod_rating", value: undefined },
                { name: "vod_languages", value: undefined },
                { name: "vod_categories", value: undefined },
            ]);
        }
    }, [accessToAllMovies]);

    useEffect(() => {
        if (accessToAllServicesAndProducts) {
            form.setFields([{ name: "e_commerce_categories", value: undefined }]);
        }
    }, [accessToAllServicesAndProducts]);

    useEffect(() => {
        if (accessToAllWidgets) {
            form.setFields([{ name: "widgets", value: undefined }]);
        }
    }, [accessToAllWidgets]);

    useEffect(() => {
        if (visiblePackageDrawer) {
            // form.resetFields();

            if (currentPackege) {

                setEnableTvChannels(currentPackege.access_to_TV_Channels.enabled);
                setAccessToAllChannels(currentPackege.access_to_TV_Channels.access);

                setEnableMoviesVod(currentPackege.access_to_Movies.enabled);
                setAccessToAllMovies(currentPackege.access_to_Movies.access);

                setEnableEcommerce(currentPackege.access_to_Services_and_products.enabled);
                setAccessToAllServicesAndProducts(
                    currentPackege.access_to_Services_and_products.access
                );

                setEnableWidgets(currentPackege.access_to_Widget.enabled);
                setAccessToAllWidgets(currentPackege.access_to_Widget.access);

                setSelectedGuiId(currentPackege.GUI_id);

                form.setFields([
                    { name: "name", value: currentPackege.name },
                    { name: "feedback_sccess", value: currentPackege.feedback_sccess },
                    { name: "news_Views", value: currentPackege.news_Views },
                    { name: "send_messages", value: currentPackege.send_messages },
                    {
                        name: "view_Billing_Information",
                        value: currentPackege.view_Billing_Information,
                    },
                    { name: "view_promotions", value: currentPackege.view_promotions },
                    {
                        name: "tv_channels_languages",
                        value: currentPackege.access_to_TV_Channels.language,
                    },

                    {
                        name: "tv_channels_countries",
                        value: currentPackege.access_to_TV_Channels.country,
                    },

                    {
                        name: "tv_channels_categories",
                        value: currentPackege.access_to_TV_Channels.category,
                    },

                    {
                        name: "tv_channels_rating",
                        value: currentPackege.access_to_TV_Channels.rating,
                    },

                    {
                        name: "vod_languages",
                        value: currentPackege.access_to_Movies.language,
                    },

                    {
                        name: "vod_categories",
                        value: currentPackege.access_to_Movies.category,
                    },

                    {
                        name: "vod_rating",
                        value: currentPackege.access_to_Movies.rating,
                    },

                    {
                        name: "e_commerce_categories",
                        value: currentPackege.access_to_Services_and_products.category,
                    },

                    {
                        name: "access_to_Widget",
                        value: currentPackege.access_to_Widget.widget,
                    },
                ]);
            }
        } else {
            setErrorText(null);

            setEnableTvChannels(true);
            setAccessToAllChannels(false);

            setEnableMoviesVod(true);
            setAccessToAllMovies(false);

            setEnableEcommerce(true);
            setAccessToAllServicesAndProducts(false);

            setEnableWidgets(true);
            setAccessToAllWidgets(false);

            setSelectedGuiId(null);
        }
    }, [visiblePackageDrawer]);

    useEffect(()=>{
        if(!visiblePackageDrawer && currentPackege){
            form.resetFields();
        }
    },[visiblePackageDrawer])

    const [form] = Form.useForm();

    const onFinish = (values) => {
        setLoading(true);

        setErrorText(null);

        const body = {
            name: values.name,
            access_to_TV_Channels: JSON.stringify({
                enabled: enableTvChannels,
                access: accessToAllChannels,
                language: values.tv_channels_languages,

                country: values.tv_channels_countries,
                category: values.tv_channels_categories,
                rating: values.tv_channels_rating,
            }),

            access_to_Movies: JSON.stringify({
                enabled: enableMoviesVod,
                access: accessToAllMovies,
                language: values.vod_languages,
                category: values.vod_categories,
                rating: values.vod_rating,
            }),

            access_to_Services_and_products: JSON.stringify({
                enabled: enableEcommerce,
                access: accessToAllServicesAndProducts,
                category: values.e_commerce_categories,
            }),

            access_to_Widget: JSON.stringify({
                enabled: enableWidgets,
                access: accessToAllWidgets,

                category: values.widget,
            }),

            news_Views: values.news_Views,
            send_messages: values.send_messages,
            view_promotions: values.view_promotions,
            feedback_sccess: values.feedback_sccess,
            view_Billing_Information: values.view_Billing_Information,
            GUI_id: selectedGuiId,
        };

        function callback() {
            setLoading(false);
            setVisiblePackageDrawer();
            getPackegesList();
            form.resetFields();
        }

        function errorCallback(err) {
            setErrorText(err);
            setLoading(false);
        }

        if (currentPackege) {
            body.id = currentPackege.id;
            REQUESTS.PACKAGES.EDIT(body, callback, errorCallback);
        } else {
            REQUESTS.PACKAGES.ADD(body, callback, errorCallback);
        }
    };

    const onFinishFailed = () => {
        setErrorText(null);
    };

    return (
        <Context.Provider
            value={{
                enableTvChannels,
                setEnableTvChannels,
                accessToAllChannels,
                setAccessToAllChannels,
                enableMoviesVod,
                setEnableMoviesVod,
                accessToAllMovies,
                setAccessToAllMovies,
                form,
                enableEcommerce,
                setEnableEcommerce,
                accessToAllServicesAndProducts,
                setAccessToAllServicesAndProducts,
                enableWidgets,
                setEnableWidgets,
                accessToAllWidgets,
                setAccessToAllWidgets,
                selectedGuiId,
                setSelectedGuiId,
            }}
        >
            <Drawer
                title={translate['Package'] || EN['Package']}
                placement="right"
                width={600}
                onClose={() => setVisiblePackageDrawer(false)}
                visible={visiblePackageDrawer}
               
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="package-form"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    initialValues={{
                        feedback_sccess: currentPackege ? currentPackege.feedback_sccess : false,
                        view_Billing_Information: currentPackege
                            ? currentPackege.view_Billing_Information
                            : false,
                        news_Views: currentPackege ? currentPackege.news_Views : false,
                        send_messages: currentPackege ? currentPackege.send_messages : false,
                        view_promotions: currentPackege ? currentPackege.view_promotions : false,
                        view_Billing_Information: currentPackege
                            ? currentPackege.view_Billing_Information
                            : false,
                    }}
                >
                    <Form.Item
                        label={translate['Package name'] || EN['Package name']}
                        tooltip={{
                            title: "Package name",
                            icon: ICONS.INFO,
                        }}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: `${translate['Please input'] || EN['Please input']} ${translate['Package name'] || EN['Package name']}`,
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>

                    <TvChannelsSection />

                    <MoviesVodSection />

                    <EcommerceSection />

                    <WidgetsSection />

                    {/* <GuiSection /> */}

                    {/* <SwitchesSection /> */}

                    <Form.Item>
                        <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
                    </Form.Item>

                    <ErrorMessage>{errorText}</ErrorMessage>
                </Form>
            </Drawer>
        </Context.Provider>
    );
}
