import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Drawer, Form, Input, Switch, Select } from "antd";

import REQUESTS from "../../../server/requests";

import ICONS from "../../../config/icons";
import EN from "../../../config/en";

import ErrorMessage from "../../elements/ErrorMessage";
import SaveButtonComponent from "../../elements/SaveButtonComponent";
import FileChooser from "../../elements/FileChooser";

import {useDeviceTypeOptions} from "../../../hooks/options";
// 
export default function AddAppDrawer({
  visible,
  onClose,
  current,
  getAppList,
}) {
  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);

  const [errorText, setErrorText] = useState(null);

  const [imageSrc, setImageSrc] = useState(null);

  const [form] = Form.useForm();

  const deviceTypes = useDeviceTypeOptions();

  useEffect(() => {
    if (visible) {
      if (current) {
        form.setFields([
          { name: "name", value: current.name },
          { name: "icon", value: current.icon },
          { name: "is_enabled", value: current.is_enabled },
          { name: "device_type", value: current.device_type},
          { name: "application_id", value:current.application_id},
        ]);

        if (current.icon) {
          setImageSrc(current.icon);
        }
      }
    } else {
      setErrorText(null);
    }
  }, [visible]);

  useEffect(() => {
    if (!visible && current) {
      form.resetFields();
      setImageSrc(null);
    }
  }, [visible]);

  const onFinish = (values) => {
    setLoading(true);

    setErrorText(null);

    const body = {
      name: values.name,
      icon: values.icon,
      is_enabled: values.is_enabled,
      device_type: values.device_type,
      application_id: values.application_id,
    };

    function callback() {
      setLoading(false);
      onClose();
      getAppList();
      form.resetFields();
      setImageSrc(null);
    }

    function errorCallback(err) {
      setErrorText(err);
      setLoading(false);
    }

    if (current) {
      body.id = current.id;
      REQUESTS.APPLICATION.EDIT(body, callback, errorCallback);
    } else {
      REQUESTS.APPLICATION.ADD(body, callback, errorCallback);
    }
  };

  const onFinishFailed = () => {
    setLoading(false);
    setErrorText(null);
  };

  const selectFile = (item) => {
    setImageSrc(item.file_url);
    form.setFields([{ name: "icon", value: item.file_url }]);
  };

  const repliceImage = () => {
    setImageSrc(null);
    form.setFields([{ name: "icon", value: null }]);
  };
  return (
    <Drawer
      title={
        current
          ? translate["Edit Application"] || EN["Edit Application"]
          : translate["Add New Application"] || EN["Add New Application"]
      }
      placement="right"
      width={600}
      visible={visible}
      onClose={onClose}
    >
      <Form
        form={form}
        layout="vertical"
        name="gui-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          is_enabled: false,
        }}
      >
        <Form.Item
          label={translate["Image"] || EN["Image"]}
          tooltip={{
            title: "Choose an image",
            icon: ICONS.INFO,
          }}
          name="icon"
          rules={[
            {
              required: true,
              message: `${translate["Please choose"] || EN["Please choose"]} ${
                translate["Image"] || EN["Image"]
              }`,
            },
          ]}
        >
          <FileChooser
            src={imageSrc}
            onChange={selectFile}
            replace={repliceImage}
            types={["image"]}
          />
        </Form.Item>

        <Form.Item
          label={translate["Name"] || EN["Name"]}
          tooltip={{
            title: "Application title",
            icon: ICONS.INFO,
          }}
          name="name"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Name"] || EN["Name"]
              }`,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate['Application Id'] || EN['Application Id']}
          tooltip={{
            title: "Choose Device Type",
            icon: ICONS.INFO,
          }}
          name="application_id"
          rules={[
            {
              required: true,
              message: `${translate['Please select'] || EN['Please select']} ${translate['Application Id'] || EN['Application Id']}`,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translate['Device Type'] || EN['Device Type']}
          tooltip={{
            title: "Choose Device Type",
            icon: ICONS.INFO,
          }}
          name="device_type"
          rules={[
            {
              required: true,
              message: `${translate['Please select'] || EN['Please select']} ${translate['Device Type'] || EN['Device Type']}`,
            },
          ]}
        >
          <Select showArrow allowClear={true} options={deviceTypes} />
        </Form.Item>

        <Form.Item
          label={translate['Enabled'] || EN['Enabled']}
          tooltip={{
            title: "Published Status",
            icon: ICONS.INFO,
          }}
        >
          <Form.Item name="is_enabled" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form.Item>

        <Form.Item>
          <SaveButtonComponent
            loading={loading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
      <ErrorMessage>{errorText}</ErrorMessage>
    </Drawer>
  );
}
