import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Drawer, Select } from "antd";

import { useRoomsGroupsOptions } from "../../../../hooks/options";

import REQUESTS from "../../../../server/requests";

import EN from "../../../../config/en";
import ICONS from "../../../../config/icons";
import BUTTON_CLASSES from "../../../../config/button-classes";

import ButtonComponent from "../../../elements/ButtonComponent";

export default function FilterDrawer({ visible, onClose, setSelectedGroupId }) {

    const translate = useSelector((state) => state.globalState.translate);

    const [selectedGroup, setSelectedGroup] = useState("");

    const groupsOptions = useRoomsGroupsOptions();

    const onClickFilter = () => {
        setSelectedGroupId(selectedGroup);
        onClose();
    };

    return (
        <Drawer 
            title={translate['Filter'] || EN['Filter']} 
            placement="right" 
            onClose={onClose} 
            visible={visible}
        >
            <div style={{ display: "flex", flexDirection: "column" }}>
                <h1>{translate['Select filter parameters'] || EN['Select filter parameters']}</h1>

                <p>{translate['Categories'] || EN['Categories']}:</p>
                <Select
                    showArrow
                    allowClear={true}
                    value={selectedGroup}
                    onChange={setSelectedGroup}
                    options={groupsOptions}
                    style={{ width: "100%", marginBottom: 20 }}
                />

                <ButtonComponent
                    text={translate['Filter'] || EN['Filter']}
                    icon={ICONS.ARROW_RIGHT_OUTLINED}
                    buttonClass={BUTTON_CLASSES.APP_BUTTON}
                    onClick={onClickFilter}
                />
            </div>
        </Drawer>
    );
}
