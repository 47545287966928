import React, { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Drawer, Select } from "antd";

import Context from "./Context";

import {
  usePublishedStatusOptions,
  useOrdersOptions,
} from "../../../../../hooks/options";

import ICONS from "../../../../../config/icons";

import ButtonComponent from "../../../../elements/ButtonComponent";
import EN from "../../../../../config/en";

export default function FilterDrawer() {
  const {
    visibleFilterDrawer,
    setVisibleFilterDrawer,
    setSelectedPublishedStatus,
    setOrdersTypeId,
  } = useContext(Context);

  const translate = useSelector((state) => state.globalState.translate);

  const ordersTypesOptions = useOrdersOptions();
  const publishedStatusOptions = usePublishedStatusOptions();


  const [selectedOrdersType, setSelectedOrdersType] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const onClickFilter = () => {
    setSelectedPublishedStatus(selectedStatus);
    setOrdersTypeId(selectedOrdersType);

    setVisibleFilterDrawer(false);
  };
  return (
    <Drawer
      title={translate['Filter'] || EN['Filter']}
      placement="right"
      visible={visibleFilterDrawer}
      onClose={() => setVisibleFilterDrawer(false)}
     
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h1>{translate['Select filter parameters'] || EN['Select filter parameters']}</h1>

        <p>{translate['Select orders table'] || EN['Select orders table']}:</p>
        <Select
          showArrow
          allowClear={true}
          value={selectedOrdersType}
          onChange={setSelectedOrdersType}
          options={ordersTypesOptions}
          style={{ width: "100%", marginBottom: 20 }}
        />

        <p>{translate['Status'] || EN['Status']}:</p>
        <Select
          showArrow
          allowClear={true}
          value={selectedStatus}
          onChange={setSelectedStatus}
          options={publishedStatusOptions}
          style={{ width: "100%", marginBottom: 20 }}
        />

        <ButtonComponent
          text={translate['Filter'] || EN['Filter']}
          icon={ICONS.ARROW_RIGHT_OUTLINED}
          onClick={onClickFilter}
        />
      </div>
    </Drawer>
  );
}
