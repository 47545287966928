import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Form, Input } from "antd";

import REQUESTS from "../../../server/requests";

import EN from "../../../config/en";
import ICONS from "../../../config/icons";
import NAVIGATORS from "../../../config/navigators";
import { APP_VERSION } from "../../../config/config";

import Logo from "../../elements/Logo";
import ErrorMessage from "../../elements/ErrorMessage";
import ButtonComponent from "../../elements/ButtonComponent";

import "./_login_registration.scss";

export default function ResetPasswordPage({}) {
    const navigate = useNavigate();

    const translate = useSelector((state) => state.globalState.translate);

    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [errorText, setErrorText] = useState(null);

    const onFinish = (values) => {
        setErrorText(null);

        const body = {
            email: values.email,
        };

        function callback(data) {
            setLoading(false);
            navigate(NAVIGATORS.CHECK_EMAIL, { state: { email: values.email } });
        }

        function errorCallback(err) {
            if (typeof err === "string") {
                setErrorText(err);
            } else if (typeof err.message === "string") {
                setErrorText(err.message);
            }
        }

        REQUESTS.EMPLOYERS.RESET_PASSWORD(body, callback, errorCallback);
    };

    const onFinishFailed = () => {
        setErrorText(null);
    };

    const backToLogin = () => {
        navigate(NAVIGATORS.LOGIN);
    };

    const onClickResetPassword = () => {
        form.submit();
    };

    return (
        <div className="page">
            <div className="image-section">
                <div className="app-version">
                    <span>App Version: {APP_VERSION}</span>
                </div>
            </div>

            <div className="form-section">
                <div className="container">
                    <Logo showTitle={true} />

                    <div className="form-style">
                        <h1>{translate['Reset password'] || EN['Reset password']}</h1>
                        <p className="text-in-reset-password">
                            {
                                translate['Enter your email address and we will send you instructions to reset your account password'] ||
                                EN['Enter your email address and we will send you instructions to reset your account password']
                            }
                        </p>
                        <Form
                            form={form}
                            layout="vertical"
                            name="reset-form"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item label={translate['Email address'] || EN['Email address']}>
                                <Form.Item
                                    name="email"
                                    noStyle
                                    rules={[
                                        {
                                            required: true,
                                            message: `${translate['Please input your'] || EN['Please input your']} ${translate['Email address'] || EN['Email address']}`,
                                        },
                                    ]}
                                >
                                    <Input 
                                        className="input-style" 
                                        autoComplete="new-password" 
                                        onPressEnter={onClickResetPassword}
                                    />
                                </Form.Item>
                            </Form.Item>

                            <Form.Item>
                                <div className="reset-password-buttones">
                                    <ButtonComponent
                                        iconLeft={true}
                                        text={translate['Back to login'] || EN['Back to login']}
                                        icon={ICONS.ARROW_LEFT_OUTLINED}
                                        onClick={backToLogin}
                                        style={{ width: 175 }}
                                    />

                                    <ButtonComponent
                                        loading={loading}
                                        text={translate['Reset password'] || EN['Reset password']}
                                        icon={ICONS.ARROW_RIGHT_OUTLINED}
                                        onClick={onClickResetPassword}
                                        style={{ width: 175 }}
                                    />
                                </div>
                            </Form.Item>
                        </Form>

                        <ErrorMessage>{errorText}</ErrorMessage>
                    </div>
                </div>
            </div>
        </div>
    );
}
