import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Drawer, Form, Input } from "antd";

import REQUESTS from "../../../server/requests";

import ICONS from "../../../config/icons";
import EN from "../../../config/en";

import ErrorMessage from "../../elements/ErrorMessage";
import SaveButtonComponent from "../../elements/SaveButtonComponent";
import FileChooser from "../../elements/FileChooser";

export default function AddGuiDrawer({ visible, onClose, currentGui, getGuiList }) {

    const translate = useSelector((state) => state.globalState.translate);

    const [loading, setLoading] = useState(false);

    const [errorText, setErrorText] = useState(null);

    const [imageSrc, setImageSrc] = useState(null);

    const [form] = Form.useForm();

    useEffect(() => {
        if (visible) {
            if (currentGui) {
                form.setFields([
                    { name: "title", value: currentGui.title },
                    { name: "image", value: currentGui.image },
                ]);

                if (currentGui.image) {
                    setImageSrc(currentGui.image);
                }
            }
        } else {
            setErrorText(null);
        }
    }, [visible]);

    useEffect(()=>{
        if (!visible && currentGui) {
            form.resetFields();
            setImageSrc(null);
        }
    },[visible])

    const onFinish = (values) => {
        setLoading(true);

        setErrorText(null);

        const body = {
            title: values.title,
            image: values.image,
        };

        function callback() {
            setLoading(false);
            onClose();
            getGuiList();
            form.resetFields();
            setImageSrc(null);
        }

        function errorCallback(err) {
            setErrorText(err);
            setLoading(false);
        }

        if (currentGui) {
            body.id = currentGui.id;
            REQUESTS.GUI.EDIT(body, callback, errorCallback);
        } else {
            REQUESTS.GUI.ADD(body, callback, errorCallback);
        }
    };

    const onFinishFailed = () => {
        setLoading(false);
        setErrorText(null);
    };

    const selectFile = (item) => {
        setImageSrc(item.file_url);
        form.setFields([{ name: "image", value: item.file_url }]);
    };

    const repliceImage = () => {
        setImageSrc(null);
        form.setFields([{ name: "image", value: null }]);
    };
    return (
        <Drawer
            title={currentGui ? translate['Edit GUI'] || EN['Edit GUI'] : translate['Add New GUI'] || EN['Add New GUI']}
            placement="right"
            width={600}
            visible={visible}
            onClose={onClose}
           
        >
            <Form
                form={form}
                layout="vertical"
                name="gui-form"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label={translate['Image'] || EN['Image']}
                    tooltip={{
                        title: "Choose an image",
                        icon: ICONS.INFO,
                    }}
                    name="image"
                    rules={[
                        {
                            required: true,
                            message: `${translate['Please choose'] || EN['Please choose']} ${translate['Image'] || EN['Image']}`,
                        },
                    ]}
                >
                    <FileChooser
                        src={imageSrc}
                        onChange={selectFile}
                        replace={repliceImage}
                        types={["image"]}
                    />
                </Form.Item>

                <Form.Item
                    label={translate['Title'] || EN['Title']}
                    tooltip={{
                        title: "GUI title",
                        icon: ICONS.INFO,
                    }}
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: `${translate['Please input'] || EN['Please input']} ${translate['Title'] || EN['Title']}`,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item>
                    <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
                </Form.Item>
            </Form>
            <ErrorMessage>{errorText}</ErrorMessage>
        </Drawer>
    );
}
