import { useContext } from "react";
import { useSelector } from "react-redux";
import { Drawer, Checkbox } from "antd";

import Context from "./Context";

import EN from "../../../../../config/en";
import LOCAL_STORAGE_VALUES from "../../../../../config/localStorageValues";

export default function ViewSettingsDrawer() {
    const {
        visibleViewSettingsDrawer,
        setVisibleViewSettingsDrawer,
        viewSettingsValues,
        setViewSettingsValues,
    } = useContext(Context);

    const translate = useSelector((state) => state.globalState.translate);

    const onChangeSettingsValues = (event) => {
        viewSettingsValues[event.target.name] = event.target.checked;
        setViewSettingsValues({ ...viewSettingsValues });

        localStorage.setItem(
            LOCAL_STORAGE_VALUES.EPG_LINKS_COLUMNS,
            JSON.stringify(viewSettingsValues)
        );
    };

    return (
        <Drawer
            title={translate['View settings'] || EN['View settings']}
            placement="right"
            onClose={() => setVisibleViewSettingsDrawer(false)}
            visible={visibleViewSettingsDrawer}
        >
            <h2>{translate['Show columns'] || EN['Show columns']}</h2>

            <div className="view-settings-checkboxes">
                <Checkbox
                    name="enable"
                    checked={viewSettingsValues.enable}
                    onChange={onChangeSettingsValues}
                >
                    {translate['Enable'] || EN['Enable']}
                </Checkbox>

                <Checkbox
                    name="name"
                    checked={viewSettingsValues.name}
                    onChange={onChangeSettingsValues}
                >
                    {translate['Name'] || EN['Name']}
                </Checkbox>

                <Checkbox
                    name="url"
                    checked={viewSettingsValues.url}
                    onChange={onChangeSettingsValues}
                >
                    {translate['Url'] || EN['Url']}
                </Checkbox>

                <Checkbox
                    name="createdAt"
                    checked={viewSettingsValues.createdAt}
                    onChange={onChangeSettingsValues}
                >
                    {translate['Date'] || EN['Date']}
                </Checkbox>
            </div>
        </Drawer>
    );
}
