import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table, Input } from "antd";

import REQUESTS from "../../../../../server/requests";

import LongText from "../../../../elements/LongText";
import Page from "../../../../elements/Page";
import TitleComponent from "../../../../elements/TitleComponent";

import { dateFormat } from "../../../../../config/formats";
import EN from "../../../../../config/en";
import ICONS from "../../../../../config/icons";

export default function HistoryFeedback({ room_id }) {
  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);
  const [feedbackList, setFeedbackList] = useState([]);
  const [sort, setSort] = useState(["id", "DESC"]);
  const [search, setSearch] = useState({ guest_name: ""});

  const columns = [
    {
      width: 60,
      fixed: "left",
      title: "#",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => index + 1,
    },

    {
      title: translate['Question title'] || EN['Question title'],
      dataIndex: "questions",
      key: "questions",
      render: (text, record, index) => (
        <p className="text-ellipsis">{record.feedback_question?.questions}</p>
      ),
    },

    {
      title: translate['Description'] || EN['Description'],
      dataIndex: "description",
      key: "description",
      render: (text, record, index) => (
        <LongText>{record.feedback_question?.description}</LongText>
      ),
    },

    {
      title: translate['Guest'] || EN['Guest'],
      dataIndex: "guest_name",
      key: "guest_name",
      render: (text, record, index) => text,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <Input
            allowClear
            autoFocus
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: () => {
        return <> {ICONS.SEARCHOUTLINED} </>;
      },

      onFilter: (value, record) => {
        return record.guest_name.toLowerCase().includes(value.toLowerCase());
      },
    },

    {
      title: translate['Rating'] || EN['Rating'],
      dataIndex: "rating",
      sorter: true,
      key: "rating",
      with: 100,
      render: (text, record, index) => <p className="txt-tb-r">{record.rating} </p>,
    },

    {
      title: translate['Arrival Date'] || EN['Arrival Date'],
      dataIndex: "arival_date",
      key: "arival_date",
      sorter: true,
      render: (text, record, index) => dateFormat(text),
    },

    {
      title: translate['Created Data'] || EN['Created Data'],
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (text, record, index) => dateFormat(text),
    },
  ];

  useEffect(() => {
    getFeedbackHistoryList();
  }, [room_id, sort]);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
        getFeedbackHistoryList();
    }, 1000);

    return () => {
        clearTimeout(searchTimeout);
    };
}, [search]);

  const getFeedbackHistoryList = () => {
    setLoading(true);

    const query = {
      sort,
      room_id: room_id,
    };

    if (search.guest_name || search.rating ) {
        query.search = JSON.stringify({
          guest_name: search.guest_name && search.guest_name[0],
        });
    }

    function callback(data) {
      setFeedbackList(data);
      setLoading(false);
    }

    function errorCallback() {
      setLoading(false);
    }

    REQUESTS.HISTORY.FEEDBACK(query, callback, errorCallback);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    setSearch(filters);
  };
  return (
    <Page>
      <div className="page-body">
        <TitleComponent>
          {translate['Feedback History'] || EN['Feedback History']}
        </TitleComponent>
        <Table
          defaultExpandAllRows={true}
          loading={loading}
          rowKey="id"
          columns={columns}
          dataSource={feedbackList}
          onChange={handleTableChange}
          scroll={{
            x: "max-content",
            y: null,
          }}
          pagination={false}
          sticky={{
            offsetHeader: 60,
            offsetScroll: 0,
          }}
        />
      </div>
    </Page>
  );
}
