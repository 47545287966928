import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table, Input, Form } from "antd";

import Context from "./Context";

import REQUESTS from "../../../server/requests";

import EN from "../../../config/en";
import ICONS from "../../../config/icons";
import { dateFormat, parseYearMonthDayHoursMinutes } from "../../../config/formats";
import confirmModal from "../../../config/confirmModal";

import ErrorMessage from "../../elements/ErrorMessage";
import ImageComponent from "../../elements/ImageComponent";
import CompletedComponent from "../../elements/CompletedComponent";
import AcceptRejectBtnComponent from "../../elements/AcceptRejectBtnComponent";
import Currency from "../../elements/Currency";
import Page from "../../elements/Page";

import Header from "./Header";

import TableHead from "./TableHead";

export default function OrdersPage() {
  const translate = useSelector((state) => state.globalState.translate);

  const new_message = useSelector((state) => state.globalState.new_message);

  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState([]);

  const [sort, setSort] = useState(["id", "DESC"]);
  const [search, setSearch] = useState({ name: "" });

  const [roomSearch, setRoomSearch] = useState("");
  const [guestSearch, setGuestSearch] = useState("");

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedPublishedStatus, setSelectedPublishedStatus] = useState(null);
  const [selectedHostId, setSelectedHostId] = useState(null);
  const [selectedLocationNameId, setSelectedLocationNameId] = useState(null);
  const [ordersTypeId, setOrdersTypeId] = useState(null);

  const [visibleFilterDrawer, setVisibleFilterDrawer] = useState(false);
  const [visibleViewSettingsDrawer, setVisibleViewSettingsDrawer] = useState(false);

  const [selecteOrder, setSelectedOrder] = useState(null);

  const [columns, setColumns] = useState([]);

  const [errorText, setErrorText] = useState(null);
  const [form] = Form.useForm();

  const [viewSettingsValues, setViewSettingsValues] = useState({
    id: true,
    image: true,
    name: true,
    type: true,
    price: true,
    completed: true,
    guest: true,
    location_name: true,
    quantity: true,
    createdAt: true,
    action: true,
    rejected_date: true,
    accepted_date: true,
  });

  useEffect(() => {
    if (new_message?.message_type === "product") {
      getProductList();
    }
  }, [new_message]);

  const configureTableColumns = () => {
    const newColumns = [];
    newColumns.push({
      width: 60,
      fixed: "left",
      title: "#",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => (currentPage - 1) * limit + 1 + index,
    });

    if (viewSettingsValues.image) {
      newColumns.push({
        width: 100,
        dataIndex: "image",
        key: "image",
        render: (text, record, index) => {
          return <ImageComponent src={record.image || ""} className="tableImage" />;
        },
      });
    }

    if (viewSettingsValues.name) {
      newColumns.push({
        title: translate["Name"] || EN["Name"],
        dataIndex: "name",
        key: "name",
        sorter: true,
        render: (text, record, index) => (
          <p className="text-ellipsis" style={{ width: 100 }}>
            {record.name}
          </p>
        ),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <> {ICONS.SEARCHOUTLINED} </>;
        },

        onFilter: (value, record) => {
          return record.name.toLowerCase().includes(value.toLowerCase());
        },
      });
    }

    if (viewSettingsValues.type) {
      newColumns.push({
        title: translate["Type"] || EN["Type"],
        width: 120,
        dataIndex: "type",
        key: "type",
        render: (text, record, index) => record.type,
      });
    }

    if (viewSettingsValues.guest) {
      newColumns.push({
        title: translate["Guest Name"] || EN["Guest Name"],
        dataIndex: "guest",
        key: "guest",
        width: 200,
        render: (text, record, index) => (
          <p className="text-ellipsis">{record.guest ? record.guest.name : "-"}</p>
        ),
        filterDropdown: ({ confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={guestSearch[0]}
              onChange={(e) => {
                setGuestSearch(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <> {ICONS.SEARCHOUTLINED} </>;
        },

        onFilter: (value, record) => {
          return record.guest?.name.toLowerCase().includes(value.toLowerCase());
        },
      });
    }

    newColumns.push({
      title: translate["Reserved Date"] || EN["Reserved Date"],
      dataIndex: "reserved_date",
      key: "reserved_date",
      render: (text, record, index) => (
        <p className="text-ellipsis">
          {record.reserved_date
            ? parseYearMonthDayHoursMinutes(record.reserved_date)
            : "-"}
        </p>
      ),
    });

    if (viewSettingsValues.location_name) {
      newColumns.push({
        title: `${translate["Location name"] || EN["Location name"]}`,
        dataIndex: "room",
        key: "room",
        width: 150,
        render: (text, record, index) => (
          <p className="text-ellipsis">{record.room ? record.room.location_name : "-"}</p>
        ),
        filterDropdown: ({ confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={roomSearch[0]}
              onChange={(e) => {
                setRoomSearch(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <> {ICONS.SEARCHOUTLINED} </>;
        },

        onFilter: (value, record) => {
          return record.room?.location_name.toLowerCase().includes(value.toLowerCase());
        },
      });
    }

    if (viewSettingsValues.price) {
      newColumns.push({
        title: translate["Price"] || EN["Price"],
        width: 100,
        dataIndex: "price",
        key: "price",
        sorter: true,
        render: (text, record, index) => (
          <Currency>{parseFloat(record.price).toFixed(2)}</Currency>
        ),
      });
    }

    if (viewSettingsValues.completed) {
      newColumns.push({
        title: translate["Status"] || EN["Status"],
        dataIndex: "completed",
        key: "completed",
        sorter: true,
        width: 130,
        render: (text, record, index) => (
          <p className="txt-tb-l">{<CompletedComponent check={record.completed} />} </p>
        ),
      });
    }

    if (viewSettingsValues.quantity) {
      newColumns.push({
        title: translate["Quantity"] || EN["Quantity"],
        dataIndex: "quantity",
        key: "quantity",
        sorter: true,
        width: 100,
        render: (text, record, index) => (
          <p className="txt-tb-r">
            {" "}
            {record.quantity ? parseFloat(record.quantity) : ""}{" "}
          </p>
        ),
      });
    }
    if (viewSettingsValues.createdAt) {
      newColumns.push({
        title: translate["Date"] || EN["Date"],
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: true,
        render: (text, record, index) => dateFormat(text),
      });
    }

    if (viewSettingsValues.accepted_date) {
      newColumns.push({
        title: translate["Accepted Date"] || EN["Accepted Date"],
        dataIndex: "accepted_date",
        key: "accepted_date",
        render: (text, record, index) => (
          <p className="text-ellipsis">
            {record.accepted_date
              ? parseYearMonthDayHoursMinutes(record.accepted_date)
              : "-"}
          </p>
        ),
      });
    }

    if (viewSettingsValues.rejected_date) {
      newColumns.push({
        title: translate["Rejected Date"] || EN["Rejected Date"],
        dataIndex: "rejected_date",
        key: "rejected_date",
        render: (text, record, index) => (
          <p className="text-ellipsis">
            {record.rejected_date
              ? parseYearMonthDayHoursMinutes(record.rejected_date)
              : "-"}
          </p>
        ),
      });
    }

    newColumns.push({
      title: "",
      width: 150,
      fixed: "right",
      dataIndex: "action",
      render: (text, record, index) => (
        <div>
          {record.completed === "0" ? (
            <div style={{ display: "flex" }}>
              <AcceptRejectBtnComponent
                icon={ICONS.ACCEPT}
                onClick={(e) => onChangeDefaultProducts(e, record, "1")}
              />
              <AcceptRejectBtnComponent
                icon={ICONS.REJECT}
                onClick={(e) => onChangeDefaultProducts(e, record, "2")}
              />
            </div>
          ) : (
            <AcceptRejectBtnComponent
              icon={record.completed === "1" ? ICONS.REJECT : ICONS.ACCEPT}
              onClick={(e) =>
                onChangeDefaultProducts(e, record, record.completed === "1" ? "2" : "1")
              }
            />
          )}
        </div>
      ),
    });

    setColumns(newColumns);
  };

  useEffect(() => {
    getProductList();
  }, [
    currentPage,
    limit,
    sort,
    selectedHostId,
    ordersTypeId,
    selectedLocationNameId,
    selectedPublishedStatus,
  ]);

  useEffect(() => {
    let timeout = null;
    if (roomSearch[0] || guestSearch[0]) {
      if (roomSearch[0]?.length > 2 || guestSearch[0]?.length > 2) {
        timeout = setTimeout(() => {
          getProductList();
        }, 1000);
      }
    } else {
      getProductList();
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [roomSearch, guestSearch]);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      getProductList();
    }, 1000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [search]);

  useEffect(() => {
    configureTableColumns();
  }, [viewSettingsValues, currentPage]);

  useEffect(() => {
    let ORDERS_COLUMNS = localStorage.getItem("ORDERS_COLUMNS");

    if (ORDERS_COLUMNS) {
      ORDERS_COLUMNS = JSON.parse(ORDERS_COLUMNS);
      setViewSettingsValues(ORDERS_COLUMNS);
    }
  }, []);

  const onFinish = (values) => {
    let productsId = selecteOrder.id;
    let completed = selecteOrder.status;

    form.resetFields();

    const body = {
      order_id: productsId,
      completed: completed,
      message: values.message,
    };

    function callback() {
      getProductList();
      setSelectedOrder(null);
    }

    function errorCallback(err) {}

    REQUESTS.ORDERS.COMPLETED(body, callback, errorCallback);
  };

  const onFinishFailed = () => {
    setErrorText(null);
  };

  useEffect(() => {
    selecteOrder && openConfirmModal();
  }, [selecteOrder]);

  const openConfirmModal = () => {
    confirmModal({
      title: `Do you want to ${
        selecteOrder.status == "1"
          ? "accept"
          : selecteOrder.status == "2"
          ? "reject"
          : "completed"
      } the ${selecteOrder.type} ${selecteOrder.name}`,
      content: (
        <Form
          form={form}
          layout="vertical"
          name="servicesOrders-form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label={translate["Messages"] || EN["Messages"]}
            name="message"
            tooltip={{
              title: "message",
              icon: ICONS.INFO,
            }}
          >
            <Input.TextArea rows={5} onResize={false} />
          </Form.Item>
          <ErrorMessage>{errorText}</ErrorMessage>
        </Form>
      ),
      className: `${
        selecteOrder.status == "1" ? "green-yes-button" : "confirm-yes-button"
      }`,
      action: () => form.submit(),
    });
  };
  const onChangeDefaultProducts = (e, record, status) => {
    setSelectedOrder({ ...record, status });
  };

  const getProductList = () => {
    setLoading(true);

    const query = {
      sort,
      limit,
      pagination: 1,
      page: currentPage,
    };

    if (search.name) {
      query.search = JSON.stringify({
        name: search.name && search.name[0],
      });
    }

    if (guestSearch[0]) {
      query.guest_name = guestSearch[0];
    }

    if (roomSearch[0]) {
      query.room_name = roomSearch[0];
    }

    let filter = {};

    if (selectedHostId) {
      filter.guest_id = selectedHostId;
    }

    if (ordersTypeId) {
      filter.type = ordersTypeId;
    }

    if (selectedLocationNameId) {
      filter.room_id = selectedLocationNameId;
    }

    if (selectedPublishedStatus != null) {
      filter.completed = selectedPublishedStatus;
    }

    const size = Object.keys(filter).length;

    if (size) {
      query.filter = JSON.stringify(filter);
    }

    function callback(data) {
      setProductList(data.rows);
      setLimit(data.limit);
      setTotal(data.count);
      setLoading(false);

      if (data.rows.length === 0 && currentPage !== 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }
    }

    function errorCallback() {
      setLoading(false);
    }

    REQUESTS.ORDERS.GET(query, callback, errorCallback);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }

    // if (Object.values(filters)[0]) {
    //     setNameSearch(Object.values(filters)[0].join(""));
    // } else {
    //     setNameSearch(null);
    // }
    setSearch(filters);

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  return (
    <Context.Provider
      value={{
        total,
        getProductList,
        visibleViewSettingsDrawer,
        setVisibleViewSettingsDrawer,
        viewSettingsValues,
        setViewSettingsValues,
        visibleFilterDrawer,
        setVisibleFilterDrawer,
        selectedPublishedStatus,
        setSelectedPublishedStatus,
        selectedHostId,
        setSelectedHostId,
        selectedLocationNameId,
        setSelectedLocationNameId,
        ordersTypeId,
        setOrdersTypeId,
      }}
    >
      <Page>
        <Header />

        <div className="page-body">
          <TableHead />
          <Table
            defaultExpandAllRows={true}
            loading={loading}
            rowKey="id"
            columns={columns}
            dataSource={productList}
            onChange={handleTableChange}
            scroll={{
              x: 2000,
              y: null,
            }}
            pagination={{
              position: ["topCenter", "bottomCenter"],
              current: currentPage,
              total: total,
              showSizeChanger: true,
            }}
            sticky={{
              offsetHeader: 60,
              offsetScroll: 0,
            }}
          />
        </div>
      </Page>
    </Context.Provider>
  );
}
