import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Drawer, Form, Input } from "antd";

import EN from "../../../config/en";
import ICONS from "../../../config/icons";

import ErrorMessage from "../../elements/ErrorMessage";
import SaveButtonComponent from "../../elements/SaveButtonComponent";
import REQUESTS from "../../../server/requests";

export default function MediaDrawer({ visible, current, onClose, getList }) {
  
  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);

  const [errorText, setErrorText] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      if (current) {
        form.setFields([{ name: "name", value: current.name }]);
      }
    } else {
      form.resetFields();
      setErrorText(null);
    }
  }, [visible]);

  const onFinish = (values) => {
    setLoading(true);

    setErrorText(null);

    const body = {
      id: current.id,
      name: values.name,
    };

    function callback() {
      setLoading(false);
      onClose();
      getList();
    }

    function errorCallback(err) {
      setErrorText(err);
      setLoading(false);
    }

    // if (currentGroups) {
    //     body.id = currentGroups.id;
    //     REQUESTS.ROOMS.GROUPS.EDIT(body, callback, errorCallback);
    // } else {
    //     REQUESTS.ROOMS.GROUPS.ADD(body, callback, errorCallback);
    // }

    REQUESTS.MEDIA_LIBRARY.EDIT(body, callback, errorCallback);
  };

  const onFinishFailed = () => {
    setLoading(false);
    setErrorText(null);
  };

 
  return (
    <Drawer
      title={current ? translate['Edit Category'] || EN['Edit Category'] : translate['Add Category'] || EN['Add Category']}
      placement="right"
      width={400}
      onClose={onClose}
      visible={visible}
    >
      <Form
        form={form}
        layout="vertical"
        name="groups-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={translate['Name'] || EN['Name']}
          tooltip={{
            title: "Category name",
            icon: ICONS.INFO,
          }}
          name="name"
          rules={[
            {
              required: true,
              message: `${translate['Please input'] || EN['Please input']} ${translate['Name'] || EN['Name']}`,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <SaveButtonComponent
            loading={loading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>

      <ErrorMessage>{errorText}</ErrorMessage>
    </Drawer>
  );
}
