import React from "react";

import { Drawer } from "antd";
import FromHandTabPane from "./from-hand/FromHandTabPane";

export default function EditMovieDrawer({ visible, onClose }) {
    return (
        <Drawer
            title={"Edit movie"}
            placement="right"
            width={1100}
            onClose={onClose}
            visible={visible}
        >
            <FromHandTabPane />
        </Drawer>
    );
}
