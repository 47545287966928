// import { useEffect, useState } from "react";
// import { Drawer, Form, Input, Select, Button } from "antd";
// import TextArea from "antd/lib/input/TextArea";
// import FileChooser from "../../../../elements/FileChooser";
// import Translation from "../../../../elements/Translation";
// import { useSelector } from "react-redux";
// import EN from "../../../../../config/en";
// import ICONS from "../../../../../config/icons";

// const ServicePageDrawer = ({ visible, onClose, AddService, editable, loading }) => {
//   const [form] = Form.useForm();
//   const [imageSrc, setImageSrc] = useState(null);
//   const [descriptionTranslations, setDescriptionTranslations] = useState([]);
//   const [nameTranslation, setNameTranslation] = useState([]);
//   const [categoriesId, setCategoriesId] = useState(null);

//   const translate = useSelector((state) => state.globalState.translate);

//   const handleFinish = (values) => {
//     const validNameTranslations = nameTranslation.filter((i) => i.language_id);
//     const validDescriptionTranslations = descriptionTranslations.filter(
//       (i) => i.language_id
//     );

//     const body = {
//       title: validNameTranslations.length > 0 ? validNameTranslations : [],
//       text: validDescriptionTranslations.length > 0 ? validDescriptionTranslations : [],
//       image: imageSrc,
//     };

//     console.log(body);

//     AddService(body);
//   };

//   const repliceImage = () => {
//     setImageSrc(null);
//     form.setFields([{ name: "image", value: null }]);
//   };

//   const selectFile = (file) => {
//     setImageSrc(file?.file_url);
//     form.setFields([{ name: "image", value: file }]);
//   };

//   const onChangeNameTranslation = (value, id) => {
//     const name = {
//       text: value,
//       language_id: id,
//     };

//     const newTranslations = [...nameTranslation];
//     const index = newTranslations.findIndex((i) => i.language_id === id);

//     if (index >= 0) {
//       newTranslations[index] = name;
//     } else {
//       newTranslations.push(name);
//     }

//     setNameTranslation(newTranslations.filter((i) => i.language_id));
//   };

//   const onChangeTranslation = (value, id) => {
//     if (!id) return;

//     const description = {
//       text: value,
//       language_id: id,
//     };

//     const newTranslations = [...descriptionTranslations];
//     const index = newTranslations.findIndex((i) => i.language_id === id);

//     if (index >= 0) {
//       newTranslations[index] = description;
//     } else {
//       newTranslations.push(description);
//     }

//     setDescriptionTranslations(newTranslations.filter((i) => i.language_id));
//   };

//   useEffect(() => {
//     if (editable) {
//       form.setFieldsValue({
//         title: editable.title,
//         text: editable.text,
//         image: editable.image,
//       });
//       setNameTranslation([{ text: editable.title, language_id: null }]);
//       setDescriptionTranslations([{ text: editable.text, language_id: null }]);
//       setImageSrc(editable.image);
//     }
//   }, [visible, editable]);

//   useEffect(() => {
//     if (!visible) {
//       form.resetFields();
//       setImageSrc(null);
//       setDescriptionTranslations([]);
//       setNameTranslation([]);
//       setCategoriesId(null);
//     }
//   }, [visible]);

//   return (
//     <Drawer
//       width={550}
//       open={visible}
//       title={
//         editable
//           ? translate["Edit Service"] || EN["Edit Service"]
//           : translate["Add Service"] || EN["Add Service"]
//       }
//       onClose={() => {
//         onClose();
//         form.resetFields();
//         selectFile(null);
//       }}
//       footer={
//         <div style={{ textAlign: "right" }}>
//           <Button
//             onClick={() => {
//               form.resetFields();
//               onClose();
//             }}
//             style={{ marginRight: 8 }}
//           >
//             {translate["Cancel"] || EN["Cancel"]}
//           </Button>
//           <Button
//             loading={loading}
//             onClick={() => {
//               form.submit();
//             }}
//             type="primary"
//           >
//             {editable ? translate["Edit"] || EN["Edit"] : translate["Add"] || EN["Add"]}
//           </Button>
//         </div>
//       }
//     >
//       <Form form={form} onFinish={handleFinish} layout="vertical">
//         <Form.Item label="Icon" name="icon">
//           <FileChooser
//             src={imageSrc}
//             onChange={selectFile}
//             replace={repliceImage}
//             types={["image"]}
//           />
//         </Form.Item>
//         <Translation
//           render={(item) => {
//             const name = nameTranslation.find(
//               (translation) => translation.language_id === item.id
//             )?.text;
//             return (
//               <Form.Item
//                 label={translate["Title"] || EN["Title"]}
//                 name="title"
//                 tooltip={{ title: "Info Title", icon: ICONS.INFO }}
//               >
//                 <Input
//                   value={name || ""}
//                   onChange={(e) => onChangeNameTranslation(e.target.value, item.id)}
//                 />
//               </Form.Item>
//             );
//           }}
//         />
//         <Translation
//           render={(item) => {
//             const description = descriptionTranslations.find(
//               (translation) => translation.language_id === item.id
//             )?.text;
//             return (
//               <Form.Item
//                 label={translate["Text"] || EN["Text"]}
//                 name="text"
//                 tooltip={{ title: "Info Title", icon: ICONS.INFO }}
//               >
//                 <TextArea
//                   value={description || ""}
//                   onChange={(e) => onChangeTranslation(e.target.value, item.id)}
//                 />
//               </Form.Item>
//             );
//           }}
//         />
//       </Form>
//     </Drawer>
//   );
// };

// export default ServicePageDrawer;

import { useEffect, useState } from "react";
import { Drawer, Form, Input, Select, Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import FileChooser from "../../../../elements/FileChooser";
import Translation from "../../../../elements/Translation";
import { useSelector } from "react-redux";
import EN from "../../../../../config/en";
import ICONS from "../../../../../config/icons";

const ServicePageDrawer = ({ visible, onClose, AddService, editable, loading }) => {
  const [form] = Form.useForm();
  const [imageSrc, setImageSrc] = useState(null);
  const [descriptionTranslations, setDescriptionTranslations] = useState([]);
  const [nameTranslation, setNameTranslation] = useState([]);
  const [categoriesId, setCategoriesId] = useState(null);

  const translate = useSelector((state) => state.globalState.translate);

  const handleFinish = (values) => {
    const body = {
      title: nameTranslation,
      text: descriptionTranslations,
      image: imageSrc,
    };
    AddService(body);
  };

  const repliceImage = () => {
    setImageSrc(null);
    form.setFields([{ name: "image", value: null }]);
  };

  const selectFile = (file) => {
    setImageSrc(file?.file_url);
    form.setFields([{ name: "image", value: file }]);
  };

  const onChangeNameTranslation = (value, id) => {
    const name = {
      text: value,
      iso_code: id,
    };

    const newTranslations = [...nameTranslation];
    const index = newTranslations.findIndex((i) => i.iso_code === id);

    if (index >= 0) {
      newTranslations[index] = name;
    } else {
      newTranslations.push(name);
    }

    setNameTranslation(newTranslations);
  };

  const onChangeTranslation = (value, iso_cod) => {
    const description = {
      text: value,
      iso_code: iso_cod,
    };

    const newTranslations = [...descriptionTranslations];

    const index = newTranslations.findIndex((i) => i.iso_code === iso_cod);

    if (index >= 0) {
      newTranslations[index] = description;
    } else {
      newTranslations.push(description);
    }

    setDescriptionTranslations(newTranslations);
  };

  useEffect(() => {
    if (editable) {
      const translationName = editable.title_translations.map((item) => {
        return {
          text: item.text,
          iso_code: item.iso_code,
        };
      });

      const translationDescription = editable.text_translations.map((item) => {
        return {
          text: item.text,
          iso_code: item.iso_code,
        };
      });

      setDescriptionTranslations(translationDescription);
      setNameTranslation(translationName);

      form.setFieldsValue({
        title: editable.title,
        text: editable.text,
      });

      setImageSrc(editable.image);
    }
  }, [visible, editable]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setImageSrc(null);
      setDescriptionTranslations([]);
      setNameTranslation([]);
      setCategoriesId(null);
    }
  }, [visible]);

  return (
    <Drawer
      width={550}
      open={visible}
      title={
        editable
          ? translate["Edit Service"] || EN["Edit Service"]
          : translate["Add Service"] || EN["Add Service"]
      }
      onClose={() => {
        onClose();
        form.resetFields();
        selectFile(null);
      }}
      footer={
        <div style={{ textAlign: "right" }}>
          <Button
            onClick={() => {
              form.resetFields();
              onClose();
            }}
            style={{ marginRight: 8 }}
          >
            {translate["Cancel"] || EN["Cancel"]}
          </Button>
          <Button
            loading={loading}
            onClick={() => {
              form.submit();
            }}
            type="primary"
          >
            {editable ? translate["Edit"] || EN["Edit"] : translate["Add"] || EN["Add"]}
          </Button>
        </div>
      }
    >
      <Form form={form} onFinish={handleFinish} layout="vertical">
        <Form.Item label="Icon" name="icon">
          <FileChooser
            src={imageSrc}
            onChange={selectFile}
            replace={repliceImage}
            types={["image"]}
          />
        </Form.Item>
        <Translation
          render={(item) => {
            const name = nameTranslation.find(
              (translation) => translation.iso_code === item.id
            )?.text;
            return (
              <Form.Item
                label={translate["Title"] || EN["Title"]}
                name="title"
                tooltip={{ title: "Info Title", icon: ICONS.INFO }}
              >
                <Input
                  value={name || ""}
                  onChange={(e) => onChangeNameTranslation(e.target.value, item.iso_code)}
                />
              </Form.Item>
            );
          }}
        />
        <Translation
          render={(item) => {
            const description = descriptionTranslations.find(
              (translation) => translation.iso_code === item.iso_code
            )?.text;
            return (
              <Form.Item
                label={translate["Text"] || EN["Text"]}
                name="text"
                tooltip={{ title: "Info Title", icon: ICONS.INFO }}
              >
                <TextArea
                  value={description || ""}
                  onChange={(e) => onChangeTranslation(e.target.value, item.iso_code)}
                />
              </Form.Item>
            );
          }}
        />
      </Form>
    </Drawer>
  );
};

export default ServicePageDrawer;
