import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Drawer, Form, Input, Select } from "antd";

import REQUESTS from "../../../server/requests";

import ICONS from "../../../config/icons";
import EN from "../../../config/en";

import ErrorMessage from "../../elements/ErrorMessage";
import SaveButtonComponent from "../../elements/SaveButtonComponent";
import { useOrganizationsOptions } from "../../../hooks/options";

export default function AddCountryDrawer({ visible, onClose, currentCountry, getCountryList }) {
    const translate = useSelector((state) => state.globalState.translate);

    const [loading, setLoading] = useState(false);

    const [errorText, setErrorText] = useState(null);

    const organizationsOptions = useOrganizationsOptions();

    const [form] = Form.useForm();

    useEffect(() => {
        if (visible) {
            if (currentCountry) {
                form.setFields([
                    { name: "name", value: currentCountry.name },
                    { name: "organization_id", value: currentCountry.organization_id },
                ]);
            }
        } else {
            setErrorText(null);
        }
    }, [visible]);

    useEffect(() => {
        if (!visible && currentCountry) {
            form.resetFields();
        }
    }, [visible]);

    const onFinish = (values) => {
        setLoading(true);

        setErrorText(null);

        const body = {
            name: values.name,
            organization_id: values.organization_id,
        };

        function callback() {
            setLoading(false);
            onClose();
            getCountryList();
            form.resetFields();
        }

        function errorCallback(err) {
            setErrorText(err);
            setLoading(false);
        }

        if (currentCountry) {
            body.id = currentCountry.id;
            REQUESTS.COUNTRY.EDIT(body, callback, errorCallback);
        } else {
            REQUESTS.COUNTRY.ADD(body, callback, errorCallback);
        }
    };

    const onFinishFailed = () => {
        setLoading(false);
        setErrorText(null);
    };

    return (
        <Drawer
            title={
                currentCountry
                    ? translate["Edit Country"] || EN["Edit Country"]
                    : translate["Add New Country"] || EN["Add New Country"]
            }
            placement="right"
            width={600}
            visible={visible}
            onClose={onClose}
           
        >
            <Form
                form={form}
                layout="vertical"
                name="gui-form"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label={translate["Name"] || EN["Name"]}
                    tooltip={{
                        title: "Country name",
                        icon: ICONS.INFO,
                    }}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: `${translate["Please input"] || EN["Please input"]} ${
                                translate["Name"] || EN["Name"]
                            }`,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={translate["Organization"] || EN["Organization"]}
                    tooltip={{
                        title: "Organization name",
                        icon: ICONS.INFO,
                    }}
                    name="organization_id"
                    rules={[
                        {
                            required: true,
                            message: `${translate["Please select"] || EN["Please select"]} ${
                                translate["Organization"] || EN["Organization"]
                            }`,
                        },
                    ]}
                >
                    <Select
                        showArrow
                        options={organizationsOptions}
                        placeholder={translate["Select Organization"] || EN["Select Organization"]}
                    />
                </Form.Item>

                <Form.Item>
                    <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
                </Form.Item>
            </Form>
            <ErrorMessage>{errorText}</ErrorMessage>
        </Drawer>
    );
}
