import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import REQUESTS from "../../../../../server/requests";

import EN from "../../../../../config/en";
import ICONS from "../../../../../config/icons";

import Page from "../../../../elements/Page";
import TitleComponent from "../../../../elements/TitleComponent";

import RoomDrawer from "../RoomDrawer";

import CurrentHost from "./CurrentHost";
import HistoryOrders from "./HistoryOrders";
import OrdersHost from "./OrdersHost";
import HistoryFeedback from "./HistoryFeedback";

export default function SingleRoomPage() {

    const translate = useSelector((state) => state.globalState.translate);

    const navigate = useNavigate();
    const location = useLocation();

    const roomId = useRef(null);

    const [currentHost, setCurrentHost] = useState([]);
    const [checkInHost, setCheckInHost] = useState(null);

    const [updateOrdersTable, setUpdateOrdersTable] = useState(false);

    useEffect(() => {
        if (location.state) {
            roomId.current = location.state.roomId;
        }
        getHostInLocations();
    }, []);
    
    const getHostInLocations = () => {
        const query = {};

        query.filter = JSON.stringify({
            id: roomId.current,
        });

        function callback(data) {
            setCurrentHost(data);
        }
        function errorCallback() {}

        REQUESTS.ROOMS.GET(query, callback, errorCallback);
    };

    const location_name = currentHost.map((item) => {
        return item.location_name;
    });

    return (
        <Page>
            <div className="page-body">
                <header className="single-location-header">
                    <div className="onBack">
                        <div onClick={() => navigate(-1)}>
                            {ICONS.GO_BACK}
                            <span>{translate['Locations'] || EN['Locations']}</span>
                        </div>
                    </div>
                    <div className="title-single-location-name">
                        <TitleComponent> {location_name} </TitleComponent>
                    </div>
                </header>

                <div className="two_sections_content">
                    <CurrentHost
                        currentHost={currentHost}
                        setCheckInHost={setCheckInHost}
                        getHostInLocations={getHostInLocations}
                    />
                    {/* 2 */}
                    <div className="bg_fff  content_box_dashbord">
                        <TitleComponent>{translate['Curent guest orders'] || EN['Curent guest orders']}</TitleComponent>
                        <OrdersHost 
                            currentHost={currentHost[0]}
                            setUpdateOrdersTable={setUpdateOrdersTable}
                        />
                    </div>
                </div>
                <HistoryOrders room_id={roomId.current}  updateOrdersTable={updateOrdersTable} />
                <HistoryFeedback   room_id={roomId.current} />

                <RoomDrawer
                    visible={checkInHost ? true : false}
                    onClose={() => setCheckInHost(null)}
                    room={currentHost[0]}
                    getRoomsList={getHostInLocations}
                />
            </div>
        </Page>
    );
}
